import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { FC, useState } from 'react';
import LinkContainer, { ILinkContainerProps } from '../../LinkContainer';

export interface IGeminiNavLinkButtonProps extends ILinkContainerProps {
  title: string;
  description?: string;
  onClick?(): void;
  titleClassName?: string;
  containerClassName?: string;
  hoverIconAlignment?: 'left' | 'right';
}

const GeminiNavLinkButton: FC<IGeminiNavLinkButtonProps> = ({
  title,
  description,
  onClick,
  titleClassName,
  containerClassName,
  hoverIconAlignment = 'left',
  ...props
}) => {
  const [hover, setHover] = useState(false);

  return (
    <LinkContainer {...props}>
      <button
        className={classNames(
          'xl:w-auto w-full flex items-center justify-between text-cobalt xl:space-x-0 space-x-5 xl:px-0 px-5 xl:py-0 py-6 xl:border-b-transparent border-b border-b-new-gray',
          containerClassName
        )}
        onClick={onClick}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <div>
          <div
            className={classNames(
              'flex items-center gap-2.5',
              hoverIconAlignment === 'right'
                ? 'justify-between'
                : 'justify-start'
            )}
          >
            <h4
              className={classNames(
                'capitalize font-telegraf text-xl',
                titleClassName
              )}
            >
              {title}
            </h4>
            <div
              className={classNames(
                'hidden xl:flex justify-center items-center bg-new-primary rounded-full text-base w-[30px] h-[30px]',
                hover ? 'opacity-100' : 'opacity-0'
              )}
            >
              <FontAwesomeIcon icon={faArrowRight} className='-rotate-45' />
            </div>
          </div>
          <p className='text-left font-inter text-[15px] opacity-70 xl:mt-2.5 mt-1 leading-[22.5px] font-normal'>
            {description}
          </p>
        </div>
        <div className='xl:hidden block'>
          <FontAwesomeIcon icon={faArrowRight} className='-rotate-45 text-lg' />
        </div>
      </button>
    </LinkContainer>
  );
};

export default GeminiNavLinkButton;
