import React from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

interface NavMenuItemProps {
  title: string;
  onShow(): void;
  onHide(): void;
  isOpen: boolean;
}

const NavMenuItem: React.FC<NavMenuItemProps> = ({
  title,
  onShow,
  onHide,
  isOpen,
}) => {
  return (
    <button
      onMouseEnter={onShow}
      onClick={isOpen ? onHide : onShow}
      className='text-dark flex flex-row space-x-2 justify-between items-center w-full text-base whitespace-nowrap'
    >
      <span>{title}</span>{' '}
      {isOpen ? (
        <FaChevronUp className='text-xs' />
      ) : (
        <FaChevronDown className='text-xs' />
      )}
    </button>
  );
};

export default NavMenuItem;
