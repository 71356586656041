/* tslint:disable */
/* eslint-disable */
/**
 * BFF API
 * BFF Main Application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AddressRequest
 */
export interface AddressRequest {
    /**
     * 
     * @type {string}
     * @memberof AddressRequest
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof AddressRequest
     */
    'streetAddress1': string;
    /**
     * 
     * @type {string}
     * @memberof AddressRequest
     */
    'streetAddress2'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressRequest
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof AddressRequest
     */
    'stateOrProvince': AddressRequestStateOrProvinceEnum;
    /**
     * 
     * @type {string}
     * @memberof AddressRequest
     */
    'country': AddressRequestCountryEnum;
    /**
     * 
     * @type {string}
     * @memberof AddressRequest
     */
    'zipOrPostalCode': string;
}

export const AddressRequestStateOrProvinceEnum = {
    Alabama: 'ALABAMA',
    Alaska: 'ALASKA',
    Arizona: 'ARIZONA',
    Arkansas: 'ARKANSAS',
    California: 'CALIFORNIA',
    Colorado: 'COLORADO',
    Connecticut: 'CONNECTICUT',
    Delaware: 'DELAWARE',
    DistrictOfColumbia: 'DISTRICT_OF_COLUMBIA',
    Florida: 'FLORIDA',
    Georgia: 'GEORGIA',
    Hawaii: 'HAWAII',
    Idaho: 'IDAHO',
    Illinois: 'ILLINOIS',
    Indiana: 'INDIANA',
    Iowa: 'IOWA',
    Kansas: 'KANSAS',
    Kentucky: 'KENTUCKY',
    Louisiana: 'LOUISIANA',
    Maine: 'MAINE',
    Maryland: 'MARYLAND',
    Massachusetts: 'MASSACHUSETTS',
    Michigan: 'MICHIGAN',
    Minnesota: 'MINNESOTA',
    Mississippi: 'MISSISSIPPI',
    Missouri: 'MISSOURI',
    Montana: 'MONTANA',
    Nebraska: 'NEBRASKA',
    Nevada: 'NEVADA',
    NewHampshire: 'NEW_HAMPSHIRE',
    NewJersey: 'NEW_JERSEY',
    NewMexico: 'NEW_MEXICO',
    NewYork: 'NEW_YORK',
    NorthCarolina: 'NORTH_CAROLINA',
    NorthDakota: 'NORTH_DAKOTA',
    Ohio: 'OHIO',
    Oklahoma: 'OKLAHOMA',
    Oregon: 'OREGON',
    Pennsylvania: 'PENNSYLVANIA',
    RhodeIsland: 'RHODE_ISLAND',
    SouthCarolina: 'SOUTH_CAROLINA',
    SouthDakota: 'SOUTH_DAKOTA',
    Tennessee: 'TENNESSEE',
    Texas: 'TEXAS',
    Utah: 'UTAH',
    Vermont: 'VERMONT',
    Virginia: 'VIRGINIA',
    Washington: 'WASHINGTON',
    WestVirginia: 'WEST_VIRGINIA',
    Wisconsin: 'WISCONSIN',
    Wyoming: 'WYOMING',
    PuertoRico: 'PUERTO_RICO',
    Alberta: 'ALBERTA',
    BritishColumbia: 'BRITISH_COLUMBIA',
    Manitoba: 'MANITOBA',
    NewBrunswick: 'NEW_BRUNSWICK',
    NewfoundlandAndLabrador: 'NEWFOUNDLAND_AND_LABRADOR',
    NovaScotia: 'NOVA_SCOTIA',
    Ontario: 'ONTARIO',
    PrinceEdwardIsland: 'PRINCE_EDWARD_ISLAND',
    Quebec: 'QUEBEC',
    Saskatchewan: 'SASKATCHEWAN'
} as const;

export type AddressRequestStateOrProvinceEnum = typeof AddressRequestStateOrProvinceEnum[keyof typeof AddressRequestStateOrProvinceEnum];
export const AddressRequestCountryEnum = {
    UnitedStates: 'UNITED_STATES',
    Canada: 'CANADA'
} as const;

export type AddressRequestCountryEnum = typeof AddressRequestCountryEnum[keyof typeof AddressRequestCountryEnum];

/**
 * 
 * @export
 * @interface AddressResponse
 */
export interface AddressResponse {
    /**
     * 
     * @type {string}
     * @memberof AddressResponse
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressResponse
     */
    'country'?: AddressResponseCountryEnum;
    /**
     * 
     * @type {string}
     * @memberof AddressResponse
     */
    'streetAddress1'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressResponse
     */
    'streetAddress2'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressResponse
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressResponse
     */
    'stateOrProvince'?: AddressResponseStateOrProvinceEnum;
    /**
     * 
     * @type {string}
     * @memberof AddressResponse
     */
    'zipOrPostalCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressResponse
     */
    'oneLine'?: string;
}

export const AddressResponseCountryEnum = {
    UnitedStates: 'UNITED_STATES',
    Canada: 'CANADA'
} as const;

export type AddressResponseCountryEnum = typeof AddressResponseCountryEnum[keyof typeof AddressResponseCountryEnum];
export const AddressResponseStateOrProvinceEnum = {
    Alabama: 'ALABAMA',
    Alaska: 'ALASKA',
    Arizona: 'ARIZONA',
    Arkansas: 'ARKANSAS',
    California: 'CALIFORNIA',
    Colorado: 'COLORADO',
    Connecticut: 'CONNECTICUT',
    Delaware: 'DELAWARE',
    DistrictOfColumbia: 'DISTRICT_OF_COLUMBIA',
    Florida: 'FLORIDA',
    Georgia: 'GEORGIA',
    Hawaii: 'HAWAII',
    Idaho: 'IDAHO',
    Illinois: 'ILLINOIS',
    Indiana: 'INDIANA',
    Iowa: 'IOWA',
    Kansas: 'KANSAS',
    Kentucky: 'KENTUCKY',
    Louisiana: 'LOUISIANA',
    Maine: 'MAINE',
    Maryland: 'MARYLAND',
    Massachusetts: 'MASSACHUSETTS',
    Michigan: 'MICHIGAN',
    Minnesota: 'MINNESOTA',
    Mississippi: 'MISSISSIPPI',
    Missouri: 'MISSOURI',
    Montana: 'MONTANA',
    Nebraska: 'NEBRASKA',
    Nevada: 'NEVADA',
    NewHampshire: 'NEW_HAMPSHIRE',
    NewJersey: 'NEW_JERSEY',
    NewMexico: 'NEW_MEXICO',
    NewYork: 'NEW_YORK',
    NorthCarolina: 'NORTH_CAROLINA',
    NorthDakota: 'NORTH_DAKOTA',
    Ohio: 'OHIO',
    Oklahoma: 'OKLAHOMA',
    Oregon: 'OREGON',
    Pennsylvania: 'PENNSYLVANIA',
    RhodeIsland: 'RHODE_ISLAND',
    SouthCarolina: 'SOUTH_CAROLINA',
    SouthDakota: 'SOUTH_DAKOTA',
    Tennessee: 'TENNESSEE',
    Texas: 'TEXAS',
    Utah: 'UTAH',
    Vermont: 'VERMONT',
    Virginia: 'VIRGINIA',
    Washington: 'WASHINGTON',
    WestVirginia: 'WEST_VIRGINIA',
    Wisconsin: 'WISCONSIN',
    Wyoming: 'WYOMING',
    PuertoRico: 'PUERTO_RICO',
    Alberta: 'ALBERTA',
    BritishColumbia: 'BRITISH_COLUMBIA',
    Manitoba: 'MANITOBA',
    NewBrunswick: 'NEW_BRUNSWICK',
    NewfoundlandAndLabrador: 'NEWFOUNDLAND_AND_LABRADOR',
    NovaScotia: 'NOVA_SCOTIA',
    Ontario: 'ONTARIO',
    PrinceEdwardIsland: 'PRINCE_EDWARD_ISLAND',
    Quebec: 'QUEBEC',
    Saskatchewan: 'SASKATCHEWAN'
} as const;

export type AddressResponseStateOrProvinceEnum = typeof AddressResponseStateOrProvinceEnum[keyof typeof AddressResponseStateOrProvinceEnum];

/**
 * 
 * @export
 * @interface AdministrativeAreaResponse
 */
export interface AdministrativeAreaResponse {
    /**
     * 
     * @type {string}
     * @memberof AdministrativeAreaResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof AdministrativeAreaResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof AdministrativeAreaResponse
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof AdministrativeAreaResponse
     */
    'country'?: AdministrativeAreaResponseCountryEnum;
    /**
     * 
     * @type {string}
     * @memberof AdministrativeAreaResponse
     */
    'stateOrProvince'?: AdministrativeAreaResponseStateOrProvinceEnum;
    /**
     * 
     * @type {MoneyValue}
     * @memberof AdministrativeAreaResponse
     */
    'signupFee'?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof AdministrativeAreaResponse
     */
    'licenseTransferFee'?: MoneyValue;
    /**
     * 
     * @type {boolean}
     * @memberof AdministrativeAreaResponse
     */
    'agentCanTransferLicense'?: boolean;
}

export const AdministrativeAreaResponseCountryEnum = {
    UnitedStates: 'UNITED_STATES',
    Canada: 'CANADA'
} as const;

export type AdministrativeAreaResponseCountryEnum = typeof AdministrativeAreaResponseCountryEnum[keyof typeof AdministrativeAreaResponseCountryEnum];
export const AdministrativeAreaResponseStateOrProvinceEnum = {
    Alabama: 'ALABAMA',
    Alaska: 'ALASKA',
    Arizona: 'ARIZONA',
    Arkansas: 'ARKANSAS',
    California: 'CALIFORNIA',
    Colorado: 'COLORADO',
    Connecticut: 'CONNECTICUT',
    Delaware: 'DELAWARE',
    DistrictOfColumbia: 'DISTRICT_OF_COLUMBIA',
    Florida: 'FLORIDA',
    Georgia: 'GEORGIA',
    Hawaii: 'HAWAII',
    Idaho: 'IDAHO',
    Illinois: 'ILLINOIS',
    Indiana: 'INDIANA',
    Iowa: 'IOWA',
    Kansas: 'KANSAS',
    Kentucky: 'KENTUCKY',
    Louisiana: 'LOUISIANA',
    Maine: 'MAINE',
    Maryland: 'MARYLAND',
    Massachusetts: 'MASSACHUSETTS',
    Michigan: 'MICHIGAN',
    Minnesota: 'MINNESOTA',
    Mississippi: 'MISSISSIPPI',
    Missouri: 'MISSOURI',
    Montana: 'MONTANA',
    Nebraska: 'NEBRASKA',
    Nevada: 'NEVADA',
    NewHampshire: 'NEW_HAMPSHIRE',
    NewJersey: 'NEW_JERSEY',
    NewMexico: 'NEW_MEXICO',
    NewYork: 'NEW_YORK',
    NorthCarolina: 'NORTH_CAROLINA',
    NorthDakota: 'NORTH_DAKOTA',
    Ohio: 'OHIO',
    Oklahoma: 'OKLAHOMA',
    Oregon: 'OREGON',
    Pennsylvania: 'PENNSYLVANIA',
    RhodeIsland: 'RHODE_ISLAND',
    SouthCarolina: 'SOUTH_CAROLINA',
    SouthDakota: 'SOUTH_DAKOTA',
    Tennessee: 'TENNESSEE',
    Texas: 'TEXAS',
    Utah: 'UTAH',
    Vermont: 'VERMONT',
    Virginia: 'VIRGINIA',
    Washington: 'WASHINGTON',
    WestVirginia: 'WEST_VIRGINIA',
    Wisconsin: 'WISCONSIN',
    Wyoming: 'WYOMING',
    PuertoRico: 'PUERTO_RICO',
    Alberta: 'ALBERTA',
    BritishColumbia: 'BRITISH_COLUMBIA',
    Manitoba: 'MANITOBA',
    NewBrunswick: 'NEW_BRUNSWICK',
    NewfoundlandAndLabrador: 'NEWFOUNDLAND_AND_LABRADOR',
    NovaScotia: 'NOVA_SCOTIA',
    Ontario: 'ONTARIO',
    PrinceEdwardIsland: 'PRINCE_EDWARD_ISLAND',
    Quebec: 'QUEBEC',
    Saskatchewan: 'SASKATCHEWAN'
} as const;

export type AdministrativeAreaResponseStateOrProvinceEnum = typeof AdministrativeAreaResponseStateOrProvinceEnum[keyof typeof AdministrativeAreaResponseStateOrProvinceEnum];

/**
 * 
 * @export
 * @interface AgentBySlugResponse
 */
export interface AgentBySlugResponse {
    /**
     * 
     * @type {string}
     * @memberof AgentBySlugResponse
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentBySlugResponse
     */
    'middleName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentBySlugResponse
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentBySlugResponse
     */
    'company'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentBySlugResponse
     */
    'fullName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentBySlugResponse
     */
    'displayName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentBySlugResponse
     */
    'avatar'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentBySlugResponse
     */
    'about'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentBySlugResponse
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentBySlugResponse
     */
    'personalWebsiteURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentBySlugResponse
     */
    'facebookURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentBySlugResponse
     */
    'twitterURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentBySlugResponse
     */
    'instagramURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentBySlugResponse
     */
    'youtubeURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentBySlugResponse
     */
    'clubhouseURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentBySlugResponse
     */
    'linkedInURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentBySlugResponse
     */
    'zillowURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentBySlugResponse
     */
    'yelpURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentBySlugResponse
     */
    'googleBusinessProfileURL'?: string;
    /**
     * 
     * @type {Array<PublicLicenseResponse>}
     * @memberof AgentBySlugResponse
     */
    'licenses'?: Array<PublicLicenseResponse>;
    /**
     * 
     * @type {string}
     * @memberof AgentBySlugResponse
     */
    'emailAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentBySlugResponse
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {Array<PublicLocationResponse>}
     * @memberof AgentBySlugResponse
     */
    'locations'?: Array<PublicLocationResponse>;
    /**
     * 
     * @type {Array<DivisionResponse>}
     * @memberof AgentBySlugResponse
     */
    'divisions'?: Array<DivisionResponse>;
    /**
     * 
     * @type {PlanMembershipResponse}
     * @memberof AgentBySlugResponse
     */
    'planMembership'?: PlanMembershipResponse;
}
/**
 * 
 * @export
 * @interface AgentFlexTeamMembershipResponse
 */
export interface AgentFlexTeamMembershipResponse {
    /**
     * 
     * @type {string}
     * @memberof AgentFlexTeamMembershipResponse
     */
    'teamId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentFlexTeamMembershipResponse
     */
    'teamType'?: AgentFlexTeamMembershipResponseTeamTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AgentFlexTeamMembershipResponse
     */
    'teamName'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AgentFlexTeamMembershipResponse
     */
    'enforceLeaderSplit'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AgentFlexTeamMembershipResponse
     */
    'paymentDetailsVisibility'?: AgentFlexTeamMembershipResponsePaymentDetailsVisibilityEnum;
    /**
     * 
     * @type {boolean}
     * @memberof AgentFlexTeamMembershipResponse
     */
    'paidAtClosing'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AgentFlexTeamMembershipResponse
     */
    'cdaApprover'?: AgentFlexTeamMembershipResponseCdaApproverEnum;
    /**
     * 
     * @type {Array<FlexTeammateDto>}
     * @memberof AgentFlexTeamMembershipResponse
     */
    'teammates'?: Array<FlexTeammateDto>;
    /**
     * 
     * @type {Array<FlexTeamMemberDto>}
     * @memberof AgentFlexTeamMembershipResponse
     */
    'leaders'?: Array<FlexTeamMemberDto>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof AgentFlexTeamMembershipResponse
     */
    'memberIdByAgentIds'?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof AgentFlexTeamMembershipResponse
     */
    'permittedTransactionEditors'?: AgentFlexTeamMembershipResponsePermittedTransactionEditorsEnum;
}

export const AgentFlexTeamMembershipResponseTeamTypeEnum = {
    Normal: 'NORMAL',
    Platinum: 'PLATINUM',
    Domestic: 'DOMESTIC',
    Group: 'GROUP',
    Pro: 'PRO'
} as const;

export type AgentFlexTeamMembershipResponseTeamTypeEnum = typeof AgentFlexTeamMembershipResponseTeamTypeEnum[keyof typeof AgentFlexTeamMembershipResponseTeamTypeEnum];
export const AgentFlexTeamMembershipResponsePaymentDetailsVisibilityEnum = {
    None: 'NONE',
    Full: 'FULL'
} as const;

export type AgentFlexTeamMembershipResponsePaymentDetailsVisibilityEnum = typeof AgentFlexTeamMembershipResponsePaymentDetailsVisibilityEnum[keyof typeof AgentFlexTeamMembershipResponsePaymentDetailsVisibilityEnum];
export const AgentFlexTeamMembershipResponseCdaApproverEnum = {
    TeamLeader: 'TEAM_LEADER',
    TeamLeaderAndTeamAdmins: 'TEAM_LEADER_AND_TEAM_ADMINS',
    AnyTeamMembers: 'ANY_TEAM_MEMBERS'
} as const;

export type AgentFlexTeamMembershipResponseCdaApproverEnum = typeof AgentFlexTeamMembershipResponseCdaApproverEnum[keyof typeof AgentFlexTeamMembershipResponseCdaApproverEnum];
export const AgentFlexTeamMembershipResponsePermittedTransactionEditorsEnum = {
    TeamLeader: 'TEAM_LEADER',
    TeamLeaderAndTeamAdmins: 'TEAM_LEADER_AND_TEAM_ADMINS',
    AnyTeamMembers: 'ANY_TEAM_MEMBERS'
} as const;

export type AgentFlexTeamMembershipResponsePermittedTransactionEditorsEnum = typeof AgentFlexTeamMembershipResponsePermittedTransactionEditorsEnum[keyof typeof AgentFlexTeamMembershipResponsePermittedTransactionEditorsEnum];

/**
 * 
 * @export
 * @interface AgentLeadResponse
 */
export interface AgentLeadResponse {
    /**
     * 
     * @type {string}
     * @memberof AgentLeadResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof AgentLeadResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof AgentLeadResponse
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof AgentLeadResponse
     */
    'agentLeadType'?: AgentLeadResponseAgentLeadTypeEnum;
    /**
     * 
     * @type {AgentResponse}
     * @memberof AgentLeadResponse
     */
    'agentResponse'?: AgentResponse;
    /**
     * 
     * @type {string}
     * @memberof AgentLeadResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentLeadResponse
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentLeadResponse
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentLeadResponse
     */
    'message'?: string;
    /**
     * 
     * @type {AddressResponse}
     * @memberof AgentLeadResponse
     */
    'address'?: AddressResponse;
    /**
     * 
     * @type {string}
     * @memberof AgentLeadResponse
     */
    'mlsNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentLeadResponse
     */
    'listingId'?: string;
}

export const AgentLeadResponseAgentLeadTypeEnum = {
    MarketAnalysis: 'MARKET_ANALYSIS',
    Property: 'PROPERTY',
    General: 'GENERAL'
} as const;

export type AgentLeadResponseAgentLeadTypeEnum = typeof AgentLeadResponseAgentLeadTypeEnum[keyof typeof AgentLeadResponseAgentLeadTypeEnum];

/**
 * 
 * @export
 * @interface AgentLinkResponse
 */
export interface AgentLinkResponse {
    /**
     * 
     * @type {string}
     * @memberof AgentLinkResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentLinkResponse
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentLinkResponse
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentLinkResponse
     */
    'slug'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentLinkResponse
     */
    'avatar'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentLinkResponse
     */
    'emailAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentLinkResponse
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentLinkResponse
     */
    'facebookUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentLinkResponse
     */
    'linkedInUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentLinkResponse
     */
    'twitterUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentLinkResponse
     */
    'instagramUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentLinkResponse
     */
    'googleBusinessProfileUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentLinkResponse
     */
    'youtubeUrl'?: string;
    /**
     * 
     * @type {Array<AddressResponse>}
     * @memberof AgentLinkResponse
     */
    'addresses'?: Array<AddressResponse>;
    /**
     * 
     * @type {boolean}
     * @memberof AgentLinkResponse
     */
    'hasAgentWebsite'?: boolean;
    /**
     * 
     * @type {Array<Division>}
     * @memberof AgentLinkResponse
     */
    'divisions'?: Array<Division>;
}
/**
 * 
 * @export
 * @interface AgentPreviewResponse
 */
export interface AgentPreviewResponse {
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof AgentPreviewResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof AgentPreviewResponse
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AgentPreviewResponse
     */
    'licenseVerified'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AgentPreviewResponse
     */
    'needOnBoarding'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    'type'?: AgentPreviewResponseTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    'skySlopeId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    'skySlopePublicId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AgentPreviewResponse
     */
    'usesSkySlope'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    'tipaltiId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    'emailAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    'joinRealEmail'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    'middleName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    'company'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    'fullName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    'displayName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    'avatar'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    'about'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    'birthDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    'personalWebsiteURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    'facebookURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    'twitterURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    'instagramURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    'youtubeURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    'clubhouseURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    'linkedInURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    'zillowURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    'yelpURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    'googleBusinessProfileURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    'joinDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    'paymentInstructions'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    'serviceAreaZipcodes'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    'slug'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    'accountCountry'?: AgentPreviewResponseAccountCountryEnum;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    'defaultCurrency'?: AgentPreviewResponseDefaultCurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    'keyMakerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    'qualiaConnectUserId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     * @deprecated
     */
    'msDynamicsVendorNo'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AgentPreviewResponse
     */
    'paymentDetailsInvalid'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    'priorUserId'?: string;
    /**
     * 
     * @type {Array<AdministrativeAreaResponse>}
     * @memberof AgentPreviewResponse
     */
    'administrativeAreas'?: Array<AdministrativeAreaResponse>;
    /**
     * 
     * @type {Array<MsdxVendorDto>}
     * @memberof AgentPreviewResponse
     */
    'msdxVendors'?: Array<MsdxVendorDto>;
}

export const AgentPreviewResponseTypeEnum = {
    Agent: 'AGENT',
    Broker: 'BROKER',
    LoanOfficer: 'LOAN_OFFICER',
    Generic: 'GENERIC'
} as const;

export type AgentPreviewResponseTypeEnum = typeof AgentPreviewResponseTypeEnum[keyof typeof AgentPreviewResponseTypeEnum];
export const AgentPreviewResponseAccountCountryEnum = {
    UnitedStates: 'UNITED_STATES',
    Canada: 'CANADA'
} as const;

export type AgentPreviewResponseAccountCountryEnum = typeof AgentPreviewResponseAccountCountryEnum[keyof typeof AgentPreviewResponseAccountCountryEnum];
export const AgentPreviewResponseDefaultCurrencyEnum = {
    Usd: 'USD',
    Cad: 'CAD'
} as const;

export type AgentPreviewResponseDefaultCurrencyEnum = typeof AgentPreviewResponseDefaultCurrencyEnum[keyof typeof AgentPreviewResponseDefaultCurrencyEnum];

/**
 * 
 * @export
 * @interface AgentResponse
 */
export interface AgentResponse {
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof AgentResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof AgentResponse
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AgentResponse
     */
    'licenseVerified'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AgentResponse
     */
    'needOnBoarding'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'type'?: AgentResponseTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'skySlopeId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'skySlopePublicId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AgentResponse
     */
    'usesSkySlope'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'tipaltiId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'emailAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'joinRealEmail'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'middleName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'company'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'fullName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'displayName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'avatar'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'about'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'birthDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'personalWebsiteURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'facebookURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'twitterURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'instagramURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'youtubeURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'clubhouseURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'linkedInURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'zillowURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'yelpURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'googleBusinessProfileURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'joinDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'paymentInstructions'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'serviceAreaZipcodes'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'slug'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'accountCountry'?: AgentResponseAccountCountryEnum;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'defaultCurrency'?: AgentResponseDefaultCurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'keyMakerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'qualiaConnectUserId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     * @deprecated
     */
    'msDynamicsVendorNo'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AgentResponse
     */
    'paymentDetailsInvalid'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'priorUserId'?: string;
    /**
     * 
     * @type {Array<AdministrativeAreaResponse>}
     * @memberof AgentResponse
     */
    'administrativeAreas'?: Array<AdministrativeAreaResponse>;
    /**
     * 
     * @type {Array<MsdxVendorDto>}
     * @memberof AgentResponse
     */
    'msdxVendors'?: Array<MsdxVendorDto>;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'agentStatus'?: AgentResponseAgentStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'sponsorCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     * @deprecated
     */
    'sponsorId'?: string;
    /**
     * 
     * @type {AgentPreviewResponse}
     * @memberof AgentResponse
     */
    'sponsorAgent'?: AgentPreviewResponse;
    /**
     * 
     * @type {Array<SponsorResponse>}
     * @memberof AgentResponse
     */
    'sponsors'?: Array<SponsorResponse>;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'personalEmailAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'personalPhoneNumber'?: string;
    /**
     * 
     * @type {Array<AddressResponse>}
     * @memberof AgentResponse
     */
    'addresses'?: Array<AddressResponse>;
    /**
     * 
     * @type {AddressResponse}
     * @memberof AgentResponse
     */
    'primaryAddress'?: AddressResponse;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'internalPhoneNumber'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AgentResponse
     */
    'languages'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AgentResponse
     */
    'services'?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof AgentResponse
     */
    'tipaltiConfirmed'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AgentResponse
     */
    'receivesTransactionEmailNotifications'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AgentResponse
     */
    'receivesTransactionPushNotifications'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AgentResponse
     */
    'receivesPaymentEmailNotifications'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AgentResponse
     */
    'receivesPaymentPushNotifications'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AgentResponse
     */
    'notifyLeadsInSMS'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AgentResponse
     */
    'receivesLeadsOnlyFromZipcodes'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'anniversaryDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'lastAnniversaryDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'nextAnniversaryDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'deferredCapEndDate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AgentResponse
     */
    'capDeferred'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof AgentResponse
     */
    'competencies'?: Array<AgentResponseCompetenciesEnum>;
    /**
     * 
     * @type {Array<LicenseResponse>}
     * @memberof AgentResponse
     */
    'licenses'?: Array<LicenseResponse>;
    /**
     * 
     * @type {PlanMembershipResponse}
     * @memberof AgentResponse
     */
    'planMembership'?: PlanMembershipResponse;
    /**
     * 
     * @type {number}
     * @memberof AgentResponse
     */
    'sizeOfNetwork'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AgentResponse
     */
    'hasSignedICA'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'stripeCustomerId'?: string;
    /**
     * 
     * @type {number}
     * @memberof AgentResponse
     */
    'shareworksId'?: number;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'chimeStatus'?: AgentResponseChimeStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof AgentResponse
     */
    'chimeSubscribedAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof AgentResponse
     */
    'chimeUnsubscribedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'preferredNetsuiteEntity'?: AgentResponsePreferredNetsuiteEntityEnum;
    /**
     * 
     * @type {BusinessEntityValue}
     * @memberof AgentResponse
     */
    'paidViaBusinessEntity'?: BusinessEntityValue;
    /**
     * 
     * @type {boolean}
     * @memberof AgentResponse
     */
    'shouldSkipTipaltiOnboarding'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AgentResponse
     */
    'needsTaxIdOnboarding'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AgentResponse
     */
    'needsWillBeneficiaryOnboarding'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AgentResponse
     */
    'hasAgentWebsite'?: boolean;
    /**
     * 
     * @type {Array<ApplicationResponse>}
     * @memberof AgentResponse
     */
    'applications'?: Array<ApplicationResponse>;
    /**
     * 
     * @type {Array<BoardPreviewResponse>}
     * @memberof AgentResponse
     */
    'boards'?: Array<BoardPreviewResponse>;
    /**
     * 
     * @type {Array<MlsPreviewResponse>}
     * @memberof AgentResponse
     */
    'mls'?: Array<MlsPreviewResponse>;
    /**
     * 
     * @type {Array<OfficePreviewResponse>}
     * @memberof AgentResponse
     */
    'offices'?: Array<OfficePreviewResponse>;
    /**
     * 
     * @type {Array<AgentTeamMembershipResponse>}
     * @memberof AgentResponse
     */
    'teamMemberships'?: Array<AgentTeamMembershipResponse>;
    /**
     * 
     * @type {Array<AgentFlexTeamMembershipResponse>}
     * @memberof AgentResponse
     */
    'flexTeamMemberships'?: Array<AgentFlexTeamMembershipResponse>;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'terminationReason'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'terminationReasonDetails'?: string;
    /**
     * 
     * @type {number}
     * @memberof AgentResponse
     */
    'terminatedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'terminatedAsOf'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AgentResponse
     */
    'notEligibleForRehire'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AgentResponse
     */
    'needsWebsiteOnboarding'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AgentResponse
     */
    'realv1UserId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AgentResponse
     */
    'restricted'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AgentResponse
     */
    'revshareBan'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AgentResponse
     */
    'nonReportable'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AgentResponse
     */
    'commercial'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AgentResponse
     */
    'awardsBanned'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AgentResponse
     */
    'gracePeriodEnd'?: number;
    /**
     * 
     * @type {number}
     * @memberof AgentResponse
     */
    'sponsorProducingGracePeriodEnd'?: number;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'driverLicenseImagePath'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'onboardingChecklistId'?: string;
    /**
     * 
     * @type {Array<Division>}
     * @memberof AgentResponse
     */
    'divisions'?: Array<Division>;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'learnworldsUserId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'realUsername'?: string;
    /**
     * 
     * @type {OneRealImpactFundConfigDto}
     * @memberof AgentResponse
     */
    'oneRealImpactFundConfig'?: OneRealImpactFundConfigDto;
    /**
     * 
     * @type {boolean}
     * @memberof AgentResponse
     */
    'prefersSingleCheck'?: boolean;
    /**
     * 
     * @type {UserAvailabilityResponse}
     * @memberof AgentResponse
     */
    'availability'?: UserAvailabilityResponse;
    /**
     * 
     * @type {boolean}
     * @memberof AgentResponse
     */
    'optedIntoSms'?: boolean;
}

export const AgentResponseTypeEnum = {
    Agent: 'AGENT',
    Broker: 'BROKER',
    LoanOfficer: 'LOAN_OFFICER',
    Generic: 'GENERIC'
} as const;

export type AgentResponseTypeEnum = typeof AgentResponseTypeEnum[keyof typeof AgentResponseTypeEnum];
export const AgentResponseAccountCountryEnum = {
    UnitedStates: 'UNITED_STATES',
    Canada: 'CANADA'
} as const;

export type AgentResponseAccountCountryEnum = typeof AgentResponseAccountCountryEnum[keyof typeof AgentResponseAccountCountryEnum];
export const AgentResponseDefaultCurrencyEnum = {
    Usd: 'USD',
    Cad: 'CAD'
} as const;

export type AgentResponseDefaultCurrencyEnum = typeof AgentResponseDefaultCurrencyEnum[keyof typeof AgentResponseDefaultCurrencyEnum];
export const AgentResponseAgentStatusEnum = {
    Candidate: 'CANDIDATE',
    Active: 'ACTIVE',
    Inactive: 'INACTIVE',
    Rejected: 'REJECTED'
} as const;

export type AgentResponseAgentStatusEnum = typeof AgentResponseAgentStatusEnum[keyof typeof AgentResponseAgentStatusEnum];
export const AgentResponseCompetenciesEnum = {
    Residential: 'RESIDENTIAL',
    Commercial: 'COMMERCIAL',
    Buyers: 'BUYERS',
    Sellers: 'SELLERS',
    Investors: 'INVESTORS',
    FarmAndRanch: 'FARM_AND_RANCH',
    ProvidingReferrals: 'PROVIDING_REFERRALS',
    ReceivingReferrals: 'RECEIVING_REFERRALS'
} as const;

export type AgentResponseCompetenciesEnum = typeof AgentResponseCompetenciesEnum[keyof typeof AgentResponseCompetenciesEnum];
export const AgentResponseChimeStatusEnum = {
    SubscribeRequested: 'SUBSCRIBE_REQUESTED',
    UnsubscribeRequested: 'UNSUBSCRIBE_REQUESTED'
} as const;

export type AgentResponseChimeStatusEnum = typeof AgentResponseChimeStatusEnum[keyof typeof AgentResponseChimeStatusEnum];
export const AgentResponsePreferredNetsuiteEntityEnum = {
    Llc: 'REAL_BROKER_LLC',
    Ab: 'REAL_BROKER_AB',
    Bc: 'REAL_BROKER_BC',
    Lfro: 'REAL_BROKER_LFRO',
    Ontario: 'REAL_BROKER_ONTARIO',
    Manitoba: 'REAL_BROKER_MANITOBA'
} as const;

export type AgentResponsePreferredNetsuiteEntityEnum = typeof AgentResponsePreferredNetsuiteEntityEnum[keyof typeof AgentResponsePreferredNetsuiteEntityEnum];

/**
 * 
 * @export
 * @interface AgentSearchResponse
 */
export interface AgentSearchResponse {
    /**
     * 
     * @type {number}
     * @memberof AgentSearchResponse
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof AgentSearchResponse
     */
    'pageSize'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AgentSearchResponse
     */
    'hasMore'?: boolean;
    /**
     * 
     * @type {Array<AgentLinkResponse>}
     * @memberof AgentSearchResponse
     */
    'agents'?: Array<AgentLinkResponse>;
}
/**
 * 
 * @export
 * @interface AgentTeamMembershipResponse
 */
export interface AgentTeamMembershipResponse {
    /**
     * 
     * @type {TeamPreviewResponse}
     * @memberof AgentTeamMembershipResponse
     */
    'team'?: TeamPreviewResponse;
    /**
     * 
     * @type {Array<string>}
     * @memberof AgentTeamMembershipResponse
     */
    'roles'?: Array<AgentTeamMembershipResponseRolesEnum>;
    /**
     * 
     * @type {number}
     * @memberof AgentTeamMembershipResponse
     */
    'memberCommissionSplit'?: number;
}

export const AgentTeamMembershipResponseRolesEnum = {
    Member: 'MEMBER',
    Leader: 'LEADER',
    Admin: 'ADMIN'
} as const;

export type AgentTeamMembershipResponseRolesEnum = typeof AgentTeamMembershipResponseRolesEnum[keyof typeof AgentTeamMembershipResponseRolesEnum];

/**
 * 
 * @export
 * @interface AgentTestimonialResponse
 */
export interface AgentTestimonialResponse {
    /**
     * 
     * @type {string}
     * @memberof AgentTestimonialResponse
     */
    'clientName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentTestimonialResponse
     */
    'content'?: string;
}
/**
 * 
 * @export
 * @interface AgentWebsiteResponse
 */
export interface AgentWebsiteResponse {
    /**
     * 
     * @type {string}
     * @memberof AgentWebsiteResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof AgentWebsiteResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof AgentWebsiteResponse
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {AgentResponse}
     * @memberof AgentWebsiteResponse
     */
    'agentResponse'?: AgentResponse;
    /**
     * 
     * @type {string}
     * @memberof AgentWebsiteResponse
     */
    'photoSet'?: AgentWebsiteResponsePhotoSetEnum;
    /**
     * 
     * @type {string}
     * @memberof AgentWebsiteResponse
     */
    'themeColor'?: AgentWebsiteResponseThemeColorEnum;
    /**
     * 
     * @type {string}
     * @memberof AgentWebsiteResponse
     */
    'websiteTitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentWebsiteResponse
     */
    'mainTitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentWebsiteResponse
     */
    'mainSubtitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentWebsiteResponse
     */
    'bottomTitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentWebsiteResponse
     */
    'bottomSubtitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentWebsiteResponse
     */
    'feature1Title'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentWebsiteResponse
     */
    'feature1Content'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentWebsiteResponse
     */
    'feature2Title'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentWebsiteResponse
     */
    'feature2Content'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentWebsiteResponse
     */
    'feature3Title'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentWebsiteResponse
     */
    'feature3Content'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AgentWebsiteResponse
     */
    'showReferralLink'?: boolean;
    /**
     * 
     * @type {Array<AgentTestimonialResponse>}
     * @memberof AgentWebsiteResponse
     */
    'testimonials'?: Array<AgentTestimonialResponse>;
    /**
     * 
     * @type {PropertyFilterResponse}
     * @memberof AgentWebsiteResponse
     */
    'propertyFilter'?: PropertyFilterResponse;
}

export const AgentWebsiteResponsePhotoSetEnum = {
    Cabin: 'CABIN',
    Luxury: 'LUXURY',
    Urban: 'URBAN',
    Country: 'COUNTRY',
    PhotoSet5: 'PHOTO_SET_5',
    PhotoSet6: 'PHOTO_SET_6',
    PhotoSet7: 'PHOTO_SET_7',
    PhotoSet8: 'PHOTO_SET_8',
    PhotoSet9: 'PHOTO_SET_9',
    PhotoSet10: 'PHOTO_SET_10'
} as const;

export type AgentWebsiteResponsePhotoSetEnum = typeof AgentWebsiteResponsePhotoSetEnum[keyof typeof AgentWebsiteResponsePhotoSetEnum];
export const AgentWebsiteResponseThemeColorEnum = {
    Green: 'GREEN',
    Black: 'BLACK',
    Blue: 'BLUE',
    Red: 'RED',
    Yellow: 'YELLOW',
    Magenta: 'MAGENTA'
} as const;

export type AgentWebsiteResponseThemeColorEnum = typeof AgentWebsiteResponseThemeColorEnum[keyof typeof AgentWebsiteResponseThemeColorEnum];

/**
 * 
 * @export
 * @interface ApplicationResponse
 */
export interface ApplicationResponse {
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApplicationResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationResponse
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    'agentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    'status'?: ApplicationResponseStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    'nextStatus'?: ApplicationResponseNextStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    'applicationType'?: ApplicationResponseApplicationTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    'country'?: ApplicationResponseCountryEnum;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    'emailAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    'birthDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    'boardOrMls'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     * @deprecated
     */
    'board'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     * @deprecated
     */
    'mls'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    'currentBrokerage'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationResponse
     */
    'hasPendingTransactionsWithCurrentBrokerage'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    'estimatedSales'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationResponse
     */
    'commercialAgent'?: boolean;
    /**
     * 
     * @type {Array<LicenseResponse>}
     * @memberof ApplicationResponse
     * @deprecated
     */
    'doesBusinessIn'?: Array<LicenseResponse>;
    /**
     * 
     * @type {Array<LicenseBoardMLSResponse>}
     * @memberof ApplicationResponse
     */
    'doesBusinessInExtended'?: Array<LicenseBoardMLSResponse>;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    'icaType'?: ApplicationResponseIcaTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    'icaName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    'icaSignature'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    'icaAnniversaryDateRequested'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     * @deprecated
     */
    'icaReferringAgentName'?: string;
    /**
     * 
     * @type {Array<SponsoringAgentDto>}
     * @memberof ApplicationResponse
     */
    'icaSponsoringAgents'?: Array<SponsoringAgentDto>;
    /**
     * 
     * @type {number}
     * @memberof ApplicationResponse
     */
    'icaSignedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    'teamName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    'teamLeaderName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    'teamRole'?: ApplicationResponseTeamRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    'preferredTitleVendor'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApplicationResponse
     */
    'feesPaidAt'?: number;
    /**
     * 
     * @type {MoneyValue}
     * @memberof ApplicationResponse
     */
    'paidFeesAmount'?: MoneyValue;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    'feesStripeChargeId'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApplicationResponse
     */
    'approvedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    'approvedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    'sponsorCode'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApplicationResponse
     */
    'licenseTransferredAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationResponse
     */
    'rejectedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    'rejectedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    'icaEmergencyContactName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    'icaEmergencyContactPhoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    'icaEmergencyContactEmailAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    'icaEmergencyContactRelationship'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    'icaEntityName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    'icaInitials'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    'loiName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    'loiSignature'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    'loiResidentIn'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApplicationResponse
     */
    'loiNumDaysUntilJoining'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationResponse
     */
    'loiSignedAt'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationResponse
     */
    'optInToStockPlan'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ApplicationResponse
     */
    'feesWaivedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    'feesWaivedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    'driverLicenseImagePath'?: string;
    /**
     * 
     * @type {ICAAgreement}
     * @memberof ApplicationResponse
     */
    'icaAgreement'?: ICAAgreement;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    'couponCode'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationResponse
     */
    'preferredTitleVendorFlag'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationResponse
     */
    'individualAgentFlag'?: boolean;
    /**
     * 
     * @type {TeamInvitationDto}
     * @memberof ApplicationResponse
     */
    'teamInvitation'?: TeamInvitationDto;
    /**
     * 
     * @type {GenericTeamInvitationResponse}
     * @memberof ApplicationResponse
     */
    'genericTeamInvitation'?: GenericTeamInvitationResponse;
}

export const ApplicationResponseStatusEnum = {
    Created: 'CREATED',
    Started: 'STARTED',
    SignIca: 'SIGN_ICA',
    IcaSigned: 'ICA_SIGNED',
    PayFees: 'PAY_FEES',
    FeesPaid: 'FEES_PAID',
    TransferLicense: 'TRANSFER_LICENSE',
    LicenseTransferred: 'LICENSE_TRANSFERRED',
    JoinMls: 'JOIN_MLS',
    MlsJoined: 'MLS_JOINED',
    JoinBoard: 'JOIN_BOARD',
    BoardJoined: 'BOARD_JOINED',
    PendingApproval: 'PENDING_APPROVAL',
    Approved: 'APPROVED',
    Rejected: 'REJECTED',
    SignLetterOfIntent: 'SIGN_LETTER_OF_INTENT',
    LetterOfIntentSigned: 'LETTER_OF_INTENT_SIGNED',
    FeesWaived: 'FEES_WAIVED'
} as const;

export type ApplicationResponseStatusEnum = typeof ApplicationResponseStatusEnum[keyof typeof ApplicationResponseStatusEnum];
export const ApplicationResponseNextStatusEnum = {
    Created: 'CREATED',
    Started: 'STARTED',
    SignIca: 'SIGN_ICA',
    IcaSigned: 'ICA_SIGNED',
    PayFees: 'PAY_FEES',
    FeesPaid: 'FEES_PAID',
    TransferLicense: 'TRANSFER_LICENSE',
    LicenseTransferred: 'LICENSE_TRANSFERRED',
    JoinMls: 'JOIN_MLS',
    MlsJoined: 'MLS_JOINED',
    JoinBoard: 'JOIN_BOARD',
    BoardJoined: 'BOARD_JOINED',
    PendingApproval: 'PENDING_APPROVAL',
    Approved: 'APPROVED',
    Rejected: 'REJECTED',
    SignLetterOfIntent: 'SIGN_LETTER_OF_INTENT',
    LetterOfIntentSigned: 'LETTER_OF_INTENT_SIGNED',
    FeesWaived: 'FEES_WAIVED'
} as const;

export type ApplicationResponseNextStatusEnum = typeof ApplicationResponseNextStatusEnum[keyof typeof ApplicationResponseNextStatusEnum];
export const ApplicationResponseApplicationTypeEnum = {
    Regular: 'REGULAR',
    LetterOfIntent: 'LETTER_OF_INTENT',
    Ica: 'ICA'
} as const;

export type ApplicationResponseApplicationTypeEnum = typeof ApplicationResponseApplicationTypeEnum[keyof typeof ApplicationResponseApplicationTypeEnum];
export const ApplicationResponseCountryEnum = {
    UnitedStates: 'UNITED_STATES',
    Canada: 'CANADA'
} as const;

export type ApplicationResponseCountryEnum = typeof ApplicationResponseCountryEnum[keyof typeof ApplicationResponseCountryEnum];
export const ApplicationResponseIcaTypeEnum = {
    ResidentialStandardV1: 'RESIDENTIAL_STANDARD_V1',
    CommercialStandardV1: 'COMMERCIAL_STANDARD_V1'
} as const;

export type ApplicationResponseIcaTypeEnum = typeof ApplicationResponseIcaTypeEnum[keyof typeof ApplicationResponseIcaTypeEnum];
export const ApplicationResponseTeamRoleEnum = {
    Member: 'MEMBER',
    Leader: 'LEADER',
    Admin: 'ADMIN'
} as const;

export type ApplicationResponseTeamRoleEnum = typeof ApplicationResponseTeamRoleEnum[keyof typeof ApplicationResponseTeamRoleEnum];

/**
 * 
 * @export
 * @interface AwardsConfig
 */
export interface AwardsConfig {
    /**
     * 
     * @type {string}
     * @memberof AwardsConfig
     */
    'effectiveDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof AwardsConfig
     */
    'minAttractingGci'?: number;
    /**
     * 
     * @type {Array<CapLevel>}
     * @memberof AwardsConfig
     */
    'capLevels'?: Array<CapLevel>;
}
/**
 * 
 * @export
 * @interface BoardPreviewResponse
 */
export interface BoardPreviewResponse {
    /**
     * 
     * @type {string}
     * @memberof BoardPreviewResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof BoardPreviewResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof BoardPreviewResponse
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof BoardPreviewResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof BoardPreviewResponse
     */
    'logo'?: string;
    /**
     * 
     * @type {AdministrativeAreaResponse}
     * @memberof BoardPreviewResponse
     */
    'administrativeArea'?: AdministrativeAreaResponse;
    /**
     * 
     * @type {string}
     * @memberof BoardPreviewResponse
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof BoardPreviewResponse
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof BoardPreviewResponse
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof BoardPreviewResponse
     */
    'officeCode'?: string;
    /**
     * 
     * @type {AddressResponse}
     * @memberof BoardPreviewResponse
     */
    'address'?: AddressResponse;
    /**
     * 
     * @type {string}
     * @memberof BoardPreviewResponse
     */
    'billingFrequency'?: BoardPreviewResponseBillingFrequencyEnum;
    /**
     * 
     * @type {string}
     * @memberof BoardPreviewResponse
     */
    'nextDueDate'?: string;
    /**
     * 
     * @type {MoneyValue}
     * @memberof BoardPreviewResponse
     */
    'fee'?: MoneyValue;
    /**
     * 
     * @type {string}
     * @memberof BoardPreviewResponse
     */
    'billingType'?: BoardPreviewResponseBillingTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof BoardPreviewResponse
     */
    'paymentType'?: BoardPreviewResponsePaymentTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof BoardPreviewResponse
     */
    'unreportedLicenseRisk'?: BoardPreviewResponseUnreportedLicenseRiskEnum;
    /**
     * 
     * @type {string}
     * @memberof BoardPreviewResponse
     */
    'brokerAffiliation'?: BoardPreviewResponseBrokerAffiliationEnum;
}

export const BoardPreviewResponseBillingFrequencyEnum = {
    Monthly: 'MONTHLY',
    Quarterly: 'QUARTERLY',
    Yearly: 'YEARLY',
    BiYearly: 'BI_YEARLY'
} as const;

export type BoardPreviewResponseBillingFrequencyEnum = typeof BoardPreviewResponseBillingFrequencyEnum[keyof typeof BoardPreviewResponseBillingFrequencyEnum];
export const BoardPreviewResponseBillingTypeEnum = {
    DirectBillToAgent: 'DIRECT_BILL_TO_AGENT',
    BilledThroughBroker: 'BILLED_THROUGH_BROKER'
} as const;

export type BoardPreviewResponseBillingTypeEnum = typeof BoardPreviewResponseBillingTypeEnum[keyof typeof BoardPreviewResponseBillingTypeEnum];
export const BoardPreviewResponsePaymentTypeEnum = {
    CreditCard: 'CREDIT_CARD',
    Check: 'CHECK'
} as const;

export type BoardPreviewResponsePaymentTypeEnum = typeof BoardPreviewResponsePaymentTypeEnum[keyof typeof BoardPreviewResponsePaymentTypeEnum];
export const BoardPreviewResponseUnreportedLicenseRiskEnum = {
    OfficeSuspension: 'OFFICE_SUSPENSION',
    BrokerInvoiceIncrease: 'BROKER_INVOICE_INCREASE',
    BothSuspensionIncrease: 'BOTH_SUSPENSION_INCREASE',
    AgentSuspensionOnly: 'AGENT_SUSPENSION_ONLY',
    NoRisk: 'NO_RISK'
} as const;

export type BoardPreviewResponseUnreportedLicenseRiskEnum = typeof BoardPreviewResponseUnreportedLicenseRiskEnum[keyof typeof BoardPreviewResponseUnreportedLicenseRiskEnum];
export const BoardPreviewResponseBrokerAffiliationEnum = {
    Primary: 'PRIMARY',
    Secondary: 'SECONDARY'
} as const;

export type BoardPreviewResponseBrokerAffiliationEnum = typeof BoardPreviewResponseBrokerAffiliationEnum[keyof typeof BoardPreviewResponseBrokerAffiliationEnum];

/**
 * 
 * @export
 * @interface BrokerLicenseResponse
 */
export interface BrokerLicenseResponse {
    /**
     * 
     * @type {string}
     * @memberof BrokerLicenseResponse
     */
    'number'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BrokerLicenseResponse
     */
    'active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BrokerLicenseResponse
     */
    'expirationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof BrokerLicenseResponse
     */
    'country'?: BrokerLicenseResponseCountryEnum;
    /**
     * 
     * @type {string}
     * @memberof BrokerLicenseResponse
     */
    'stateOrProvince'?: BrokerLicenseResponseStateOrProvinceEnum;
}

export const BrokerLicenseResponseCountryEnum = {
    UnitedStates: 'UNITED_STATES',
    Canada: 'CANADA'
} as const;

export type BrokerLicenseResponseCountryEnum = typeof BrokerLicenseResponseCountryEnum[keyof typeof BrokerLicenseResponseCountryEnum];
export const BrokerLicenseResponseStateOrProvinceEnum = {
    Alabama: 'ALABAMA',
    Alaska: 'ALASKA',
    Arizona: 'ARIZONA',
    Arkansas: 'ARKANSAS',
    California: 'CALIFORNIA',
    Colorado: 'COLORADO',
    Connecticut: 'CONNECTICUT',
    Delaware: 'DELAWARE',
    DistrictOfColumbia: 'DISTRICT_OF_COLUMBIA',
    Florida: 'FLORIDA',
    Georgia: 'GEORGIA',
    Hawaii: 'HAWAII',
    Idaho: 'IDAHO',
    Illinois: 'ILLINOIS',
    Indiana: 'INDIANA',
    Iowa: 'IOWA',
    Kansas: 'KANSAS',
    Kentucky: 'KENTUCKY',
    Louisiana: 'LOUISIANA',
    Maine: 'MAINE',
    Maryland: 'MARYLAND',
    Massachusetts: 'MASSACHUSETTS',
    Michigan: 'MICHIGAN',
    Minnesota: 'MINNESOTA',
    Mississippi: 'MISSISSIPPI',
    Missouri: 'MISSOURI',
    Montana: 'MONTANA',
    Nebraska: 'NEBRASKA',
    Nevada: 'NEVADA',
    NewHampshire: 'NEW_HAMPSHIRE',
    NewJersey: 'NEW_JERSEY',
    NewMexico: 'NEW_MEXICO',
    NewYork: 'NEW_YORK',
    NorthCarolina: 'NORTH_CAROLINA',
    NorthDakota: 'NORTH_DAKOTA',
    Ohio: 'OHIO',
    Oklahoma: 'OKLAHOMA',
    Oregon: 'OREGON',
    Pennsylvania: 'PENNSYLVANIA',
    RhodeIsland: 'RHODE_ISLAND',
    SouthCarolina: 'SOUTH_CAROLINA',
    SouthDakota: 'SOUTH_DAKOTA',
    Tennessee: 'TENNESSEE',
    Texas: 'TEXAS',
    Utah: 'UTAH',
    Vermont: 'VERMONT',
    Virginia: 'VIRGINIA',
    Washington: 'WASHINGTON',
    WestVirginia: 'WEST_VIRGINIA',
    Wisconsin: 'WISCONSIN',
    Wyoming: 'WYOMING',
    PuertoRico: 'PUERTO_RICO',
    Alberta: 'ALBERTA',
    BritishColumbia: 'BRITISH_COLUMBIA',
    Manitoba: 'MANITOBA',
    NewBrunswick: 'NEW_BRUNSWICK',
    NewfoundlandAndLabrador: 'NEWFOUNDLAND_AND_LABRADOR',
    NovaScotia: 'NOVA_SCOTIA',
    Ontario: 'ONTARIO',
    PrinceEdwardIsland: 'PRINCE_EDWARD_ISLAND',
    Quebec: 'QUEBEC',
    Saskatchewan: 'SASKATCHEWAN'
} as const;

export type BrokerLicenseResponseStateOrProvinceEnum = typeof BrokerLicenseResponseStateOrProvinceEnum[keyof typeof BrokerLicenseResponseStateOrProvinceEnum];

/**
 * 
 * @export
 * @interface BrokerLinkResponse
 */
export interface BrokerLinkResponse {
    /**
     * 
     * @type {string}
     * @memberof BrokerLinkResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof BrokerLinkResponse
     */
    'emailAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof BrokerLinkResponse
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof BrokerLinkResponse
     */
    'middleName'?: string;
    /**
     * 
     * @type {string}
     * @memberof BrokerLinkResponse
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof BrokerLinkResponse
     */
    'fullName'?: string;
    /**
     * 
     * @type {string}
     * @memberof BrokerLinkResponse
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {Array<BrokerLicenseResponse>}
     * @memberof BrokerLinkResponse
     */
    'licenses'?: Array<BrokerLicenseResponse>;
}
/**
 * 
 * @export
 * @interface BusinessEntityValue
 */
export interface BusinessEntityValue {
    /**
     * 
     * @type {string}
     * @memberof BusinessEntityValue
     */
    'name'?: string;
    /**
     * 
     * @type {Array<NationalIdentificationValue>}
     * @memberof BusinessEntityValue
     */
    'nationalIds'?: Array<NationalIdentificationValue>;
}
/**
 * 
 * @export
 * @interface CapLevel
 */
export interface CapLevel {
    /**
     * 
     * @type {string}
     * @memberof CapLevel
     */
    'awardType'?: string;
    /**
     * 
     * @type {number}
     * @memberof CapLevel
     */
    'capLevel'?: number;
    /**
     * 
     * @type {number}
     * @memberof CapLevel
     */
    'awardAmount'?: number;
}
/**
 * 
 * @export
 * @interface CommissionPlan
 */
export interface CommissionPlan {
    /**
     * 
     * @type {string}
     * @memberof CommissionPlan
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommissionPlan
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommissionPlan
     */
    'country'?: CommissionPlanCountryEnum;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CommissionPlan
     */
    'annualCap'?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CommissionPlan
     */
    'normalTeamLeadAnnualCap'?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CommissionPlan
     */
    'normalTeamMemberAnnualCap'?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CommissionPlan
     */
    'platinumTeamLeadAnnualCap'?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CommissionPlan
     */
    'platinumTeamMemberAnnualCap'?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CommissionPlan
     */
    'domesticTeamCap'?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CommissionPlan
     */
    'groupTeamCap'?: MoneyValue;
    /**
     * 
     * @type {number}
     * @memberof CommissionPlan
     */
    'preCapRealCommissionSplit'?: number;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CommissionPlan
     */
    'postCapSalesTransactionFee'?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CommissionPlan
     */
    'postCapLeaseTransactionFee'?: MoneyValue;
    /**
     * 
     * @type {number}
     * @memberof CommissionPlan
     */
    'postCapTransactionFeePercentage'?: number;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CommissionPlan
     */
    'minCommissionForPostCapTransactionFeePercentage'?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CommissionPlan
     */
    'signUpFee'?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CommissionPlan
     */
    'personalDealFee'?: MoneyValue;
    /**
     * 
     * @type {number}
     * @memberof CommissionPlan
     */
    'allowedPersonalDeals'?: number;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CommissionPlan
     */
    'annualBrokerageFee'?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CommissionPlan
     */
    'perTransactionBrokerageFee'?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CommissionPlan
     */
    'minCommissionForBrokerageFee'?: MoneyValue;
    /**
     * 
     * @type {boolean}
     * @memberof CommissionPlan
     */
    'commercial'?: boolean;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CommissionPlan
     */
    'beopFee'?: MoneyValue;
}

export const CommissionPlanCountryEnum = {
    UnitedStates: 'UNITED_STATES',
    Canada: 'CANADA'
} as const;

export type CommissionPlanCountryEnum = typeof CommissionPlanCountryEnum[keyof typeof CommissionPlanCountryEnum];

/**
 * 
 * @export
 * @interface CommissionPlanResponse
 */
export interface CommissionPlanResponse {
    /**
     * 
     * @type {string}
     * @memberof CommissionPlanResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof CommissionPlanResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof CommissionPlanResponse
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof CommissionPlanResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommissionPlanResponse
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommissionPlanResponse
     */
    'country'?: CommissionPlanResponseCountryEnum;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CommissionPlanResponse
     */
    'annualCap'?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CommissionPlanResponse
     */
    'normalTeamLeadAnnualCap'?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CommissionPlanResponse
     */
    'normalTeamMemberAnnualCap'?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CommissionPlanResponse
     */
    'platinumTeamLeadAnnualCap'?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CommissionPlanResponse
     */
    'platinumTeamMemberAnnualCap'?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CommissionPlanResponse
     */
    'domesticTeamCap'?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CommissionPlanResponse
     */
    'groupTeamCap'?: MoneyValue;
    /**
     * 
     * @type {number}
     * @memberof CommissionPlanResponse
     */
    'preCapRealCommissionSplit'?: number;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CommissionPlanResponse
     */
    'postCapSalesTransactionFee'?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CommissionPlanResponse
     */
    'postCapLeaseTransactionFee'?: MoneyValue;
    /**
     * 
     * @type {number}
     * @memberof CommissionPlanResponse
     */
    'postCapTransactionFeePercentage'?: number;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CommissionPlanResponse
     */
    'minCommissionForPostCapTransactionFeePercentage'?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CommissionPlanResponse
     */
    'signUpFee'?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CommissionPlanResponse
     */
    'personalDealFee'?: MoneyValue;
    /**
     * 
     * @type {number}
     * @memberof CommissionPlanResponse
     */
    'allowedPersonalDeals'?: number;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CommissionPlanResponse
     */
    'annualBrokerageFee'?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CommissionPlanResponse
     */
    'perTransactionBrokerageFee'?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CommissionPlanResponse
     */
    'minCommissionForBrokerageFee'?: MoneyValue;
    /**
     * 
     * @type {boolean}
     * @memberof CommissionPlanResponse
     */
    'commercial'?: boolean;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CommissionPlanResponse
     */
    'beopFee'?: MoneyValue;
    /**
     * 
     * @type {Array<MoneyValue>}
     * @memberof CommissionPlanResponse
     */
    'allAnnualCaps'?: Array<MoneyValue>;
}

export const CommissionPlanResponseCountryEnum = {
    UnitedStates: 'UNITED_STATES',
    Canada: 'CANADA'
} as const;

export type CommissionPlanResponseCountryEnum = typeof CommissionPlanResponseCountryEnum[keyof typeof CommissionPlanResponseCountryEnum];

/**
 * 
 * @export
 * @interface CompanyResponse
 */
export interface CompanyResponse {
    /**
     * 
     * @type {string}
     * @memberof CompanyResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof CompanyResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyResponse
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof CompanyResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyResponse
     */
    'ein'?: string;
    /**
     * 
     * @type {AdministrativeAreaResponse}
     * @memberof CompanyResponse
     */
    'administrativeArea'?: AdministrativeAreaResponse;
    /**
     * 
     * @type {AddressResponse}
     * @memberof CompanyResponse
     */
    'address'?: AddressResponse;
}
/**
 * 
 * @export
 * @interface CreateAgentLeadRequest
 */
export interface CreateAgentLeadRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateAgentLeadRequest
     */
    'agentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAgentLeadRequest
     */
    'agentLeadType'?: CreateAgentLeadRequestAgentLeadTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateAgentLeadRequest
     */
    'name'?: string;
    /**
     * 
     * @type {AddressRequest}
     * @memberof CreateAgentLeadRequest
     */
    'addressRequest'?: AddressRequest;
    /**
     * 
     * @type {string}
     * @memberof CreateAgentLeadRequest
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAgentLeadRequest
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAgentLeadRequest
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAgentLeadRequest
     */
    'mlsNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAgentLeadRequest
     */
    'listingId'?: string;
}

export const CreateAgentLeadRequestAgentLeadTypeEnum = {
    MarketAnalysis: 'MARKET_ANALYSIS',
    Property: 'PROPERTY',
    General: 'GENERAL'
} as const;

export type CreateAgentLeadRequestAgentLeadTypeEnum = typeof CreateAgentLeadRequestAgentLeadTypeEnum[keyof typeof CreateAgentLeadRequestAgentLeadTypeEnum];

/**
 * 
 * @export
 * @interface DateRange
 */
export interface DateRange {
    /**
     * 
     * @type {string}
     * @memberof DateRange
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof DateRange
     */
    'endDate': string;
}
/**
 * 
 * @export
 * @interface DayHourRange
 */
export interface DayHourRange {
    /**
     * 
     * @type {string}
     * @memberof DayHourRange
     */
    'dayOfWeek': DayHourRangeDayOfWeekEnum;
    /**
     * 
     * @type {HourRange}
     * @memberof DayHourRange
     */
    'hourRange': HourRange;
}

export const DayHourRangeDayOfWeekEnum = {
    Monday: 'MONDAY',
    Tuesday: 'TUESDAY',
    Wednesday: 'WEDNESDAY',
    Thursday: 'THURSDAY',
    Friday: 'FRIDAY',
    Saturday: 'SATURDAY',
    Sunday: 'SUNDAY'
} as const;

export type DayHourRangeDayOfWeekEnum = typeof DayHourRangeDayOfWeekEnum[keyof typeof DayHourRangeDayOfWeekEnum];

/**
 * 
 * @export
 * @interface Division
 */
export interface Division {
    /**
     * 
     * @type {string}
     * @memberof Division
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Division
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Division
     */
    'logoUrl'?: string;
}
/**
 * 
 * @export
 * @interface DivisionResponse
 */
export interface DivisionResponse {
    /**
     * 
     * @type {string}
     * @memberof DivisionResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DivisionResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof DivisionResponse
     */
    'logoUrl'?: string;
}
/**
 * 
 * @export
 * @interface EliteEquityAwardPlan
 */
export interface EliteEquityAwardPlan {
    /**
     * 
     * @type {string}
     * @memberof EliteEquityAwardPlan
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof EliteEquityAwardPlan
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof EliteEquityAwardPlan
     */
    'country'?: EliteEquityAwardPlanCountryEnum;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EliteEquityAwardPlan
     */
    'postCapTransactionFeesTotal'?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EliteEquityAwardPlan
     */
    'minGCI'?: MoneyValue;
    /**
     * 
     * @type {number}
     * @memberof EliteEquityAwardPlan
     */
    'minTransactionsClosed'?: number;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EliteEquityAwardPlan
     */
    'minTransactionPrice'?: MoneyValue;
    /**
     * 
     * @type {number}
     * @memberof EliteEquityAwardPlan
     */
    'minParticipationLevel'?: number;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EliteEquityAwardPlan
     */
    'award'?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EliteEquityAwardPlan
     */
    'postEliteLeaseTransactionFee'?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EliteEquityAwardPlan
     */
    'postEliteSalesTransactionFee'?: MoneyValue;
}

export const EliteEquityAwardPlanCountryEnum = {
    UnitedStates: 'UNITED_STATES',
    Canada: 'CANADA'
} as const;

export type EliteEquityAwardPlanCountryEnum = typeof EliteEquityAwardPlanCountryEnum[keyof typeof EliteEquityAwardPlanCountryEnum];

/**
 * 
 * @export
 * @interface EliteEquityAwardPlanResponse
 */
export interface EliteEquityAwardPlanResponse {
    /**
     * 
     * @type {string}
     * @memberof EliteEquityAwardPlanResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof EliteEquityAwardPlanResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof EliteEquityAwardPlanResponse
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof EliteEquityAwardPlanResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof EliteEquityAwardPlanResponse
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof EliteEquityAwardPlanResponse
     */
    'country'?: EliteEquityAwardPlanResponseCountryEnum;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EliteEquityAwardPlanResponse
     */
    'postCapTransactionFeesTotal'?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EliteEquityAwardPlanResponse
     */
    'minGCI'?: MoneyValue;
    /**
     * 
     * @type {number}
     * @memberof EliteEquityAwardPlanResponse
     */
    'minTransactionsClosed'?: number;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EliteEquityAwardPlanResponse
     */
    'minTransactionPrice'?: MoneyValue;
    /**
     * 
     * @type {number}
     * @memberof EliteEquityAwardPlanResponse
     */
    'minParticipationLevel'?: number;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EliteEquityAwardPlanResponse
     */
    'award'?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EliteEquityAwardPlanResponse
     */
    'postEliteLeaseTransactionFee'?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EliteEquityAwardPlanResponse
     */
    'postEliteSalesTransactionFee'?: MoneyValue;
}

export const EliteEquityAwardPlanResponseCountryEnum = {
    UnitedStates: 'UNITED_STATES',
    Canada: 'CANADA'
} as const;

export type EliteEquityAwardPlanResponseCountryEnum = typeof EliteEquityAwardPlanResponseCountryEnum[keyof typeof EliteEquityAwardPlanResponseCountryEnum];

/**
 * 
 * @export
 * @interface EquityAwardPlanResponse
 */
export interface EquityAwardPlanResponse {
    /**
     * 
     * @type {string}
     * @memberof EquityAwardPlanResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof EquityAwardPlanResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof EquityAwardPlanResponse
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof EquityAwardPlanResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof EquityAwardPlanResponse
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof EquityAwardPlanResponse
     */
    'country'?: EquityAwardPlanResponseCountryEnum;
    /**
     * 
     * @type {Array<EquityAwardTierResponse>}
     * @memberof EquityAwardPlanResponse
     */
    'tiers'?: Array<EquityAwardTierResponse>;
}

export const EquityAwardPlanResponseCountryEnum = {
    UnitedStates: 'UNITED_STATES',
    Canada: 'CANADA'
} as const;

export type EquityAwardPlanResponseCountryEnum = typeof EquityAwardPlanResponseCountryEnum[keyof typeof EquityAwardPlanResponseCountryEnum];

/**
 * 
 * @export
 * @interface EquityAwardTierResponse
 */
export interface EquityAwardTierResponse {
    /**
     * 
     * @type {number}
     * @memberof EquityAwardTierResponse
     */
    'minNumberOfAgents'?: number;
    /**
     * 
     * @type {number}
     * @memberof EquityAwardTierResponse
     */
    'maxNumberOfAgents'?: number;
    /**
     * 
     * @type {number}
     * @memberof EquityAwardTierResponse
     */
    'numberOfSharesForCapping'?: number;
    /**
     * 
     * @type {number}
     * @memberof EquityAwardTierResponse
     */
    'numberOfSharesForAttracting'?: number;
}
/**
 * 
 * @export
 * @interface EquityPurchasePlan
 */
export interface EquityPurchasePlan {
    /**
     * 
     * @type {string}
     * @memberof EquityPurchasePlan
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof EquityPurchasePlan
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof EquityPurchasePlan
     */
    'country'?: EquityPurchasePlanCountryEnum;
    /**
     * 
     * @type {number}
     * @memberof EquityPurchasePlan
     */
    'preCapNetCommissionPercent'?: number;
    /**
     * 
     * @type {number}
     * @memberof EquityPurchasePlan
     */
    'postCapNetCommissionPercent'?: number;
    /**
     * 
     * @type {number}
     * @memberof EquityPurchasePlan
     */
    'preCapBonusSharePercent'?: number;
    /**
     * 
     * @type {number}
     * @memberof EquityPurchasePlan
     */
    'postCapBonusSharePercent'?: number;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EquityPurchasePlan
     */
    'preCapMax'?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EquityPurchasePlan
     */
    'normalTeamMemberPreCapMax'?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EquityPurchasePlan
     */
    'normalTeamLeaderPreCapMax'?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EquityPurchasePlan
     */
    'platinumTeamMemberPreCapMax'?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EquityPurchasePlan
     */
    'platinumTeamLeaderPreCapMax'?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EquityPurchasePlan
     */
    'groupTeamPreCapMax'?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EquityPurchasePlan
     */
    'domesticTeamPreCapMax'?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EquityPurchasePlan
     */
    'postCapMax'?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EquityPurchasePlan
     */
    'normalTeamMemberPostCapMax'?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EquityPurchasePlan
     */
    'normalTeamLeaderPostCapMax'?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EquityPurchasePlan
     */
    'platinumTeamMemberPostCapMax'?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EquityPurchasePlan
     */
    'platinumTeamLeaderPostCapMax'?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EquityPurchasePlan
     */
    'groupTeamPostCapMax'?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EquityPurchasePlan
     */
    'domesticTeamPostCapMax'?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EquityPurchasePlan
     */
    'minPurchase'?: MoneyValue;
}

export const EquityPurchasePlanCountryEnum = {
    UnitedStates: 'UNITED_STATES',
    Canada: 'CANADA'
} as const;

export type EquityPurchasePlanCountryEnum = typeof EquityPurchasePlanCountryEnum[keyof typeof EquityPurchasePlanCountryEnum];

/**
 * 
 * @export
 * @interface EquityPurchasePlanResponse
 */
export interface EquityPurchasePlanResponse {
    /**
     * 
     * @type {string}
     * @memberof EquityPurchasePlanResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof EquityPurchasePlanResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof EquityPurchasePlanResponse
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof EquityPurchasePlanResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof EquityPurchasePlanResponse
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof EquityPurchasePlanResponse
     */
    'country'?: EquityPurchasePlanResponseCountryEnum;
    /**
     * 
     * @type {number}
     * @memberof EquityPurchasePlanResponse
     */
    'preCapNetCommissionPercent'?: number;
    /**
     * 
     * @type {number}
     * @memberof EquityPurchasePlanResponse
     */
    'postCapNetCommissionPercent'?: number;
    /**
     * 
     * @type {number}
     * @memberof EquityPurchasePlanResponse
     */
    'preCapBonusSharePercent'?: number;
    /**
     * 
     * @type {number}
     * @memberof EquityPurchasePlanResponse
     */
    'postCapBonusSharePercent'?: number;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EquityPurchasePlanResponse
     */
    'preCapMax'?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EquityPurchasePlanResponse
     */
    'normalTeamMemberPreCapMax'?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EquityPurchasePlanResponse
     */
    'normalTeamLeaderPreCapMax'?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EquityPurchasePlanResponse
     */
    'platinumTeamMemberPreCapMax'?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EquityPurchasePlanResponse
     */
    'platinumTeamLeaderPreCapMax'?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EquityPurchasePlanResponse
     */
    'groupTeamPreCapMax'?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EquityPurchasePlanResponse
     */
    'domesticTeamPreCapMax'?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EquityPurchasePlanResponse
     */
    'postCapMax'?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EquityPurchasePlanResponse
     */
    'normalTeamMemberPostCapMax'?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EquityPurchasePlanResponse
     */
    'normalTeamLeaderPostCapMax'?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EquityPurchasePlanResponse
     */
    'platinumTeamMemberPostCapMax'?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EquityPurchasePlanResponse
     */
    'platinumTeamLeaderPostCapMax'?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EquityPurchasePlanResponse
     */
    'groupTeamPostCapMax'?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EquityPurchasePlanResponse
     */
    'domesticTeamPostCapMax'?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EquityPurchasePlanResponse
     */
    'minPurchase'?: MoneyValue;
}

export const EquityPurchasePlanResponseCountryEnum = {
    UnitedStates: 'UNITED_STATES',
    Canada: 'CANADA'
} as const;

export type EquityPurchasePlanResponseCountryEnum = typeof EquityPurchasePlanResponseCountryEnum[keyof typeof EquityPurchasePlanResponseCountryEnum];

/**
 * 
 * @export
 * @interface FeeSplit
 */
export interface FeeSplit {
    /**
     * 
     * @type {string}
     * @memberof FeeSplit
     */
    'feeType': FeeSplitFeeTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof FeeSplit
     */
    'percent': number;
}

export const FeeSplitFeeTypeEnum = {
    BrokerageFee: 'BROKERAGE_FEE',
    PersonalDealFee: 'PERSONAL_DEAL_FEE',
    RealSplit: 'REAL_SPLIT',
    TransactionFee: 'TRANSACTION_FEE',
    TransactionCoordinatorFee: 'TRANSACTION_COORDINATOR_FEE',
    MlsFee: 'MLS_FEE',
    BeopFee: 'BEOP_FEE'
} as const;

export type FeeSplitFeeTypeEnum = typeof FeeSplitFeeTypeEnum[keyof typeof FeeSplitFeeTypeEnum];

/**
 * 
 * @export
 * @interface FlexTeamMemberDto
 */
export interface FlexTeamMemberDto {
    /**
     * 
     * @type {string}
     * @memberof FlexTeamMemberDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FlexTeamMemberDto
     */
    'agentId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof FlexTeamMemberDto
     */
    'roles'?: Array<FlexTeamMemberDtoRolesEnum>;
    /**
     * 
     * @type {number}
     * @memberof FlexTeamMemberDto
     */
    'realCap'?: number;
}

export const FlexTeamMemberDtoRolesEnum = {
    Admin: 'ADMIN'
} as const;

export type FlexTeamMemberDtoRolesEnum = typeof FlexTeamMemberDtoRolesEnum[keyof typeof FlexTeamMemberDtoRolesEnum];

/**
 * 
 * @export
 * @interface FlexTeammateDto
 */
export interface FlexTeammateDto {
    /**
     * 
     * @type {string}
     * @memberof FlexTeammateDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FlexTeammateDto
     */
    'agentId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof FlexTeammateDto
     */
    'roles'?: Array<FlexTeammateDtoRolesEnum>;
    /**
     * 
     * @type {number}
     * @memberof FlexTeammateDto
     */
    'realCap'?: number;
    /**
     * 
     * @type {Array<MemberLeaderSplit>}
     * @memberof FlexTeammateDto
     */
    'leaderSplits'?: Array<MemberLeaderSplit>;
    /**
     * 
     * @type {Array<MemberLeaderSplit>}
     * @memberof FlexTeammateDto
     */
    'computedLeaderSplits'?: Array<MemberLeaderSplit>;
    /**
     * 
     * @type {Array<MemberFeeSplit>}
     * @memberof FlexTeammateDto
     */
    'feeSplits'?: Array<MemberFeeSplit>;
    /**
     * 
     * @type {Array<MemberFeeSplit>}
     * @memberof FlexTeammateDto
     */
    'computedFeeSplits'?: Array<MemberFeeSplit>;
    /**
     * 
     * @type {MemberTeamCap}
     * @memberof FlexTeammateDto
     */
    'teamCap'?: MemberTeamCap;
    /**
     * 
     * @type {boolean}
     * @memberof FlexTeammateDto
     */
    'paysLeaderSplit'?: boolean;
}

export const FlexTeammateDtoRolesEnum = {
    Admin: 'ADMIN'
} as const;

export type FlexTeammateDtoRolesEnum = typeof FlexTeammateDtoRolesEnum[keyof typeof FlexTeammateDtoRolesEnum];

/**
 * 
 * @export
 * @interface FututeMaintenanceResponse
 */
export interface FututeMaintenanceResponse {
    /**
     * 
     * @type {boolean}
     * @memberof FututeMaintenanceResponse
     */
    'futureMaintenance'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FututeMaintenanceResponse
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof FututeMaintenanceResponse
     */
    'subTitle'?: string;
}
/**
 * 
 * @export
 * @interface GenericTeamInvitationResponse
 */
export interface GenericTeamInvitationResponse {
    /**
     * 
     * @type {string}
     * @memberof GenericTeamInvitationResponse
     */
    'invitationId': string;
    /**
     * 
     * @type {string}
     * @memberof GenericTeamInvitationResponse
     */
    'invitationCreatedByAgentId': string;
    /**
     * 
     * @type {string}
     * @memberof GenericTeamInvitationResponse
     */
    'teamId': string;
    /**
     * 
     * @type {number}
     * @memberof GenericTeamInvitationResponse
     */
    'capLevel'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GenericTeamInvitationResponse
     */
    'waiveFees'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GenericTeamInvitationResponse
     */
    'expirationTime': number;
    /**
     * 
     * @type {string}
     * @memberof GenericTeamInvitationResponse
     */
    'couponCode'?: string;
}
/**
 * 
 * @export
 * @interface HourRange
 */
export interface HourRange {
    /**
     * 
     * @type {string}
     * @memberof HourRange
     */
    'startTime': string;
    /**
     * 
     * @type {string}
     * @memberof HourRange
     */
    'endTime': string;
}
/**
 * 
 * @export
 * @interface ICAAgreement
 */
export interface ICAAgreement {
    /**
     * 
     * @type {string}
     * @memberof ICAAgreement
     */
    'agreementId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ICAAgreement
     */
    'signeeToken'?: string;
    /**
     * 
     * @type {number}
     * @memberof ICAAgreement
     */
    'signedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof ICAAgreement
     */
    'agreementDefinitionId'?: string;
    /**
     * 
     * @type {number}
     * @memberof ICAAgreement
     */
    'agreementVersion'?: number;
}
/**
 * 
 * @export
 * @interface InitAppRequest
 */
export interface InitAppRequest {
    /**
     * 
     * @type {string}
     * @memberof InitAppRequest
     */
    'appVersion': string;
    /**
     * 
     * @type {string}
     * @memberof InitAppRequest
     */
    'appBuildNumber': string;
}
/**
 * 
 * @export
 * @interface InitAppResponse
 */
export interface InitAppResponse {
    /**
     * 
     * @type {boolean}
     * @memberof InitAppResponse
     */
    'appCompatible'?: boolean;
}
/**
 * 
 * @export
 * @interface LicenseBoardMLSResponse
 */
export interface LicenseBoardMLSResponse {
    /**
     * 
     * @type {LicenseResponse}
     * @memberof LicenseBoardMLSResponse
     */
    'licenseResponse'?: LicenseResponse;
    /**
     * 
     * @type {Array<string>}
     * @memberof LicenseBoardMLSResponse
     */
    'boards'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof LicenseBoardMLSResponse
     */
    'mlses'?: Array<string>;
}
/**
 * 
 * @export
 * @interface LicenseResponse
 */
export interface LicenseResponse {
    /**
     * 
     * @type {string}
     * @memberof LicenseResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof LicenseResponse
     */
    'number'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LicenseResponse
     */
    'active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LicenseResponse
     */
    'expirationDate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LicenseResponse
     */
    'knownComplaints'?: boolean;
    /**
     * 
     * @type {AdministrativeAreaResponse}
     * @memberof LicenseResponse
     */
    'administrativeArea'?: AdministrativeAreaResponse;
    /**
     * 
     * @type {string}
     * @memberof LicenseResponse
     */
    'licenseImagePath'?: string;
    /**
     * 
     * @type {string}
     * @memberof LicenseResponse
     * @deprecated
     */
    'signatureImagePath'?: string;
}
/**
 * 
 * @export
 * @interface MaintenanceResponse
 */
export interface MaintenanceResponse {
    /**
     * 
     * @type {string}
     * @memberof MaintenanceResponse
     */
    'message'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MaintenanceResponse
     */
    'inMaintenancePeriod'?: boolean;
}
/**
 * 
 * @export
 * @interface MemberFeeSplit
 */
export interface MemberFeeSplit {
    /**
     * 
     * @type {string}
     * @memberof MemberFeeSplit
     */
    'leaderId': string;
    /**
     * 
     * @type {Array<FeeSplit>}
     * @memberof MemberFeeSplit
     */
    'feeSplits': Array<FeeSplit>;
}
/**
 * 
 * @export
 * @interface MemberLeaderSplit
 */
export interface MemberLeaderSplit {
    /**
     * 
     * @type {string}
     * @memberof MemberLeaderSplit
     */
    'leaderId': string;
    /**
     * 
     * @type {number}
     * @memberof MemberLeaderSplit
     */
    'leaderSplit': number;
}
/**
 * 
 * @export
 * @interface MemberTeamCap
 */
export interface MemberTeamCap {
    /**
     * 
     * @type {number}
     * @memberof MemberTeamCap
     */
    'capAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof MemberTeamCap
     */
    'preCapFee'?: number;
    /**
     * 
     * @type {number}
     * @memberof MemberTeamCap
     */
    'postCapFee'?: number;
}
/**
 * 
 * @export
 * @interface MlsPreviewResponse
 */
export interface MlsPreviewResponse {
    /**
     * 
     * @type {string}
     * @memberof MlsPreviewResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof MlsPreviewResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof MlsPreviewResponse
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof MlsPreviewResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof MlsPreviewResponse
     */
    'logo'?: string;
    /**
     * 
     * @type {Array<AdministrativeAreaResponse>}
     * @memberof MlsPreviewResponse
     */
    'administrativeAreas'?: Array<AdministrativeAreaResponse>;
    /**
     * 
     * @type {string}
     * @memberof MlsPreviewResponse
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof MlsPreviewResponse
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof MlsPreviewResponse
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof MlsPreviewResponse
     */
    'officeCode'?: string;
    /**
     * 
     * @type {AddressResponse}
     * @memberof MlsPreviewResponse
     */
    'address'?: AddressResponse;
    /**
     * 
     * @type {string}
     * @memberof MlsPreviewResponse
     */
    'billingFrequency'?: MlsPreviewResponseBillingFrequencyEnum;
    /**
     * 
     * @type {string}
     * @memberof MlsPreviewResponse
     */
    'nextDueDate'?: string;
    /**
     * 
     * @type {MoneyValue}
     * @memberof MlsPreviewResponse
     */
    'recurringFee'?: MoneyValue;
    /**
     * 
     * @type {string}
     * @memberof MlsPreviewResponse
     */
    'billingType'?: MlsPreviewResponseBillingTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof MlsPreviewResponse
     */
    'paymentType'?: MlsPreviewResponsePaymentTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof MlsPreviewResponse
     */
    'brokerOrMemberId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MlsPreviewResponse
     */
    'unreportedLicenseRisk'?: MlsPreviewResponseUnreportedLicenseRiskEnum;
    /**
     * 
     * @type {string}
     * @memberof MlsPreviewResponse
     */
    'idxFeedStatus'?: MlsPreviewResponseIdxFeedStatusEnum;
}

export const MlsPreviewResponseBillingFrequencyEnum = {
    Monthly: 'MONTHLY',
    Quarterly: 'QUARTERLY',
    Yearly: 'YEARLY',
    BiYearly: 'BI_YEARLY'
} as const;

export type MlsPreviewResponseBillingFrequencyEnum = typeof MlsPreviewResponseBillingFrequencyEnum[keyof typeof MlsPreviewResponseBillingFrequencyEnum];
export const MlsPreviewResponseBillingTypeEnum = {
    DirectBillToAgent: 'DIRECT_BILL_TO_AGENT',
    BilledThroughBroker: 'BILLED_THROUGH_BROKER'
} as const;

export type MlsPreviewResponseBillingTypeEnum = typeof MlsPreviewResponseBillingTypeEnum[keyof typeof MlsPreviewResponseBillingTypeEnum];
export const MlsPreviewResponsePaymentTypeEnum = {
    CreditCard: 'CREDIT_CARD',
    Check: 'CHECK'
} as const;

export type MlsPreviewResponsePaymentTypeEnum = typeof MlsPreviewResponsePaymentTypeEnum[keyof typeof MlsPreviewResponsePaymentTypeEnum];
export const MlsPreviewResponseUnreportedLicenseRiskEnum = {
    OfficeSuspension: 'OFFICE_SUSPENSION',
    BrokerInvoiceIncrease: 'BROKER_INVOICE_INCREASE',
    BothSuspensionIncrease: 'BOTH_SUSPENSION_INCREASE',
    AgentSuspensionOnly: 'AGENT_SUSPENSION_ONLY',
    NoRisk: 'NO_RISK'
} as const;

export type MlsPreviewResponseUnreportedLicenseRiskEnum = typeof MlsPreviewResponseUnreportedLicenseRiskEnum[keyof typeof MlsPreviewResponseUnreportedLicenseRiskEnum];
export const MlsPreviewResponseIdxFeedStatusEnum = {
    AccessAvailable: 'ACCESS_AVAILABLE',
    NotCurrentlyAvailable: 'NOT_CURRENTLY_AVAILABLE',
    PendingAccess: 'PENDING_ACCESS'
} as const;

export type MlsPreviewResponseIdxFeedStatusEnum = typeof MlsPreviewResponseIdxFeedStatusEnum[keyof typeof MlsPreviewResponseIdxFeedStatusEnum];

/**
 * 
 * @export
 * @interface MoneyValue
 */
export interface MoneyValue {
    /**
     * 
     * @type {number}
     * @memberof MoneyValue
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof MoneyValue
     */
    'currency'?: MoneyValueCurrencyEnum;
}

export const MoneyValueCurrencyEnum = {
    Usd: 'USD',
    Cad: 'CAD'
} as const;

export type MoneyValueCurrencyEnum = typeof MoneyValueCurrencyEnum[keyof typeof MoneyValueCurrencyEnum];

/**
 * 
 * @export
 * @interface MsdxVendorDto
 */
export interface MsdxVendorDto {
    /**
     * 
     * @type {string}
     * @memberof MsdxVendorDto
     */
    'subsidiary'?: MsdxVendorDtoSubsidiaryEnum;
    /**
     * 
     * @type {string}
     * @memberof MsdxVendorDto
     */
    'no'?: string;
    /**
     * 
     * @type {string}
     * @memberof MsdxVendorDto
     */
    'error'?: string;
    /**
     * 
     * @type {number}
     * @memberof MsdxVendorDto
     */
    'syncedAt'?: number;
}

export const MsdxVendorDtoSubsidiaryEnum = {
    Llc: 'REAL_BROKER_LLC',
    Ab: 'REAL_BROKER_AB',
    Bc: 'REAL_BROKER_BC',
    Ontario: 'REAL_BROKER_ONTARIO',
    Manitoba: 'REAL_BROKER_MANITOBA',
    Lfro: 'REAL_BROKER_LFRO'
} as const;

export type MsdxVendorDtoSubsidiaryEnum = typeof MsdxVendorDtoSubsidiaryEnum[keyof typeof MsdxVendorDtoSubsidiaryEnum];

/**
 * 
 * @export
 * @interface NationalIdentificationValue
 */
export interface NationalIdentificationValue {
    /**
     * 
     * @type {string}
     * @memberof NationalIdentificationValue
     */
    'type': NationalIdentificationValueTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof NationalIdentificationValue
     */
    'id': string;
}

export const NationalIdentificationValueTypeEnum = {
    Ssn: 'SSN',
    Ein: 'EIN',
    BAndOId: 'B_AND_O_ID',
    GrtId: 'GRT_ID',
    GetId: 'GET_ID',
    LaCbtId: 'LA_CBT_ID',
    CbjId: 'CBJ_ID',
    Bn: 'BN',
    GstId: 'GST_ID',
    PstId: 'PST_ID',
    HstId: 'HST_ID',
    QstId: 'QST_ID',
    Sin: 'SIN',
    Fnin: 'FNIN'
} as const;

export type NationalIdentificationValueTypeEnum = typeof NationalIdentificationValueTypeEnum[keyof typeof NationalIdentificationValueTypeEnum];

/**
 * 
 * @export
 * @interface OfficeAgentPreviewResponse
 */
export interface OfficeAgentPreviewResponse {
    /**
     * 
     * @type {string}
     * @memberof OfficeAgentPreviewResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof OfficeAgentPreviewResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof OfficeAgentPreviewResponse
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof OfficeAgentPreviewResponse
     */
    'licenseVerified'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OfficeAgentPreviewResponse
     */
    'needOnBoarding'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OfficeAgentPreviewResponse
     */
    'type'?: OfficeAgentPreviewResponseTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof OfficeAgentPreviewResponse
     */
    'skySlopeId'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeAgentPreviewResponse
     */
    'skySlopePublicId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OfficeAgentPreviewResponse
     */
    'usesSkySlope'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OfficeAgentPreviewResponse
     */
    'tipaltiId'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeAgentPreviewResponse
     */
    'emailAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeAgentPreviewResponse
     */
    'joinRealEmail'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeAgentPreviewResponse
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeAgentPreviewResponse
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeAgentPreviewResponse
     */
    'middleName'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeAgentPreviewResponse
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeAgentPreviewResponse
     */
    'company'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeAgentPreviewResponse
     */
    'fullName'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeAgentPreviewResponse
     */
    'displayName'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeAgentPreviewResponse
     */
    'avatar'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeAgentPreviewResponse
     */
    'about'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeAgentPreviewResponse
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeAgentPreviewResponse
     */
    'birthDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeAgentPreviewResponse
     */
    'personalWebsiteURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeAgentPreviewResponse
     */
    'facebookURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeAgentPreviewResponse
     */
    'twitterURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeAgentPreviewResponse
     */
    'instagramURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeAgentPreviewResponse
     */
    'youtubeURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeAgentPreviewResponse
     */
    'clubhouseURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeAgentPreviewResponse
     */
    'linkedInURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeAgentPreviewResponse
     */
    'zillowURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeAgentPreviewResponse
     */
    'yelpURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeAgentPreviewResponse
     */
    'googleBusinessProfileURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeAgentPreviewResponse
     */
    'joinDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeAgentPreviewResponse
     */
    'paymentInstructions'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeAgentPreviewResponse
     */
    'serviceAreaZipcodes'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeAgentPreviewResponse
     */
    'slug'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeAgentPreviewResponse
     */
    'accountCountry'?: OfficeAgentPreviewResponseAccountCountryEnum;
    /**
     * 
     * @type {string}
     * @memberof OfficeAgentPreviewResponse
     */
    'defaultCurrency'?: OfficeAgentPreviewResponseDefaultCurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof OfficeAgentPreviewResponse
     */
    'keyMakerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeAgentPreviewResponse
     */
    'qualiaConnectUserId'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeAgentPreviewResponse
     * @deprecated
     */
    'msDynamicsVendorNo'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OfficeAgentPreviewResponse
     */
    'paymentDetailsInvalid'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OfficeAgentPreviewResponse
     */
    'priorUserId'?: string;
    /**
     * 
     * @type {Array<AdministrativeAreaResponse>}
     * @memberof OfficeAgentPreviewResponse
     */
    'administrativeAreas'?: Array<AdministrativeAreaResponse>;
    /**
     * 
     * @type {Array<MsdxVendorDto>}
     * @memberof OfficeAgentPreviewResponse
     */
    'msdxVendors'?: Array<MsdxVendorDto>;
    /**
     * 
     * @type {Array<LicenseResponse>}
     * @memberof OfficeAgentPreviewResponse
     */
    'licenses'?: Array<LicenseResponse>;
}

export const OfficeAgentPreviewResponseTypeEnum = {
    Agent: 'AGENT',
    Broker: 'BROKER',
    LoanOfficer: 'LOAN_OFFICER',
    Generic: 'GENERIC'
} as const;

export type OfficeAgentPreviewResponseTypeEnum = typeof OfficeAgentPreviewResponseTypeEnum[keyof typeof OfficeAgentPreviewResponseTypeEnum];
export const OfficeAgentPreviewResponseAccountCountryEnum = {
    UnitedStates: 'UNITED_STATES',
    Canada: 'CANADA'
} as const;

export type OfficeAgentPreviewResponseAccountCountryEnum = typeof OfficeAgentPreviewResponseAccountCountryEnum[keyof typeof OfficeAgentPreviewResponseAccountCountryEnum];
export const OfficeAgentPreviewResponseDefaultCurrencyEnum = {
    Usd: 'USD',
    Cad: 'CAD'
} as const;

export type OfficeAgentPreviewResponseDefaultCurrencyEnum = typeof OfficeAgentPreviewResponseDefaultCurrencyEnum[keyof typeof OfficeAgentPreviewResponseDefaultCurrencyEnum];

/**
 * 
 * @export
 * @interface OfficeLinkResponse
 */
export interface OfficeLinkResponse {
    /**
     * 
     * @type {string}
     * @memberof OfficeLinkResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeLinkResponse
     */
    'name'?: string;
    /**
     * 
     * @type {AddressResponse}
     * @memberof OfficeLinkResponse
     */
    'address'?: AddressResponse;
    /**
     * 
     * @type {string}
     * @memberof OfficeLinkResponse
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeLinkResponse
     */
    'faxNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeLinkResponse
     */
    'brokerageLicenseNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeLinkResponse
     */
    'companyName'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeLinkResponse
     */
    'country'?: OfficeLinkResponseCountryEnum;
    /**
     * 
     * @type {string}
     * @memberof OfficeLinkResponse
     */
    'stateOrProvince'?: OfficeLinkResponseStateOrProvinceEnum;
    /**
     * 
     * @type {BrokerLinkResponse}
     * @memberof OfficeLinkResponse
     */
    'designatedBroker'?: BrokerLinkResponse;
}

export const OfficeLinkResponseCountryEnum = {
    UnitedStates: 'UNITED_STATES',
    Canada: 'CANADA'
} as const;

export type OfficeLinkResponseCountryEnum = typeof OfficeLinkResponseCountryEnum[keyof typeof OfficeLinkResponseCountryEnum];
export const OfficeLinkResponseStateOrProvinceEnum = {
    Alabama: 'ALABAMA',
    Alaska: 'ALASKA',
    Arizona: 'ARIZONA',
    Arkansas: 'ARKANSAS',
    California: 'CALIFORNIA',
    Colorado: 'COLORADO',
    Connecticut: 'CONNECTICUT',
    Delaware: 'DELAWARE',
    DistrictOfColumbia: 'DISTRICT_OF_COLUMBIA',
    Florida: 'FLORIDA',
    Georgia: 'GEORGIA',
    Hawaii: 'HAWAII',
    Idaho: 'IDAHO',
    Illinois: 'ILLINOIS',
    Indiana: 'INDIANA',
    Iowa: 'IOWA',
    Kansas: 'KANSAS',
    Kentucky: 'KENTUCKY',
    Louisiana: 'LOUISIANA',
    Maine: 'MAINE',
    Maryland: 'MARYLAND',
    Massachusetts: 'MASSACHUSETTS',
    Michigan: 'MICHIGAN',
    Minnesota: 'MINNESOTA',
    Mississippi: 'MISSISSIPPI',
    Missouri: 'MISSOURI',
    Montana: 'MONTANA',
    Nebraska: 'NEBRASKA',
    Nevada: 'NEVADA',
    NewHampshire: 'NEW_HAMPSHIRE',
    NewJersey: 'NEW_JERSEY',
    NewMexico: 'NEW_MEXICO',
    NewYork: 'NEW_YORK',
    NorthCarolina: 'NORTH_CAROLINA',
    NorthDakota: 'NORTH_DAKOTA',
    Ohio: 'OHIO',
    Oklahoma: 'OKLAHOMA',
    Oregon: 'OREGON',
    Pennsylvania: 'PENNSYLVANIA',
    RhodeIsland: 'RHODE_ISLAND',
    SouthCarolina: 'SOUTH_CAROLINA',
    SouthDakota: 'SOUTH_DAKOTA',
    Tennessee: 'TENNESSEE',
    Texas: 'TEXAS',
    Utah: 'UTAH',
    Vermont: 'VERMONT',
    Virginia: 'VIRGINIA',
    Washington: 'WASHINGTON',
    WestVirginia: 'WEST_VIRGINIA',
    Wisconsin: 'WISCONSIN',
    Wyoming: 'WYOMING',
    PuertoRico: 'PUERTO_RICO',
    Alberta: 'ALBERTA',
    BritishColumbia: 'BRITISH_COLUMBIA',
    Manitoba: 'MANITOBA',
    NewBrunswick: 'NEW_BRUNSWICK',
    NewfoundlandAndLabrador: 'NEWFOUNDLAND_AND_LABRADOR',
    NovaScotia: 'NOVA_SCOTIA',
    Ontario: 'ONTARIO',
    PrinceEdwardIsland: 'PRINCE_EDWARD_ISLAND',
    Quebec: 'QUEBEC',
    Saskatchewan: 'SASKATCHEWAN'
} as const;

export type OfficeLinkResponseStateOrProvinceEnum = typeof OfficeLinkResponseStateOrProvinceEnum[keyof typeof OfficeLinkResponseStateOrProvinceEnum];

/**
 * 
 * @export
 * @interface OfficePreviewResponse
 */
export interface OfficePreviewResponse {
    /**
     * 
     * @type {string}
     * @memberof OfficePreviewResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof OfficePreviewResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof OfficePreviewResponse
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof OfficePreviewResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficePreviewResponse
     */
    'emailAddress'?: string;
    /**
     * 
     * @type {AddressResponse}
     * @memberof OfficePreviewResponse
     */
    'address'?: AddressResponse;
    /**
     * 
     * @type {string}
     * @memberof OfficePreviewResponse
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficePreviewResponse
     */
    'faxNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficePreviewResponse
     */
    'brokerageLicenseNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficePreviewResponse
     */
    'licenseExpirationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficePreviewResponse
     */
    'transactionType'?: OfficePreviewResponseTransactionTypeEnum;
    /**
     * 
     * @type {AdministrativeAreaResponse}
     * @memberof OfficePreviewResponse
     */
    'administrativeArea'?: AdministrativeAreaResponse;
    /**
     * 
     * @type {OfficeAgentPreviewResponse}
     * @memberof OfficePreviewResponse
     */
    'designatedBroker'?: OfficeAgentPreviewResponse;
    /**
     * 
     * @type {OfficeAgentPreviewResponse}
     * @memberof OfficePreviewResponse
     */
    'managingBroker'?: OfficeAgentPreviewResponse;
    /**
     * 
     * @type {CompanyResponse}
     * @memberof OfficePreviewResponse
     */
    'company'?: CompanyResponse;
    /**
     * 
     * @type {boolean}
     * @memberof OfficePreviewResponse
     */
    'paysOtherAgent'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OfficePreviewResponse
     */
    'leasePaysOtherAgent'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OfficePreviewResponse
     */
    'skySlopeGuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficePreviewResponse
     */
    'cdaDocumentTitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficePreviewResponse
     */
    'invoiceDocumentTitle'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OfficePreviewResponse
     */
    'acceptsEscrow'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OfficePreviewResponse
     */
    'coordinatorFeeType'?: OfficePreviewResponseCoordinatorFeeTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof OfficePreviewResponse
     */
    'defaultOfficeInAdminArea'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OfficePreviewResponse
     */
    'paysCommissionFromTrust'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OfficePreviewResponse
     */
    'allowsLease'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OfficePreviewResponse
     */
    'dropboxId'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficePreviewResponse
     */
    'defaultOfficeDropboxId'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficePreviewResponse
     */
    'paymentSystem'?: OfficePreviewResponsePaymentSystemEnum;
    /**
     * 
     * @type {string}
     * @memberof OfficePreviewResponse
     */
    'paymentBatchName'?: OfficePreviewResponsePaymentBatchNameEnum;
    /**
     * 
     * @type {boolean}
     * @memberof OfficePreviewResponse
     */
    'realWithholdsTax'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OfficePreviewResponse
     */
    'taxPaidFromCommission'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OfficePreviewResponse
     */
    'imageUrl'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OfficePreviewResponse
     */
    'active'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof OfficePreviewResponse
     */
    'voiceCallPriorityOrder'?: Array<OfficePreviewResponseVoiceCallPriorityOrderEnum>;
    /**
     * 
     * @type {Array<VoiceCallHoldTime>}
     * @memberof OfficePreviewResponse
     */
    'voiceCallHoldTimeSecs'?: Array<VoiceCallHoldTime>;
    /**
     * 
     * @type {boolean}
     * @memberof OfficePreviewResponse
     */
    'usesTipalti'?: boolean;
}

export const OfficePreviewResponseTransactionTypeEnum = {
    SplitCheck: 'SPLIT_CHECK',
    SingleCheck: 'SINGLE_CHECK'
} as const;

export type OfficePreviewResponseTransactionTypeEnum = typeof OfficePreviewResponseTransactionTypeEnum[keyof typeof OfficePreviewResponseTransactionTypeEnum];
export const OfficePreviewResponseCoordinatorFeeTypeEnum = {
    NetCommission: 'NET_COMMISSION',
    GrossCommission: 'GROSS_COMMISSION'
} as const;

export type OfficePreviewResponseCoordinatorFeeTypeEnum = typeof OfficePreviewResponseCoordinatorFeeTypeEnum[keyof typeof OfficePreviewResponseCoordinatorFeeTypeEnum];
export const OfficePreviewResponsePaymentSystemEnum = {
    Tipalti: 'TIPALTI',
    MicrosoftDynamics: 'MICROSOFT_DYNAMICS',
    MicrosoftDynamicsPaymentJournal: 'MICROSOFT_DYNAMICS_PAYMENT_JOURNAL',
    Wallet: 'WALLET'
} as const;

export type OfficePreviewResponsePaymentSystemEnum = typeof OfficePreviewResponsePaymentSystemEnum[keyof typeof OfficePreviewResponsePaymentSystemEnum];
export const OfficePreviewResponsePaymentBatchNameEnum = {
    Rezen: 'REZEN',
    RezenRi: 'REZEN_RI',
    RezenVa: 'REZEN_VA',
    RAbCt: 'R_AB_CT',
    RAbOp: 'R_AB_OP',
    RAbTrust: 'R_AB_TRUST',
    RBcCt: 'R_BC_CT',
    RBcOp: 'R_BC_OP',
    RBcTrust: 'R_BC_TRUST',
    RMbCt: 'R_MB_CT',
    RMbOp: 'R_MB_OP',
    RMbTrust: 'R_MB_TRUST',
    ROnCt: 'R_ON_CT',
    ROnOp: 'R_ON_OP',
    ROnTrust: 'R_ON_TRUST'
} as const;

export type OfficePreviewResponsePaymentBatchNameEnum = typeof OfficePreviewResponsePaymentBatchNameEnum[keyof typeof OfficePreviewResponsePaymentBatchNameEnum];
export const OfficePreviewResponseVoiceCallPriorityOrderEnum = {
    DesignatedBroker: 'DESIGNATED_BROKER',
    RegionalBroker: 'REGIONAL_BROKER',
    ManagingBroker: 'MANAGING_BROKER',
    SettlementSpecialist: 'SETTLEMENT_SPECIALIST',
    FinanceSpecialist: 'FINANCE_SPECIALIST',
    ContractSpecialist: 'CONTRACT_SPECIALIST',
    AgentExperience: 'AGENT_EXPERIENCE',
    None: 'NONE'
} as const;

export type OfficePreviewResponseVoiceCallPriorityOrderEnum = typeof OfficePreviewResponseVoiceCallPriorityOrderEnum[keyof typeof OfficePreviewResponseVoiceCallPriorityOrderEnum];

/**
 * 
 * @export
 * @interface OfficesResponse
 */
export interface OfficesResponse {
    /**
     * 
     * @type {Array<OfficeLinkResponse>}
     * @memberof OfficesResponse
     */
    'offices'?: Array<OfficeLinkResponse>;
}
/**
 * 
 * @export
 * @interface OfficialPlanAndAwardResponse
 */
export interface OfficialPlanAndAwardResponse {
    /**
     * 
     * @type {CommissionPlan}
     * @memberof OfficialPlanAndAwardResponse
     */
    'commissionPlan'?: CommissionPlan;
    /**
     * 
     * @type {EliteEquityAwardPlan}
     * @memberof OfficialPlanAndAwardResponse
     */
    'eliteEquityAwardPlan'?: EliteEquityAwardPlan;
    /**
     * 
     * @type {AwardsConfig}
     * @memberof OfficialPlanAndAwardResponse
     */
    'awardsConfig'?: AwardsConfig;
    /**
     * 
     * @type {EquityPurchasePlan}
     * @memberof OfficialPlanAndAwardResponse
     */
    'equityPurchasePlan'?: EquityPurchasePlan;
    /**
     * 
     * @type {RevenueSharePlan}
     * @memberof OfficialPlanAndAwardResponse
     */
    'revSharePlan'?: RevenueSharePlan;
}
/**
 * 
 * @export
 * @interface OneRealImpactFundConfigDto
 */
export interface OneRealImpactFundConfigDto {
    /**
     * 
     * @type {number}
     * @memberof OneRealImpactFundConfigDto
     */
    'amount'?: number;
    /**
     * 
     * @type {number}
     * @memberof OneRealImpactFundConfigDto
     */
    'percent'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof OneRealImpactFundConfigDto
     */
    'percentEnabled'?: boolean;
}
/**
 * 
 * @export
 * @interface PlanMembershipResponse
 */
export interface PlanMembershipResponse {
    /**
     * 
     * @type {string}
     * @memberof PlanMembershipResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof PlanMembershipResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof PlanMembershipResponse
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {CommissionPlanResponse}
     * @memberof PlanMembershipResponse
     */
    'commissionPlan'?: CommissionPlanResponse;
    /**
     * 
     * @type {EquityPurchasePlanResponse}
     * @memberof PlanMembershipResponse
     */
    'equityPurchasePlan'?: EquityPurchasePlanResponse;
    /**
     * 
     * @type {EquityAwardPlanResponse}
     * @memberof PlanMembershipResponse
     */
    'equityAwardPlan'?: EquityAwardPlanResponse;
    /**
     * 
     * @type {EliteEquityAwardPlanResponse}
     * @memberof PlanMembershipResponse
     */
    'eliteEquityAwardPlan'?: EliteEquityAwardPlanResponse;
    /**
     * 
     * @type {RevenueSharePlanResponse}
     * @memberof PlanMembershipResponse
     */
    'revenueSharePlan'?: RevenueSharePlanResponse;
    /**
     * 
     * @type {string}
     * @memberof PlanMembershipResponse
     */
    'status'?: string;
    /**
     * 
     * @type {number}
     * @memberof PlanMembershipResponse
     */
    'optInToEquityPlan'?: number;
}
/**
 * 
 * @export
 * @interface PropertyFilterResponse
 */
export interface PropertyFilterResponse {
    /**
     * 
     * @type {string}
     * @memberof PropertyFilterResponse
     */
    'categoryType'?: PropertyFilterResponseCategoryTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PropertyFilterResponse
     */
    'mlsNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof PropertyFilterResponse
     */
    'googlePlaceId'?: string;
    /**
     * 
     * @type {number}
     * @memberof PropertyFilterResponse
     */
    'latitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof PropertyFilterResponse
     */
    'longitude'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof PropertyFilterResponse
     */
    'homeJunctionPropertyTypes'?: Array<PropertyFilterResponseHomeJunctionPropertyTypesEnum>;
    /**
     * 
     * @type {number}
     * @memberof PropertyFilterResponse
     */
    'minPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof PropertyFilterResponse
     */
    'maxPrice'?: number;
    /**
     * 
     * @type {string}
     * @memberof PropertyFilterResponse
     */
    'bedrooms'?: PropertyFilterResponseBedroomsEnum;
    /**
     * 
     * @type {string}
     * @memberof PropertyFilterResponse
     */
    'bathrooms'?: PropertyFilterResponseBathroomsEnum;
}

export const PropertyFilterResponseCategoryTypeEnum = {
    Buy: 'BUY',
    Rent: 'RENT',
    Sold: 'SOLD',
    Mls: 'MLS'
} as const;

export type PropertyFilterResponseCategoryTypeEnum = typeof PropertyFilterResponseCategoryTypeEnum[keyof typeof PropertyFilterResponseCategoryTypeEnum];
export const PropertyFilterResponseHomeJunctionPropertyTypesEnum = {
    Condominium: 'CONDOMINIUM',
    House: 'HOUSE',
    Multifamily: 'MULTIFAMILY',
    Townhouse: 'TOWNHOUSE',
    Land: 'LAND'
} as const;

export type PropertyFilterResponseHomeJunctionPropertyTypesEnum = typeof PropertyFilterResponseHomeJunctionPropertyTypesEnum[keyof typeof PropertyFilterResponseHomeJunctionPropertyTypesEnum];
export const PropertyFilterResponseBedroomsEnum = {
    Any: 'ANY',
    Studio: 'STUDIO',
    OneOrMore: 'ONE_OR_MORE',
    TwoOrMore: 'TWO_OR_MORE',
    ThreeOrMore: 'THREE_OR_MORE',
    FourOrMore: 'FOUR_OR_MORE',
    FiveOrMore: 'FIVE_OR_MORE'
} as const;

export type PropertyFilterResponseBedroomsEnum = typeof PropertyFilterResponseBedroomsEnum[keyof typeof PropertyFilterResponseBedroomsEnum];
export const PropertyFilterResponseBathroomsEnum = {
    Any: 'ANY',
    Studio: 'STUDIO',
    OneOrMore: 'ONE_OR_MORE',
    TwoOrMore: 'TWO_OR_MORE',
    ThreeOrMore: 'THREE_OR_MORE',
    FourOrMore: 'FOUR_OR_MORE',
    FiveOrMore: 'FIVE_OR_MORE'
} as const;

export type PropertyFilterResponseBathroomsEnum = typeof PropertyFilterResponseBathroomsEnum[keyof typeof PropertyFilterResponseBathroomsEnum];

/**
 * 
 * @export
 * @interface PublicAdministrativeAreaDto
 */
export interface PublicAdministrativeAreaDto {
    /**
     * 
     * @type {string}
     * @memberof PublicAdministrativeAreaDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicAdministrativeAreaDto
     */
    'countryIso'?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicAdministrativeAreaDto
     */
    'stateOrProvince'?: PublicAdministrativeAreaDtoStateOrProvinceEnum;
}

export const PublicAdministrativeAreaDtoStateOrProvinceEnum = {
    Alabama: 'ALABAMA',
    Alaska: 'ALASKA',
    Arizona: 'ARIZONA',
    Arkansas: 'ARKANSAS',
    California: 'CALIFORNIA',
    Colorado: 'COLORADO',
    Connecticut: 'CONNECTICUT',
    Delaware: 'DELAWARE',
    DistrictOfColumbia: 'DISTRICT_OF_COLUMBIA',
    Florida: 'FLORIDA',
    Georgia: 'GEORGIA',
    Hawaii: 'HAWAII',
    Idaho: 'IDAHO',
    Illinois: 'ILLINOIS',
    Indiana: 'INDIANA',
    Iowa: 'IOWA',
    Kansas: 'KANSAS',
    Kentucky: 'KENTUCKY',
    Louisiana: 'LOUISIANA',
    Maine: 'MAINE',
    Maryland: 'MARYLAND',
    Massachusetts: 'MASSACHUSETTS',
    Michigan: 'MICHIGAN',
    Minnesota: 'MINNESOTA',
    Mississippi: 'MISSISSIPPI',
    Missouri: 'MISSOURI',
    Montana: 'MONTANA',
    Nebraska: 'NEBRASKA',
    Nevada: 'NEVADA',
    NewHampshire: 'NEW_HAMPSHIRE',
    NewJersey: 'NEW_JERSEY',
    NewMexico: 'NEW_MEXICO',
    NewYork: 'NEW_YORK',
    NorthCarolina: 'NORTH_CAROLINA',
    NorthDakota: 'NORTH_DAKOTA',
    Ohio: 'OHIO',
    Oklahoma: 'OKLAHOMA',
    Oregon: 'OREGON',
    Pennsylvania: 'PENNSYLVANIA',
    RhodeIsland: 'RHODE_ISLAND',
    SouthCarolina: 'SOUTH_CAROLINA',
    SouthDakota: 'SOUTH_DAKOTA',
    Tennessee: 'TENNESSEE',
    Texas: 'TEXAS',
    Utah: 'UTAH',
    Vermont: 'VERMONT',
    Virginia: 'VIRGINIA',
    Washington: 'WASHINGTON',
    WestVirginia: 'WEST_VIRGINIA',
    Wisconsin: 'WISCONSIN',
    Wyoming: 'WYOMING',
    PuertoRico: 'PUERTO_RICO',
    Alberta: 'ALBERTA',
    BritishColumbia: 'BRITISH_COLUMBIA',
    Manitoba: 'MANITOBA',
    NewBrunswick: 'NEW_BRUNSWICK',
    NewfoundlandAndLabrador: 'NEWFOUNDLAND_AND_LABRADOR',
    NovaScotia: 'NOVA_SCOTIA',
    Ontario: 'ONTARIO',
    PrinceEdwardIsland: 'PRINCE_EDWARD_ISLAND',
    Quebec: 'QUEBEC',
    Saskatchewan: 'SASKATCHEWAN'
} as const;

export type PublicAdministrativeAreaDtoStateOrProvinceEnum = typeof PublicAdministrativeAreaDtoStateOrProvinceEnum[keyof typeof PublicAdministrativeAreaDtoStateOrProvinceEnum];

/**
 * 
 * @export
 * @interface PublicLicenseResponse
 */
export interface PublicLicenseResponse {
    /**
     * 
     * @type {string}
     * @memberof PublicLicenseResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicLicenseResponse
     */
    'type'?: PublicLicenseResponseTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PublicLicenseResponse
     */
    'number'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PublicLicenseResponse
     */
    'active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PublicLicenseResponse
     */
    'expirationDate'?: string;
    /**
     * 
     * @type {PublicAdministrativeAreaDto}
     * @memberof PublicLicenseResponse
     */
    'administrativeArea'?: PublicAdministrativeAreaDto;
    /**
     * 
     * @type {string}
     * @memberof PublicLicenseResponse
     */
    'licenseImagePath'?: string;
}

export const PublicLicenseResponseTypeEnum = {
    Broker: 'BROKER',
    Agent: 'AGENT',
    LoanOfficer: 'LOAN_OFFICER'
} as const;

export type PublicLicenseResponseTypeEnum = typeof PublicLicenseResponseTypeEnum[keyof typeof PublicLicenseResponseTypeEnum];

/**
 * 
 * @export
 * @interface PublicLocationResponse
 */
export interface PublicLocationResponse {
    /**
     * 
     * @type {string}
     * @memberof PublicLocationResponse
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicLocationResponse
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicLocationResponse
     */
    'stateOrProvince'?: PublicLocationResponseStateOrProvinceEnum;
    /**
     * 
     * @type {string}
     * @memberof PublicLocationResponse
     */
    'country'?: PublicLocationResponseCountryEnum;
}

export const PublicLocationResponseStateOrProvinceEnum = {
    Alabama: 'ALABAMA',
    Alaska: 'ALASKA',
    Arizona: 'ARIZONA',
    Arkansas: 'ARKANSAS',
    California: 'CALIFORNIA',
    Colorado: 'COLORADO',
    Connecticut: 'CONNECTICUT',
    Delaware: 'DELAWARE',
    DistrictOfColumbia: 'DISTRICT_OF_COLUMBIA',
    Florida: 'FLORIDA',
    Georgia: 'GEORGIA',
    Hawaii: 'HAWAII',
    Idaho: 'IDAHO',
    Illinois: 'ILLINOIS',
    Indiana: 'INDIANA',
    Iowa: 'IOWA',
    Kansas: 'KANSAS',
    Kentucky: 'KENTUCKY',
    Louisiana: 'LOUISIANA',
    Maine: 'MAINE',
    Maryland: 'MARYLAND',
    Massachusetts: 'MASSACHUSETTS',
    Michigan: 'MICHIGAN',
    Minnesota: 'MINNESOTA',
    Mississippi: 'MISSISSIPPI',
    Missouri: 'MISSOURI',
    Montana: 'MONTANA',
    Nebraska: 'NEBRASKA',
    Nevada: 'NEVADA',
    NewHampshire: 'NEW_HAMPSHIRE',
    NewJersey: 'NEW_JERSEY',
    NewMexico: 'NEW_MEXICO',
    NewYork: 'NEW_YORK',
    NorthCarolina: 'NORTH_CAROLINA',
    NorthDakota: 'NORTH_DAKOTA',
    Ohio: 'OHIO',
    Oklahoma: 'OKLAHOMA',
    Oregon: 'OREGON',
    Pennsylvania: 'PENNSYLVANIA',
    RhodeIsland: 'RHODE_ISLAND',
    SouthCarolina: 'SOUTH_CAROLINA',
    SouthDakota: 'SOUTH_DAKOTA',
    Tennessee: 'TENNESSEE',
    Texas: 'TEXAS',
    Utah: 'UTAH',
    Vermont: 'VERMONT',
    Virginia: 'VIRGINIA',
    Washington: 'WASHINGTON',
    WestVirginia: 'WEST_VIRGINIA',
    Wisconsin: 'WISCONSIN',
    Wyoming: 'WYOMING',
    PuertoRico: 'PUERTO_RICO',
    Alberta: 'ALBERTA',
    BritishColumbia: 'BRITISH_COLUMBIA',
    Manitoba: 'MANITOBA',
    NewBrunswick: 'NEW_BRUNSWICK',
    NewfoundlandAndLabrador: 'NEWFOUNDLAND_AND_LABRADOR',
    NovaScotia: 'NOVA_SCOTIA',
    Ontario: 'ONTARIO',
    PrinceEdwardIsland: 'PRINCE_EDWARD_ISLAND',
    Quebec: 'QUEBEC',
    Saskatchewan: 'SASKATCHEWAN'
} as const;

export type PublicLocationResponseStateOrProvinceEnum = typeof PublicLocationResponseStateOrProvinceEnum[keyof typeof PublicLocationResponseStateOrProvinceEnum];
export const PublicLocationResponseCountryEnum = {
    UnitedStates: 'UNITED_STATES',
    Canada: 'CANADA'
} as const;

export type PublicLocationResponseCountryEnum = typeof PublicLocationResponseCountryEnum[keyof typeof PublicLocationResponseCountryEnum];

/**
 * 
 * @export
 * @interface RevenueSharePlan
 */
export interface RevenueSharePlan {
    /**
     * 
     * @type {string}
     * @memberof RevenueSharePlan
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof RevenueSharePlan
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof RevenueSharePlan
     */
    'country'?: RevenueSharePlanCountryEnum;
    /**
     * 
     * @type {Array<RevenueShareTier>}
     * @memberof RevenueSharePlan
     */
    'tiers'?: Array<RevenueShareTier>;
    /**
     * 
     * @type {number}
     * @memberof RevenueSharePlan
     */
    'producingSpanInMonths'?: number;
    /**
     * 
     * @type {MoneyValue}
     * @memberof RevenueSharePlan
     */
    'commissionsForProducingStatus'?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof RevenueSharePlan
     */
    'annualFee'?: MoneyValue;
    /**
     * 
     * @type {number}
     * @memberof RevenueSharePlan
     */
    'processingFeePercent'?: number;
    /**
     * 
     * @type {string}
     * @memberof RevenueSharePlan
     */
    'willableRevshareAgreementTemplateId'?: string;
}

export const RevenueSharePlanCountryEnum = {
    UnitedStates: 'UNITED_STATES',
    Canada: 'CANADA'
} as const;

export type RevenueSharePlanCountryEnum = typeof RevenueSharePlanCountryEnum[keyof typeof RevenueSharePlanCountryEnum];

/**
 * 
 * @export
 * @interface RevenueSharePlanResponse
 */
export interface RevenueSharePlanResponse {
    /**
     * 
     * @type {string}
     * @memberof RevenueSharePlanResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof RevenueSharePlanResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof RevenueSharePlanResponse
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof RevenueSharePlanResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof RevenueSharePlanResponse
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof RevenueSharePlanResponse
     */
    'country'?: RevenueSharePlanResponseCountryEnum;
    /**
     * 
     * @type {Array<RevenueShareTierResponse>}
     * @memberof RevenueSharePlanResponse
     */
    'tiers'?: Array<RevenueShareTierResponse>;
    /**
     * 
     * @type {number}
     * @memberof RevenueSharePlanResponse
     */
    'producingSpanInMonths'?: number;
    /**
     * 
     * @type {MoneyValue}
     * @memberof RevenueSharePlanResponse
     */
    'commissionsForProducingStatus'?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof RevenueSharePlanResponse
     */
    'annualFee'?: MoneyValue;
    /**
     * 
     * @type {number}
     * @memberof RevenueSharePlanResponse
     */
    'processingFeePercent'?: number;
    /**
     * 
     * @type {string}
     * @memberof RevenueSharePlanResponse
     */
    'willableRevshareAgreementTemplateId'?: string;
}

export const RevenueSharePlanResponseCountryEnum = {
    UnitedStates: 'UNITED_STATES',
    Canada: 'CANADA'
} as const;

export type RevenueSharePlanResponseCountryEnum = typeof RevenueSharePlanResponseCountryEnum[keyof typeof RevenueSharePlanResponseCountryEnum];

/**
 * 
 * @export
 * @interface RevenueShareTier
 */
export interface RevenueShareTier {
    /**
     * 
     * @type {number}
     * @memberof RevenueShareTier
     */
    'tier'?: number;
    /**
     * 
     * @type {number}
     * @memberof RevenueShareTier
     */
    'commissionPercent'?: number;
    /**
     * 
     * @type {number}
     * @memberof RevenueShareTier
     */
    'numberOfTierOneAgentsToUnlockThisTier'?: number;
    /**
     * 
     * @type {MoneyValue}
     * @memberof RevenueShareTier
     */
    'maxAnnualAmountPerAgent'?: MoneyValue;
}
/**
 * 
 * @export
 * @interface RevenueShareTierResponse
 */
export interface RevenueShareTierResponse {
    /**
     * 
     * @type {string}
     * @memberof RevenueShareTierResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof RevenueShareTierResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof RevenueShareTierResponse
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof RevenueShareTierResponse
     */
    'tier'?: number;
    /**
     * 
     * @type {number}
     * @memberof RevenueShareTierResponse
     */
    'commissionPercent'?: number;
    /**
     * 
     * @type {number}
     * @memberof RevenueShareTierResponse
     */
    'numberOfTierOneAgentsToUnlockThisTier'?: number;
    /**
     * 
     * @type {MoneyValue}
     * @memberof RevenueShareTierResponse
     */
    'maxAnnualAmountPerAgent'?: MoneyValue;
}
/**
 * 
 * @export
 * @interface SponsorResponse
 */
export interface SponsorResponse {
    /**
     * 
     * @type {AgentPreviewResponse}
     * @memberof SponsorResponse
     */
    'sponsor'?: AgentPreviewResponse;
    /**
     * 
     * @type {number}
     * @memberof SponsorResponse
     */
    'revShareSplit'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SponsorResponse
     */
    'real'?: boolean;
}
/**
 * 
 * @export
 * @interface SponsoringAgentDto
 */
export interface SponsoringAgentDto {
    /**
     * 
     * @type {string}
     * @memberof SponsoringAgentDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof SponsoringAgentDto
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @interface StateBoardResponse
 */
export interface StateBoardResponse {
    /**
     * 
     * @type {string}
     * @memberof StateBoardResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof StateBoardResponse
     */
    'code'?: string;
}
/**
 * 
 * @export
 * @interface StateMlsResponse
 */
export interface StateMlsResponse {
    /**
     * 
     * @type {string}
     * @memberof StateMlsResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof StateMlsResponse
     */
    'code'?: string;
}
/**
 * 
 * @export
 * @interface StatesResponse
 */
export interface StatesResponse {
    /**
     * 
     * @type {Array<StateMlsResponse>}
     * @memberof StatesResponse
     */
    'mlsList'?: Array<StateMlsResponse>;
    /**
     * 
     * @type {Array<StateBoardResponse>}
     * @memberof StatesResponse
     */
    'boardsList'?: Array<StateBoardResponse>;
}
/**
 * 
 * @export
 * @interface TeamInvitationDto
 */
export interface TeamInvitationDto {
    /**
     * 
     * @type {string}
     * @memberof TeamInvitationDto
     */
    'invitationId': string;
    /**
     * 
     * @type {string}
     * @memberof TeamInvitationDto
     */
    'teamId': string;
    /**
     * 
     * @type {string}
     * @memberof TeamInvitationDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof TeamInvitationDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof TeamInvitationDto
     */
    'emailAddress': string;
    /**
     * 
     * @type {number}
     * @memberof TeamInvitationDto
     */
    'capLevel'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TeamInvitationDto
     */
    'waiveFees'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TeamInvitationDto
     */
    'invitationCreatedByAgentId': string;
    /**
     * 
     * @type {string}
     * @memberof TeamInvitationDto
     */
    'status': TeamInvitationDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof TeamInvitationDto
     */
    'couponCode'?: string;
    /**
     * 
     * @type {number}
     * @memberof TeamInvitationDto
     */
    'emailSentAt': number;
    /**
     * 
     * @type {string}
     * @memberof TeamInvitationDto
     */
    'applicationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TeamInvitationDto
     */
    'applicationStatus'?: TeamInvitationDtoApplicationStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof TeamInvitationDto
     */
    'emailStatus': TeamInvitationDtoEmailStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof TeamInvitationDto
     */
    'firstAndLastName'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TeamInvitationDto
     */
    'pending'?: boolean;
}

export const TeamInvitationDtoStatusEnum = {
    Emailed: 'EMAILED',
    EmailClicked: 'EMAIL_CLICKED',
    LinkClicked: 'LINK_CLICKED',
    Cancelled: 'CANCELLED',
    Pending: 'PENDING',
    Redeemed: 'REDEEMED',
    Created: 'CREATED',
    Started: 'STARTED',
    SignIca: 'SIGN_ICA',
    IcaSigned: 'ICA_SIGNED',
    PayFees: 'PAY_FEES',
    FeesPaid: 'FEES_PAID',
    TransferLicense: 'TRANSFER_LICENSE',
    LicenseTransferred: 'LICENSE_TRANSFERRED',
    JoinMls: 'JOIN_MLS',
    MlsJoined: 'MLS_JOINED',
    JoinBoard: 'JOIN_BOARD',
    BoardJoined: 'BOARD_JOINED',
    PendingApproval: 'PENDING_APPROVAL',
    Approved: 'APPROVED',
    Rejected: 'REJECTED',
    SignLetterOfIntent: 'SIGN_LETTER_OF_INTENT',
    LetterOfIntentSigned: 'LETTER_OF_INTENT_SIGNED',
    WaitingOnLicenseTransfer: 'WAITING_ON_LICENSE_TRANSFER',
    FeesWaived: 'FEES_WAIVED'
} as const;

export type TeamInvitationDtoStatusEnum = typeof TeamInvitationDtoStatusEnum[keyof typeof TeamInvitationDtoStatusEnum];
export const TeamInvitationDtoApplicationStatusEnum = {
    Created: 'CREATED',
    Started: 'STARTED',
    SignIca: 'SIGN_ICA',
    IcaSigned: 'ICA_SIGNED',
    PayFees: 'PAY_FEES',
    FeesPaid: 'FEES_PAID',
    TransferLicense: 'TRANSFER_LICENSE',
    LicenseTransferred: 'LICENSE_TRANSFERRED',
    JoinMls: 'JOIN_MLS',
    MlsJoined: 'MLS_JOINED',
    JoinBoard: 'JOIN_BOARD',
    BoardJoined: 'BOARD_JOINED',
    PendingApproval: 'PENDING_APPROVAL',
    Approved: 'APPROVED',
    Rejected: 'REJECTED',
    SignLetterOfIntent: 'SIGN_LETTER_OF_INTENT',
    LetterOfIntentSigned: 'LETTER_OF_INTENT_SIGNED',
    FeesWaived: 'FEES_WAIVED'
} as const;

export type TeamInvitationDtoApplicationStatusEnum = typeof TeamInvitationDtoApplicationStatusEnum[keyof typeof TeamInvitationDtoApplicationStatusEnum];
export const TeamInvitationDtoEmailStatusEnum = {
    Emailed: 'EMAILED',
    EmailClicked: 'EMAIL_CLICKED'
} as const;

export type TeamInvitationDtoEmailStatusEnum = typeof TeamInvitationDtoEmailStatusEnum[keyof typeof TeamInvitationDtoEmailStatusEnum];

/**
 * 
 * @export
 * @interface TeamPreviewResponse
 */
export interface TeamPreviewResponse {
    /**
     * 
     * @type {string}
     * @memberof TeamPreviewResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof TeamPreviewResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof TeamPreviewResponse
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof TeamPreviewResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof TeamPreviewResponse
     */
    'type'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TeamPreviewResponse
     */
    'paidAtClosing'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TeamPreviewResponse
     */
    'commissionDocumentApprover'?: TeamPreviewResponseCommissionDocumentApproverEnum;
}

export const TeamPreviewResponseCommissionDocumentApproverEnum = {
    TeamLeader: 'TEAM_LEADER',
    TeamLeaderAndTeamAdmins: 'TEAM_LEADER_AND_TEAM_ADMINS',
    AnyTeamMembers: 'ANY_TEAM_MEMBERS'
} as const;

export type TeamPreviewResponseCommissionDocumentApproverEnum = typeof TeamPreviewResponseCommissionDocumentApproverEnum[keyof typeof TeamPreviewResponseCommissionDocumentApproverEnum];

/**
 * 
 * @export
 * @interface UserAvailabilityResponse
 */
export interface UserAvailabilityResponse {
    /**
     * 
     * @type {Array<DayHourRange>}
     * @memberof UserAvailabilityResponse
     */
    'officeSchedule'?: Array<DayHourRange>;
    /**
     * 
     * @type {Array<DateRange>}
     * @memberof UserAvailabilityResponse
     */
    'outOfOffice'?: Array<DateRange>;
    /**
     * 
     * @type {boolean}
     * @memberof UserAvailabilityResponse
     */
    'doNotDisturb'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserAvailabilityResponse
     */
    'timeZone': string;
    /**
     * 
     * @type {boolean}
     * @memberof UserAvailabilityResponse
     */
    'available'?: boolean;
}
/**
 * 
 * @export
 * @interface VoiceCallHoldTime
 */
export interface VoiceCallHoldTime {
    /**
     * 
     * @type {string}
     * @memberof VoiceCallHoldTime
     */
    'group': VoiceCallHoldTimeGroupEnum;
    /**
     * 
     * @type {number}
     * @memberof VoiceCallHoldTime
     */
    'holdTimeSecs'?: number;
}

export const VoiceCallHoldTimeGroupEnum = {
    BrokerTeam: 'BROKER_TEAM',
    FinanceTeam: 'FINANCE_TEAM',
    SupportTeam: 'SUPPORT_TEAM',
    TransactionTeam: 'TRANSACTION_TEAM'
} as const;

export type VoiceCallHoldTimeGroupEnum = typeof VoiceCallHoldTimeGroupEnum[keyof typeof VoiceCallHoldTimeGroupEnum];


/**
 * InitAppControllerApi - axios parameter creator
 * @export
 */
export const InitAppControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary App version validity check
         * @param {string} appVersion 
         * @param {string} appBuildNumber 
         * @param {string} [xRealAppName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkAppValidity: async (appVersion: string, appBuildNumber: string, xRealAppName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appVersion' is not null or undefined
            assertParamExists('checkAppValidity', 'appVersion', appVersion)
            // verify required parameter 'appBuildNumber' is not null or undefined
            assertParamExists('checkAppValidity', 'appBuildNumber', appBuildNumber)
            const localVarPath = `/api/v1/init/check-version`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (appVersion !== undefined) {
                localVarQueryParameter['appVersion'] = appVersion;
            }

            if (appBuildNumber !== undefined) {
                localVarQueryParameter['appBuildNumber'] = appBuildNumber;
            }

            if (xRealAppName !== undefined && xRealAppName !== null) {
                localVarHeaderParameter['X-real-app-name'] = String(xRealAppName);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Initialize app
         * @param {InitAppRequest} initAppRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initApp: async (initAppRequest: InitAppRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'initAppRequest' is not null or undefined
            assertParamExists('initApp', 'initAppRequest', initAppRequest)
            const localVarPath = `/api/v1/init/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(initAppRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InitAppControllerApi - functional programming interface
 * @export
 */
export const InitAppControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InitAppControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary App version validity check
         * @param {string} appVersion 
         * @param {string} appBuildNumber 
         * @param {string} [xRealAppName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkAppValidity(appVersion: string, appBuildNumber: string, xRealAppName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InitAppResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkAppValidity(appVersion, appBuildNumber, xRealAppName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Initialize app
         * @param {InitAppRequest} initAppRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async initApp(initAppRequest: InitAppRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InitAppResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.initApp(initAppRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InitAppControllerApi - factory interface
 * @export
 */
export const InitAppControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InitAppControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary App version validity check
         * @param {string} appVersion 
         * @param {string} appBuildNumber 
         * @param {string} [xRealAppName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkAppValidity(appVersion: string, appBuildNumber: string, xRealAppName?: string, options?: any): AxiosPromise<InitAppResponse> {
            return localVarFp.checkAppValidity(appVersion, appBuildNumber, xRealAppName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Initialize app
         * @param {InitAppRequest} initAppRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initApp(initAppRequest: InitAppRequest, options?: any): AxiosPromise<InitAppResponse> {
            return localVarFp.initApp(initAppRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InitAppControllerApi - object-oriented interface
 * @export
 * @class InitAppControllerApi
 * @extends {BaseAPI}
 */
export class InitAppControllerApi extends BaseAPI {
    /**
     * 
     * @summary App version validity check
     * @param {string} appVersion 
     * @param {string} appBuildNumber 
     * @param {string} [xRealAppName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InitAppControllerApi
     */
    public checkAppValidity(appVersion: string, appBuildNumber: string, xRealAppName?: string, options?: AxiosRequestConfig) {
        return InitAppControllerApiFp(this.configuration).checkAppValidity(appVersion, appBuildNumber, xRealAppName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Initialize app
     * @param {InitAppRequest} initAppRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InitAppControllerApi
     */
    public initApp(initAppRequest: InitAppRequest, options?: AxiosRequestConfig) {
        return InitAppControllerApiFp(this.configuration).initApp(initAppRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MaintenanceControllerApi - axios parameter creator
 * @export
 */
export const MaintenanceControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkFutureMaintenancePeriod: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/maintenance/future`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkIfInMaintenancePeriod: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/maintenance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MaintenanceControllerApi - functional programming interface
 * @export
 */
export const MaintenanceControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MaintenanceControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkFutureMaintenancePeriod(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FututeMaintenanceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkFutureMaintenancePeriod(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkIfInMaintenancePeriod(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MaintenanceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkIfInMaintenancePeriod(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MaintenanceControllerApi - factory interface
 * @export
 */
export const MaintenanceControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MaintenanceControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkFutureMaintenancePeriod(options?: any): AxiosPromise<FututeMaintenanceResponse> {
            return localVarFp.checkFutureMaintenancePeriod(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkIfInMaintenancePeriod(options?: any): AxiosPromise<MaintenanceResponse> {
            return localVarFp.checkIfInMaintenancePeriod(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MaintenanceControllerApi - object-oriented interface
 * @export
 * @class MaintenanceControllerApi
 * @extends {BaseAPI}
 */
export class MaintenanceControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenanceControllerApi
     */
    public checkFutureMaintenancePeriod(options?: AxiosRequestConfig) {
        return MaintenanceControllerApiFp(this.configuration).checkFutureMaintenancePeriod(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenanceControllerApi
     */
    public checkIfInMaintenancePeriod(options?: AxiosRequestConfig) {
        return MaintenanceControllerApiFp(this.configuration).checkIfInMaintenancePeriod(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RunwayControllerApi - axios parameter creator
 * @export
 */
export const RunwayControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create an agent lead
         * @param {CreateAgentLeadRequest} createAgentLeadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAgentLead: async (createAgentLeadRequest: CreateAgentLeadRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAgentLeadRequest' is not null or undefined
            assertParamExists('createAgentLead', 'createAgentLeadRequest', createAgentLeadRequest)
            const localVarPath = `/api/v1/runway/leads`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAgentLeadRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get agent official commission plans by country
         * @param {'UNITED_STATES' | 'CANADA'} country 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getAgentCommissionPlanByCountry: async (country: 'UNITED_STATES' | 'CANADA', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'country' is not null or undefined
            assertParamExists('getAgentCommissionPlanByCountry', 'country', country)
            const localVarPath = `/api/v1/runway/agents/plans/commission/official`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (country !== undefined) {
                localVarQueryParameter['country'] = country;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get agent commission plans
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgentPlansBySlug: async (slug: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('getAgentPlansBySlug', 'slug', slug)
            const localVarPath = `/api/v1/runway/agents/slug/{slug}`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get agent website based on slug
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgentWebsiteBySlug: async (slug: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('getAgentWebsiteBySlug', 'slug', slug)
            const localVarPath = `/api/v1/runway/website/{slug}`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get paginated active agents for a given administrative area
         * @param {string} administrativeAreaId 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [name] 
         * @param {Array<string>} [country] 
         * @param {Array<string>} [divisionIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgentsByAdministrativeArea: async (administrativeAreaId: string, pageNumber?: number, pageSize?: number, name?: string, country?: Array<string>, divisionIds?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'administrativeAreaId' is not null or undefined
            assertParamExists('getAgentsByAdministrativeArea', 'administrativeAreaId', administrativeAreaId)
            const localVarPath = `/api/v1/runway/administrativeAreas/{administrativeAreaId}/agents`
                .replace(`{${"administrativeAreaId"}}`, encodeURIComponent(String(administrativeAreaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (country) {
                localVarQueryParameter['country'] = Array.from(country);
            }

            if (divisionIds) {
                localVarQueryParameter['divisionIds'] = Array.from(divisionIds);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get paginated active agents for a given US state
         * @param {string} stateAbbreviation 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getAgentsForState: async (stateAbbreviation: string, pageNumber?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stateAbbreviation' is not null or undefined
            assertParamExists('getAgentsForState', 'stateAbbreviation', stateAbbreviation)
            const localVarPath = `/api/v1/runway/states/{stateAbbreviation}/agents`
                .replace(`{${"stateAbbreviation"}}`, encodeURIComponent(String(stateAbbreviation)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all official offices
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllOfficialOffices: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/runway/offices/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get official office for state
         * @param {string} stateAbbreviation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOfficialOfficeForStateOrProvince: async (stateAbbreviation: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stateAbbreviation' is not null or undefined
            assertParamExists('getOfficialOfficeForStateOrProvince', 'stateAbbreviation', stateAbbreviation)
            const localVarPath = `/api/v1/runway/offices/{stateAbbreviation}`
                .replace(`{${"stateAbbreviation"}}`, encodeURIComponent(String(stateAbbreviation)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get agent official commission plans and awards by country
         * @param {'UNITED_STATES' | 'CANADA'} country 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOfficialPlanByCountry: async (country: 'UNITED_STATES' | 'CANADA', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'country' is not null or undefined
            assertParamExists('getOfficialPlanByCountry', 'country', country)
            const localVarPath = `/api/v1/runway/agents/plans/official`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (country !== undefined) {
                localVarQueryParameter['country'] = country;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get MLS and Boards information for a given US state
         * @param {string} stateAbbreviation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStateInformation: async (stateAbbreviation: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stateAbbreviation' is not null or undefined
            assertParamExists('getStateInformation', 'stateAbbreviation', stateAbbreviation)
            const localVarPath = `/api/v1/runway/states/{stateAbbreviation}`
                .replace(`{${"stateAbbreviation"}}`, encodeURIComponent(String(stateAbbreviation)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search for agents
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [name] 
         * @param {Array<string>} [country] 
         * @param {Array<string>} [divisionIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAgents: async (pageNumber?: number, pageSize?: number, name?: string, country?: Array<string>, divisionIds?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/runway/agents/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (country) {
                localVarQueryParameter['country'] = Array.from(country);
            }

            if (divisionIds) {
                localVarQueryParameter['divisionIds'] = Array.from(divisionIds);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RunwayControllerApi - functional programming interface
 * @export
 */
export const RunwayControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RunwayControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create an agent lead
         * @param {CreateAgentLeadRequest} createAgentLeadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAgentLead(createAgentLeadRequest: CreateAgentLeadRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentLeadResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAgentLead(createAgentLeadRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get agent official commission plans by country
         * @param {'UNITED_STATES' | 'CANADA'} country 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getAgentCommissionPlanByCountry(country: 'UNITED_STATES' | 'CANADA', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommissionPlanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAgentCommissionPlanByCountry(country, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get agent commission plans
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAgentPlansBySlug(slug: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentBySlugResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAgentPlansBySlug(slug, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get agent website based on slug
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAgentWebsiteBySlug(slug: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentWebsiteResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAgentWebsiteBySlug(slug, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get paginated active agents for a given administrative area
         * @param {string} administrativeAreaId 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [name] 
         * @param {Array<string>} [country] 
         * @param {Array<string>} [divisionIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAgentsByAdministrativeArea(administrativeAreaId: string, pageNumber?: number, pageSize?: number, name?: string, country?: Array<string>, divisionIds?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentSearchResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAgentsByAdministrativeArea(administrativeAreaId, pageNumber, pageSize, name, country, divisionIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get paginated active agents for a given US state
         * @param {string} stateAbbreviation 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getAgentsForState(stateAbbreviation: string, pageNumber?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentSearchResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAgentsForState(stateAbbreviation, pageNumber, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all official offices
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllOfficialOffices(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfficesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllOfficialOffices(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get official office for state
         * @param {string} stateAbbreviation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOfficialOfficeForStateOrProvince(stateAbbreviation: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfficeLinkResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOfficialOfficeForStateOrProvince(stateAbbreviation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get agent official commission plans and awards by country
         * @param {'UNITED_STATES' | 'CANADA'} country 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOfficialPlanByCountry(country: 'UNITED_STATES' | 'CANADA', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfficialPlanAndAwardResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOfficialPlanByCountry(country, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get MLS and Boards information for a given US state
         * @param {string} stateAbbreviation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStateInformation(stateAbbreviation: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStateInformation(stateAbbreviation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search for agents
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [name] 
         * @param {Array<string>} [country] 
         * @param {Array<string>} [divisionIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchAgents(pageNumber?: number, pageSize?: number, name?: string, country?: Array<string>, divisionIds?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentSearchResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchAgents(pageNumber, pageSize, name, country, divisionIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RunwayControllerApi - factory interface
 * @export
 */
export const RunwayControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RunwayControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Create an agent lead
         * @param {CreateAgentLeadRequest} createAgentLeadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAgentLead(createAgentLeadRequest: CreateAgentLeadRequest, options?: any): AxiosPromise<AgentLeadResponse> {
            return localVarFp.createAgentLead(createAgentLeadRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get agent official commission plans by country
         * @param {'UNITED_STATES' | 'CANADA'} country 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getAgentCommissionPlanByCountry(country: 'UNITED_STATES' | 'CANADA', options?: any): AxiosPromise<CommissionPlanResponse> {
            return localVarFp.getAgentCommissionPlanByCountry(country, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get agent commission plans
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgentPlansBySlug(slug: string, options?: any): AxiosPromise<AgentBySlugResponse> {
            return localVarFp.getAgentPlansBySlug(slug, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get agent website based on slug
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgentWebsiteBySlug(slug: string, options?: any): AxiosPromise<AgentWebsiteResponse> {
            return localVarFp.getAgentWebsiteBySlug(slug, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get paginated active agents for a given administrative area
         * @param {string} administrativeAreaId 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [name] 
         * @param {Array<string>} [country] 
         * @param {Array<string>} [divisionIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgentsByAdministrativeArea(administrativeAreaId: string, pageNumber?: number, pageSize?: number, name?: string, country?: Array<string>, divisionIds?: Array<string>, options?: any): AxiosPromise<AgentSearchResponse> {
            return localVarFp.getAgentsByAdministrativeArea(administrativeAreaId, pageNumber, pageSize, name, country, divisionIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get paginated active agents for a given US state
         * @param {string} stateAbbreviation 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getAgentsForState(stateAbbreviation: string, pageNumber?: number, pageSize?: number, options?: any): AxiosPromise<AgentSearchResponse> {
            return localVarFp.getAgentsForState(stateAbbreviation, pageNumber, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all official offices
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllOfficialOffices(options?: any): AxiosPromise<OfficesResponse> {
            return localVarFp.getAllOfficialOffices(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get official office for state
         * @param {string} stateAbbreviation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOfficialOfficeForStateOrProvince(stateAbbreviation: string, options?: any): AxiosPromise<OfficeLinkResponse> {
            return localVarFp.getOfficialOfficeForStateOrProvince(stateAbbreviation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get agent official commission plans and awards by country
         * @param {'UNITED_STATES' | 'CANADA'} country 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOfficialPlanByCountry(country: 'UNITED_STATES' | 'CANADA', options?: any): AxiosPromise<OfficialPlanAndAwardResponse> {
            return localVarFp.getOfficialPlanByCountry(country, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get MLS and Boards information for a given US state
         * @param {string} stateAbbreviation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStateInformation(stateAbbreviation: string, options?: any): AxiosPromise<StatesResponse> {
            return localVarFp.getStateInformation(stateAbbreviation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search for agents
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [name] 
         * @param {Array<string>} [country] 
         * @param {Array<string>} [divisionIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAgents(pageNumber?: number, pageSize?: number, name?: string, country?: Array<string>, divisionIds?: Array<string>, options?: any): AxiosPromise<AgentSearchResponse> {
            return localVarFp.searchAgents(pageNumber, pageSize, name, country, divisionIds, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RunwayControllerApi - object-oriented interface
 * @export
 * @class RunwayControllerApi
 * @extends {BaseAPI}
 */
export class RunwayControllerApi extends BaseAPI {
    /**
     * 
     * @summary Create an agent lead
     * @param {CreateAgentLeadRequest} createAgentLeadRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RunwayControllerApi
     */
    public createAgentLead(createAgentLeadRequest: CreateAgentLeadRequest, options?: AxiosRequestConfig) {
        return RunwayControllerApiFp(this.configuration).createAgentLead(createAgentLeadRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get agent official commission plans by country
     * @param {'UNITED_STATES' | 'CANADA'} country 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof RunwayControllerApi
     */
    public getAgentCommissionPlanByCountry(country: 'UNITED_STATES' | 'CANADA', options?: AxiosRequestConfig) {
        return RunwayControllerApiFp(this.configuration).getAgentCommissionPlanByCountry(country, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get agent commission plans
     * @param {string} slug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RunwayControllerApi
     */
    public getAgentPlansBySlug(slug: string, options?: AxiosRequestConfig) {
        return RunwayControllerApiFp(this.configuration).getAgentPlansBySlug(slug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get agent website based on slug
     * @param {string} slug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RunwayControllerApi
     */
    public getAgentWebsiteBySlug(slug: string, options?: AxiosRequestConfig) {
        return RunwayControllerApiFp(this.configuration).getAgentWebsiteBySlug(slug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get paginated active agents for a given administrative area
     * @param {string} administrativeAreaId 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {string} [name] 
     * @param {Array<string>} [country] 
     * @param {Array<string>} [divisionIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RunwayControllerApi
     */
    public getAgentsByAdministrativeArea(administrativeAreaId: string, pageNumber?: number, pageSize?: number, name?: string, country?: Array<string>, divisionIds?: Array<string>, options?: AxiosRequestConfig) {
        return RunwayControllerApiFp(this.configuration).getAgentsByAdministrativeArea(administrativeAreaId, pageNumber, pageSize, name, country, divisionIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get paginated active agents for a given US state
     * @param {string} stateAbbreviation 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof RunwayControllerApi
     */
    public getAgentsForState(stateAbbreviation: string, pageNumber?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return RunwayControllerApiFp(this.configuration).getAgentsForState(stateAbbreviation, pageNumber, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all official offices
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RunwayControllerApi
     */
    public getAllOfficialOffices(options?: AxiosRequestConfig) {
        return RunwayControllerApiFp(this.configuration).getAllOfficialOffices(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get official office for state
     * @param {string} stateAbbreviation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RunwayControllerApi
     */
    public getOfficialOfficeForStateOrProvince(stateAbbreviation: string, options?: AxiosRequestConfig) {
        return RunwayControllerApiFp(this.configuration).getOfficialOfficeForStateOrProvince(stateAbbreviation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get agent official commission plans and awards by country
     * @param {'UNITED_STATES' | 'CANADA'} country 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RunwayControllerApi
     */
    public getOfficialPlanByCountry(country: 'UNITED_STATES' | 'CANADA', options?: AxiosRequestConfig) {
        return RunwayControllerApiFp(this.configuration).getOfficialPlanByCountry(country, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get MLS and Boards information for a given US state
     * @param {string} stateAbbreviation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RunwayControllerApi
     */
    public getStateInformation(stateAbbreviation: string, options?: AxiosRequestConfig) {
        return RunwayControllerApiFp(this.configuration).getStateInformation(stateAbbreviation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search for agents
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {string} [name] 
     * @param {Array<string>} [country] 
     * @param {Array<string>} [divisionIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RunwayControllerApi
     */
    public searchAgents(pageNumber?: number, pageSize?: number, name?: string, country?: Array<string>, divisionIds?: Array<string>, options?: AxiosRequestConfig) {
        return RunwayControllerApiFp(this.configuration).searchAgents(pageNumber, pageSize, name, country, divisionIds, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SwaggerControllerApi - axios parameter creator
 * @export
 */
export const SwaggerControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets the API documentation based on the current user\'s access
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        swaggerApiDoc: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/real-swagger/api-docs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SwaggerControllerApi - functional programming interface
 * @export
 */
export const SwaggerControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SwaggerControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets the API documentation based on the current user\'s access
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async swaggerApiDoc(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.swaggerApiDoc(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SwaggerControllerApi - factory interface
 * @export
 */
export const SwaggerControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SwaggerControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets the API documentation based on the current user\'s access
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        swaggerApiDoc(options?: any): AxiosPromise<any> {
            return localVarFp.swaggerApiDoc(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SwaggerControllerApi - object-oriented interface
 * @export
 * @class SwaggerControllerApi
 * @extends {BaseAPI}
 */
export class SwaggerControllerApi extends BaseAPI {
    /**
     * 
     * @summary Gets the API documentation based on the current user\'s access
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SwaggerControllerApi
     */
    public swaggerApiDoc(options?: AxiosRequestConfig) {
        return SwaggerControllerApiFp(this.configuration).swaggerApiDoc(options).then((request) => request(this.axios, this.basePath));
    }
}


