import React, { useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import Link from 'next/link';

interface IOption {
  title: string;
  desc: string;
  link?: string;
  onClick?(): void;
  target?: React.HTMLAttributeAnchorTarget;
}

interface CollapsibleMenuItemProps {
  title: string;
  options?: IOption[];
}

const CollapsibleMenuItem: React.FC<CollapsibleMenuItemProps> = ({
  options,
  title,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className='mb-8'>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className='text-dark flex flex-row space-x-4 justify-between items-center w-full text-base font-normal font-inter'
      >
        <span>{title}</span> {isOpen ? <FaChevronUp /> : <FaChevronDown />}
      </button>
      {isOpen ? (
        <div className='my-6'>
          {options?.map((option) => {
            const LinkContainer = option.link
              ? /https?:\/\//.test(option.link)
                ? 'a'
                : Link
              : 'div';

            const ChildWrapper = LinkContainer === Link ? 'a' : 'div';

            return (
              <div key={title} onClick={option.onClick}>
                <LinkContainer
                  href={option.link}
                  target={option?.target || '_self'}
                >
                  <ChildWrapper>
                    <div className='p-6 w-9/12'>
                      <p className='font-semibold text-dark'>{option.title}</p>
                      <p className='font-light text-sm text-[#717171]'>
                        {option.desc}
                      </p>
                    </div>
                  </ChildWrapper>
                </LinkContainer>
              </div>
            );
          })}
        </div>
      ) : null}
      {isOpen && (
        <div className='bg-black p-6 -mx-6'>
          <p className='text-center text-new-primary font-inter font-normal'>
            Everything you need under one roof
          </p>
        </div>
      )}
    </div>
  );
};

export default CollapsibleMenuItem;
