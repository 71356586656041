import Image from 'next/image';
import React from 'react';
import { TypeFooter } from '../types/generated/page-contents';
import { getBackgroundImageUrl } from '../utils/DisplayHelpers';
import env from '../utils/env';
import { BOLT_REGISTER_URL } from '../constants/BoltConstants';
import {
  CASiteOnly,
  isCASiteOnly,
  USSiteOnly,
} from '../utils/SiteConfiguration';
import Container from './Container';
import LinkWithAnchor from './LinkWithAnchor';

interface FooterRedesignedProps {
  footerContent: TypeFooter;
}

const FooterRedesigned: React.FC<FooterRedesignedProps> = ({
  footerContent: {
    fields: { socialLinks },
  },
}) => {
  const socials: { name: string; url: string; iconUrl: string }[] = (
    socialLinks || []
  )?.map(({ fields }) => ({
    iconUrl: fields?.icon?.fields?.file?.url,
    name: fields?.name,
    url: fields?.url,
  }));

  const canadaonly = isCASiteOnly();
  return (
    <Container>
      <div className='divide-y-2 mt-14'>
        <div className='pt-[60px] border-t-[0.5px] border-new-primary'>
          <div className='grid grid-rows-1 lg:grid-cols-12 lg:gap-x-4 gap-y-4 lg:ml-9 ml-4'>
            <div className='lg:col-span-2 flex gap-x-12'>
              <div className='flex flex-col items-start md:text-[32px] md:leading-9 text-2xl font-primary-light font-light text-new-primary space-y-0.5 capitalize'>
                <LinkWithAnchor href='/'>Home</LinkWithAnchor>
                <CASiteOnly>
                  <LinkWithAnchor href='/listings' target='_blank'>
                    Properties
                  </LinkWithAnchor>
                </CASiteOnly>
                <CASiteOnly>
                  <LinkWithAnchor href='/search-agent' target='_blank'>
                    Agents
                  </LinkWithAnchor>
                </CASiteOnly>
                <CASiteOnly>
                  <LinkWithAnchor
                    href={env.NEXT_PUBLIC_ONEREAL_BLOG_SUBDOMAIN}
                    target='_blank'
                  >
                    Newsroom
                  </LinkWithAnchor>
                </CASiteOnly>
                <USSiteOnly>
                  <LinkWithAnchor
                    href={env.NEXT_PUBLIC_MORTGAGE_SUBDOMAIN}
                    target='_blank'
                  >
                    Mortgage
                  </LinkWithAnchor>
                </USSiteOnly>
                <USSiteOnly>
                  <LinkWithAnchor
                    href={env.NEXT_PUBLIC_TITLE_SUBDOMAIN}
                    target='_blank'
                  >
                    Title
                  </LinkWithAnchor>
                </USSiteOnly>
                <USSiteOnly>
                  <LinkWithAnchor
                    href={`${env.NEXT_PUBLIC_TITLE_SUBDOMAIN}/escrow`}
                    target='_blank'
                  >
                    Escrow
                  </LinkWithAnchor>
                </USSiteOnly>
                <LinkWithAnchor href='/pages/join-real'>
                  Join Real
                </LinkWithAnchor>
                {/* <LinkWithAnchor href='/faqs'>FAQs</LinkWithAnchor> */}
              </div>
              <div className='md:hidden block'>
                <div className='text-sm font-inter font-medium tracking-wide text-white mb-7'>
                  Brokerage
                </div>
                <div className='flex flex-col items-start text-[15px] font-inter font-extralight text-white space-y-1'>
                  <LinkWithAnchor href='/listings'>Buy/Rent</LinkWithAnchor>
                  <LinkWithAnchor href='/pages/sell'>Sell</LinkWithAnchor>
                  <LinkWithAnchor href='/search-agent'>
                    Find an Agent
                  </LinkWithAnchor>
                  <LinkWithAnchor
                    href={env.NEXT_PUBLIC_ONEREAL_EVENT_SUBDOMAIN}
                  >
                    Events
                  </LinkWithAnchor>
                  <LinkWithAnchor href={BOLT_REGISTER_URL} target='_blank'>
                    Become an Agent
                  </LinkWithAnchor>
                  {/* <LinkWithAnchor href='/faqs'>FAQs</LinkWithAnchor> */}
                </div>
              </div>
            </div>
            <div className='md:grid flex md:grid-cols-3 gap-y-7 gap-x-16 md:gap-x-24 md:my-0 my-4 md:col-span-7 lg:ml-14 md:mt-4 lg:mt-0'>
              <div className='hidden md:block sm:hidden'>
                <div className='text-sm font-inter font-medium tracking-wide text-white mb-7'>
                  Brokerage
                </div>
                <div className='flex flex-col items-start text-[15px] font-inter font-extralight text-white space-y-1'>
                  <LinkWithAnchor href='/listings'>Buy/Rent</LinkWithAnchor>
                  <LinkWithAnchor href='/pages/sell'>Sell</LinkWithAnchor>
                  <LinkWithAnchor href='/search-agent'>
                    Find an Agent
                  </LinkWithAnchor>
                  <LinkWithAnchor
                    href={env.NEXT_PUBLIC_ONEREAL_EVENT_SUBDOMAIN}
                  >
                    Events
                  </LinkWithAnchor>
                  <LinkWithAnchor
                    href={canadaonly ? '/pages/join-real' : BOLT_REGISTER_URL}
                    target='_blank'
                  >
                    Become an Agent
                  </LinkWithAnchor>
                  {/* <LinkWithAnchor href='/faqs'>FAQs</LinkWithAnchor> */}
                </div>
              </div>
              <div className='md:order-last'>
                <div className='text-sm font-inter font-medium tracking-wide text-white mb-7'>
                  About
                </div>
                <div className='flex flex-col items-start text-[15px] ffont-inter font-extralight text-white space-y-1'>
                  <LinkWithAnchor href='/pages/leadership'>
                    Leadership
                  </LinkWithAnchor>
                  <LinkWithAnchor
                    href={env.NEXT_PUBLIC_NEWSROOM_SUBDOMAIN}
                    target='_blank'
                  >
                    Newsroom
                  </LinkWithAnchor>
                  <LinkWithAnchor href='/pages/contact-us'>
                    Contact Us
                  </LinkWithAnchor>
                  <LinkWithAnchor
                    href={`${env.NEXT_PUBLIC_INVESTORS_DOMAIN}`}
                    target='_blank'
                  >
                    Investors
                  </LinkWithAnchor>
                  <LinkWithAnchor
                    href={`${env.NEXT_PUBLIC_ONEREAL_DOMAIN}/careers`}
                  >
                    Careers
                  </LinkWithAnchor>
                  <LinkWithAnchor
                    href={`${env.NEXT_PUBLIC_ONEREAL_DOMAIN}/pages/partners`}
                  >
                    Partners
                  </LinkWithAnchor>
                </div>
              </div>
              <div>
                <div className='text-sm font-inter font-medium tracking-wide text-white mb-7'>
                  Divisons
                </div>
                <div className='flex flex-col items-start text-[15px] font-inter font-extralight text-white space-y-1'>
                  <LinkWithAnchor href='/pages/military'>
                    Military
                  </LinkWithAnchor>
                </div>
              </div>
            </div>
            <div className='md:col-span-2 lg:ml-16 mt-4 lg:mt-0'>
              <div>
                <Image
                  src={require('../assets/img/real_white.svg')}
                  alt='logo'
                  width={120.0}
                  height={50.62}
                  className='bg-white'
                  layout='fixed'
                />
              </div>

              <div className='flex mt-6 md:mt-2 lg:ml-8 gap-x-6 lg:h-24 lg:flex-wrap'>
                <div className='flex lg:flex-col gap-x-3 items-center md:flex-wrap md:h-24'>
                  {socials.map((social) => (
                    <div
                      className='hover:border-2 hover:border-white w-8 h-8 flex items-center justify-center rounded-full transition-all duration-200 '
                      key={social?.name}
                    >
                      <LinkWithAnchor
                        href={social?.url}
                        target='_blank'
                        key={social?.name}
                      >
                        <div className='flex justify-center items-center'>
                          <Image
                            src={getBackgroundImageUrl(social.iconUrl)}
                            alt='instagram'
                            className='cursor-pointer'
                            width={16}
                            height={16}
                          />
                        </div>
                      </LinkWithAnchor>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default FooterRedesigned;
