import React, { useCallback } from 'react';
import AboutUsNav from '../GeminiDesktopNav/AboutUsNav';
import BrokerageNav from '../GeminiDesktopNav/BrokerageNav';
import MortgageNav from '../GeminiDesktopNav/MortgageNav';
import TitleEscrowNav from '../GeminiDesktopNav/TitleEscrowNav';
import AgentsNav from '../GeminiDesktopNav/AgentsNav';

export type GeminiNavDropdownOptionsType =
  | 'Brokerage'
  | 'Mortgage'
  | 'Title'
  | 'About'
  | 'Agents'
  | 'Investors';

interface GeminiNavDropdownOptionsProps {
  type: GeminiNavDropdownOptionsType;
}
const GeminiNavDropdownOptions: React.FC<GeminiNavDropdownOptionsProps> = ({
  type = 'Brokerage',
}) => {
  const getNavComponent = useCallback(() => {
    switch (type) {
      case 'Brokerage':
        return BrokerageNav;
      case 'Mortgage':
        return MortgageNav;
      case 'Title':
        return TitleEscrowNav;
      case 'About':
        return AboutUsNav;
      case 'Agents':
        return AgentsNav;
      default:
        return null;
    }
  }, [type]);

  const NavComponent = getNavComponent();

  return (
    <div className='w-full border-t absolute top-[65px] bg-off-white  overflow-auto'>
      <div className='hidden md:flex md:justify-center font-inter z-40'>
        <NavComponent />
      </div>
    </div>
  );
};

export default GeminiNavDropdownOptions;
