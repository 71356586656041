export const REAL_PRIVACY_POLICY_PDF_URL =
  'https://www.iubenda.com/privacy-policy/15223117';

export const REAL_APP_PRIVACY_POLICY_PDF_URL =
  'https://www.iubenda.com/privacy-policy/42754321';
export const FAIR_HOUSING_NOTICE_PDF_URL =
  'https://www.dos.ny.gov/licensing/docs/FairHousingNotice_new.pdf';
export const NEY_YORK_HUMAN_RIGHTS_PDF_URL =
  'https://dos.ny.gov/system/files/documents/2021/08/fairhousingnotice.pdf';
export const NEW_YORK_STATE_FAIR_HOUSING_NOTICE =
  'https://dos.ny.gov/system/files/documents/2021/08/fairhousingnotice.pdf';
export const NEW_YORK_STATE_STANDARD_OPERATING_PROCEDURE =
  'https://bolt-custom-assets.therealbrokerage.com/new-york-compliance/standardized-operating-procedure-notarized.pdf';
export const PURCHASERS_OF_REAL_ESTATE_PDF_URL =
  'https://drive.google.com/file/d/1kTCez8dHnUDIvl4ZPsXaeMrXhDR_1ENn/view';
export const OPERATING_PROCEDURE_NOTARIZED_PDF_URL =
  'https://bolt-custom-assets.therealbrokerage.com/new-york-compliance/standardized-operating-procedure-notarized.pdf';
export const NEW_MEXICO_REAL_ESTATE_LICENSES_PDF_URL =
  'https://bolt-custom-assets.therealbrokerage.com/new-mexico/agent-licenses.pdf';
export const ILLINOIS_REAL_ESTATE_LICENSES_PDF_URL =
  'https://drive.google.com/file/d/1L0Scn-x86VqVYza35KOHGb-c-biQIwMf/view';
