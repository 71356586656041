import React from 'react';
import {
  BOLT_CUSTOM_ASSET_FOR_TEXAS_CPN_PDF_URL,
  BOLT_CUSTOM_ASSET_FOR_TEXAS_IABS_PDF_URL,
} from '../../../constants/BoltConstants';

interface GeminiGeneralTexasDisclaimersProps {}

const GeminiGeneralTexasDisclaimers: React.FC<
  GeminiGeneralTexasDisclaimersProps
> = () => {
  return (
    <div className='flex flex-col gap-5 font-inter font-normal text-[14px] leading-[21px] text-off-white text-opacity-70 mt-5'>
      <div className='flex flex-col space-y-2.5'>
        <p>
          The Texas Real Estate Commission (TREC) requires that we provide the
          following information:
        </p>
        <a
          target='_blank'
          className='underline'
          rel='noreferrer'
          href={BOLT_CUSTOM_ASSET_FOR_TEXAS_IABS_PDF_URL}
        >
          Texas Real Estate Commission Information About Brokerage Service
        </a>
      </div>
      <a
        target='_blank'
        className='underline'
        rel='noreferrer'
        href={BOLT_CUSTOM_ASSET_FOR_TEXAS_CPN_PDF_URL}
      >
        TREC Consumer Protection Notice
      </a>
    </div>
  );
};

export default GeminiGeneralTexasDisclaimers;
