import React from 'react';
import classNames from 'classnames';

type ContainerPaddingVariant = 'sm' | 'md' | 'lg' | 'xl';

interface ContainerProps {
  paddingVariant?: ContainerPaddingVariant;
  fullWidth?: boolean;
  children?: any;
}

const Container: React.FC<ContainerProps> = ({
  paddingVariant = 'sm',
  fullWidth = false,
  children,
}) => {
  const paddingVariantMap: Record<ContainerPaddingVariant, string> = {
    sm: 'md:px-4',
    md: 'md:px-8',
    lg: 'md:px-12',
    xl: 'md:px-16',
  };

  return (
    <div
      className={classNames(
        'w-full mx-auto px-4',
        paddingVariantMap[paddingVariant],
        { 'max-w-screen-xl': !fullWidth }
      )}
    >
      {children}
    </div>
  );
};

export default Container;
