import { MutableRefObject } from 'react';
import {
  AddressResponseStateOrProvinceEnum,
  OfficeLinkResponse,
  OfficeLinkResponseStateOrProvinceEnum,
} from '../openapi/bff';
import { EnumMap, StateAbbreviation } from '../types';
import { ColorThemeType } from '../types/real-api-types';
import env from './env';
import { safeEnumMapGet } from './EnumHelper';

export const getValidUrl = (url: string): string => {
  if (url.startsWith('http')) {
    return url;
  }

  if (url.startsWith('//')) {
    return `https:${url}`;
  }

  return `https://${url}`;
};

export const getThemeTextColorClassName = (
  colorTheme: ColorThemeType
): string => `text-theme-${colorTheme}`;

export const getThemeBgColorClassName = (colorTheme: ColorThemeType): string =>
  `bg-theme-${colorTheme}`;

export const getThemeBorderClassName = (colorTheme: ColorThemeType): string =>
  `border-theme-${colorTheme}`;

export const stateAbbreviationMap: EnumMap<
  AddressResponseStateOrProvinceEnum,
  StateAbbreviation
> = {
  [AddressResponseStateOrProvinceEnum.Alberta]: StateAbbreviation.AB,
  [AddressResponseStateOrProvinceEnum.Alabama]: StateAbbreviation.AL,
  [AddressResponseStateOrProvinceEnum.Alaska]: StateAbbreviation.AK,
  [AddressResponseStateOrProvinceEnum.Arizona]: StateAbbreviation.AZ,
  [AddressResponseStateOrProvinceEnum.Arkansas]: StateAbbreviation.AR,
  [AddressResponseStateOrProvinceEnum.BritishColumbia]: StateAbbreviation.BC,
  [AddressResponseStateOrProvinceEnum.California]: StateAbbreviation.CA,
  [AddressResponseStateOrProvinceEnum.Colorado]: StateAbbreviation.CO,
  [AddressResponseStateOrProvinceEnum.Connecticut]: StateAbbreviation.CT,
  [AddressResponseStateOrProvinceEnum.Delaware]: StateAbbreviation.DE,
  [AddressResponseStateOrProvinceEnum.DistrictOfColumbia]: StateAbbreviation.DC,
  [AddressResponseStateOrProvinceEnum.Florida]: StateAbbreviation.FL,
  [AddressResponseStateOrProvinceEnum.Georgia]: StateAbbreviation.GA,
  [AddressResponseStateOrProvinceEnum.Hawaii]: StateAbbreviation.HI,
  [AddressResponseStateOrProvinceEnum.Idaho]: StateAbbreviation.ID,
  [AddressResponseStateOrProvinceEnum.Illinois]: StateAbbreviation.IL,
  [AddressResponseStateOrProvinceEnum.Indiana]: StateAbbreviation.IN,
  [AddressResponseStateOrProvinceEnum.Iowa]: StateAbbreviation.IA,
  [AddressResponseStateOrProvinceEnum.Kansas]: StateAbbreviation.KS,
  [AddressResponseStateOrProvinceEnum.Kentucky]: StateAbbreviation.KY,
  [AddressResponseStateOrProvinceEnum.Louisiana]: StateAbbreviation.LA,
  [AddressResponseStateOrProvinceEnum.Manitoba]: StateAbbreviation.MB,
  [AddressResponseStateOrProvinceEnum.Maine]: StateAbbreviation.ME,
  [AddressResponseStateOrProvinceEnum.Maryland]: StateAbbreviation.MD,
  [AddressResponseStateOrProvinceEnum.Massachusetts]: StateAbbreviation.MA,
  [AddressResponseStateOrProvinceEnum.Michigan]: StateAbbreviation.MI,
  [AddressResponseStateOrProvinceEnum.Minnesota]: StateAbbreviation.MN,
  [AddressResponseStateOrProvinceEnum.Mississippi]: StateAbbreviation.MS,
  [AddressResponseStateOrProvinceEnum.Missouri]: StateAbbreviation.MO,
  [AddressResponseStateOrProvinceEnum.Montana]: StateAbbreviation.MT,
  [AddressResponseStateOrProvinceEnum.Nebraska]: StateAbbreviation.NE,
  [AddressResponseStateOrProvinceEnum.NewfoundlandAndLabrador]:
    StateAbbreviation.NL,
  [AddressResponseStateOrProvinceEnum.NewBrunswick]: StateAbbreviation.NB,
  [AddressResponseStateOrProvinceEnum.NovaScotia]: StateAbbreviation.NS,
  [AddressResponseStateOrProvinceEnum.Nevada]: StateAbbreviation.NV,
  [AddressResponseStateOrProvinceEnum.NewHampshire]: StateAbbreviation.NH,
  [AddressResponseStateOrProvinceEnum.NewMexico]: StateAbbreviation.NM,
  [AddressResponseStateOrProvinceEnum.NorthCarolina]: StateAbbreviation.NC,
  [AddressResponseStateOrProvinceEnum.NorthDakota]: StateAbbreviation.ND,
  [AddressResponseStateOrProvinceEnum.NewJersey]: StateAbbreviation.NJ,
  [AddressResponseStateOrProvinceEnum.NewYork]: StateAbbreviation.NY,
  [AddressResponseStateOrProvinceEnum.Ohio]: StateAbbreviation.OH,
  [AddressResponseStateOrProvinceEnum.Ontario]: StateAbbreviation.ON,
  [AddressResponseStateOrProvinceEnum.Oklahoma]: StateAbbreviation.OK,
  [AddressResponseStateOrProvinceEnum.Oregon]: StateAbbreviation.OR,
  [AddressResponseStateOrProvinceEnum.Pennsylvania]: StateAbbreviation.PA,
  [AddressResponseStateOrProvinceEnum.PrinceEdwardIsland]: StateAbbreviation.PE,
  [AddressResponseStateOrProvinceEnum.PuertoRico]: StateAbbreviation.PR,
  [AddressResponseStateOrProvinceEnum.RhodeIsland]: StateAbbreviation.RI,
  [AddressResponseStateOrProvinceEnum.SouthCarolina]: StateAbbreviation.SC,
  [AddressResponseStateOrProvinceEnum.SouthDakota]: StateAbbreviation.SD,
  [AddressResponseStateOrProvinceEnum.Tennessee]: StateAbbreviation.TN,
  [AddressResponseStateOrProvinceEnum.Texas]: StateAbbreviation.TX,
  [AddressResponseStateOrProvinceEnum.Utah]: StateAbbreviation.UT,
  [AddressResponseStateOrProvinceEnum.Vermont]: StateAbbreviation.VT,
  [AddressResponseStateOrProvinceEnum.Virginia]: StateAbbreviation.VA,
  [AddressResponseStateOrProvinceEnum.Washington]: StateAbbreviation.WA,
  [AddressResponseStateOrProvinceEnum.WestVirginia]: StateAbbreviation.WV,
  [AddressResponseStateOrProvinceEnum.Wisconsin]: StateAbbreviation.WI,
  [AddressResponseStateOrProvinceEnum.Wyoming]: StateAbbreviation.WY,
  [AddressResponseStateOrProvinceEnum.Quebec]: StateAbbreviation.QC,
  [AddressResponseStateOrProvinceEnum.Saskatchewan]: StateAbbreviation.SK,
};

export const getYentaUrl = (path: string): string =>
  `${env.NEXT_PUBLIC_YENTA_IMAGE_BASE_URL}/${path}`;

export const stateAbbreviationToEnumMap: EnumMap<
  StateAbbreviation,
  AddressResponseStateOrProvinceEnum
> = {
  [StateAbbreviation.AB]: AddressResponseStateOrProvinceEnum.Alberta,
  [StateAbbreviation.AL]: AddressResponseStateOrProvinceEnum.Alabama,
  [StateAbbreviation.AK]: AddressResponseStateOrProvinceEnum.Alaska,
  [StateAbbreviation.AZ]: AddressResponseStateOrProvinceEnum.Arizona,
  [StateAbbreviation.AR]: AddressResponseStateOrProvinceEnum.Arkansas,
  [StateAbbreviation.BC]: AddressResponseStateOrProvinceEnum.BritishColumbia,
  [StateAbbreviation.CA]: AddressResponseStateOrProvinceEnum.California,
  [StateAbbreviation.CO]: AddressResponseStateOrProvinceEnum.Colorado,
  [StateAbbreviation.CT]: AddressResponseStateOrProvinceEnum.Connecticut,
  [StateAbbreviation.DE]: AddressResponseStateOrProvinceEnum.Delaware,
  [StateAbbreviation.DC]: AddressResponseStateOrProvinceEnum.DistrictOfColumbia,
  [StateAbbreviation.FL]: AddressResponseStateOrProvinceEnum.Florida,
  [StateAbbreviation.GA]: AddressResponseStateOrProvinceEnum.Georgia,
  [StateAbbreviation.HI]: AddressResponseStateOrProvinceEnum.Hawaii,
  [StateAbbreviation.ID]: AddressResponseStateOrProvinceEnum.Idaho,
  [StateAbbreviation.IL]: AddressResponseStateOrProvinceEnum.Illinois,
  [StateAbbreviation.IN]: AddressResponseStateOrProvinceEnum.Indiana,
  [StateAbbreviation.IA]: AddressResponseStateOrProvinceEnum.Iowa,
  [StateAbbreviation.KS]: AddressResponseStateOrProvinceEnum.Kansas,
  [StateAbbreviation.KY]: AddressResponseStateOrProvinceEnum.Kentucky,
  [StateAbbreviation.LA]: AddressResponseStateOrProvinceEnum.Louisiana,
  [StateAbbreviation.MB]: AddressResponseStateOrProvinceEnum.Manitoba,
  [StateAbbreviation.ME]: AddressResponseStateOrProvinceEnum.Maine,
  [StateAbbreviation.MD]: AddressResponseStateOrProvinceEnum.Maryland,
  [StateAbbreviation.MA]: AddressResponseStateOrProvinceEnum.Massachusetts,
  [StateAbbreviation.MI]: AddressResponseStateOrProvinceEnum.Michigan,
  [StateAbbreviation.MN]: AddressResponseStateOrProvinceEnum.Minnesota,
  [StateAbbreviation.MS]: AddressResponseStateOrProvinceEnum.Mississippi,
  [StateAbbreviation.MO]: AddressResponseStateOrProvinceEnum.Missouri,
  [StateAbbreviation.MT]: AddressResponseStateOrProvinceEnum.Montana,
  [StateAbbreviation.NE]: AddressResponseStateOrProvinceEnum.Nebraska,
  [StateAbbreviation.NL]:
    AddressResponseStateOrProvinceEnum.NewfoundlandAndLabrador,
  [StateAbbreviation.NB]: AddressResponseStateOrProvinceEnum.NewBrunswick,
  [StateAbbreviation.NS]: AddressResponseStateOrProvinceEnum.NovaScotia,
  [StateAbbreviation.NV]: AddressResponseStateOrProvinceEnum.Nevada,
  [StateAbbreviation.NH]: AddressResponseStateOrProvinceEnum.NewHampshire,
  [StateAbbreviation.NM]: AddressResponseStateOrProvinceEnum.NewMexico,
  [StateAbbreviation.NC]: AddressResponseStateOrProvinceEnum.NorthCarolina,
  [StateAbbreviation.ND]: AddressResponseStateOrProvinceEnum.NorthDakota,
  [StateAbbreviation.NJ]: AddressResponseStateOrProvinceEnum.NewJersey,
  [StateAbbreviation.NY]: AddressResponseStateOrProvinceEnum.NewYork,
  [StateAbbreviation.OH]: AddressResponseStateOrProvinceEnum.Ohio,
  [StateAbbreviation.ON]: AddressResponseStateOrProvinceEnum.Ontario,
  [StateAbbreviation.OK]: AddressResponseStateOrProvinceEnum.Oklahoma,
  [StateAbbreviation.OR]: AddressResponseStateOrProvinceEnum.Oregon,
  [StateAbbreviation.PA]: AddressResponseStateOrProvinceEnum.Pennsylvania,
  [StateAbbreviation.PE]: AddressResponseStateOrProvinceEnum.PrinceEdwardIsland,
  [StateAbbreviation.PR]: AddressResponseStateOrProvinceEnum.PuertoRico,
  [StateAbbreviation.RI]: AddressResponseStateOrProvinceEnum.RhodeIsland,
  [StateAbbreviation.SC]: AddressResponseStateOrProvinceEnum.SouthCarolina,
  [StateAbbreviation.SD]: AddressResponseStateOrProvinceEnum.SouthDakota,
  [StateAbbreviation.TN]: AddressResponseStateOrProvinceEnum.Tennessee,
  [StateAbbreviation.TX]: AddressResponseStateOrProvinceEnum.Texas,
  [StateAbbreviation.UT]: AddressResponseStateOrProvinceEnum.Utah,
  [StateAbbreviation.VT]: AddressResponseStateOrProvinceEnum.Vermont,
  [StateAbbreviation.VA]: AddressResponseStateOrProvinceEnum.Virginia,
  [StateAbbreviation.WA]: AddressResponseStateOrProvinceEnum.Washington,
  [StateAbbreviation.WV]: AddressResponseStateOrProvinceEnum.WestVirginia,
  [StateAbbreviation.WI]: AddressResponseStateOrProvinceEnum.Wisconsin,
  [StateAbbreviation.WY]: AddressResponseStateOrProvinceEnum.Wyoming,
  [StateAbbreviation.QC]: AddressResponseStateOrProvinceEnum.Quebec,
  [StateAbbreviation.SK]: AddressResponseStateOrProvinceEnum.Saskatchewan,
};

export const getBackgroundImageUrl = (
  url: string | undefined
): string | undefined => {
  if (!url) {
    return undefined;
  }

  if (!url.startsWith('https:')) {
    return `https:${url}`;
  }

  return url;
};

export const scrollToTargetAdjusted = (
  ref: MutableRefObject<any>,
  headerOffset: number
): void => {
  const element = ref.current as HTMLElement;
  const elementPosition = element.getBoundingClientRect().top;
  const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

  window.scrollTo({
    top: offsetPosition,
    behavior: 'smooth',
  });
};

export const displayStateOrProvinceOfficeAddress = (
  office: OfficeLinkResponse
): string => {
  const officeNameAbbreviation = safeEnumMapGet(
    stateAbbreviationMap,
    office?.stateOrProvince,
    stateAbbreviationMap['NEW_YORK']
  );

  const officeAddress = `${officeNameAbbreviation} - ${office?.address?.oneLine} - ${office?.designatedBroker?.fullName}`;

  const isIllinoisOffice =
    office?.stateOrProvince === OfficeLinkResponseStateOrProvinceEnum.Illinois;

  if (isIllinoisOffice) {
    return `${officeAddress} - Firm #471019889 - License #471019889 - ilbroker@therealbrokerage.com - 217.960.8605`;
  }

  return officeAddress;
};
