import { OfficeLinkResponse, OfficesResponse } from './openapi/bff';
import {
  TypeFooter,
  TypeMarketsCountrySection,
} from './types/generated/page-contents';
import { BrokerResponse } from './types/real-api-types';

export interface FormFieldTooltipIndexProps {
  tooltipIndex?: number;
}
export type EnumMap<E extends string, T> = {
  [key in E]: T;
};

export interface Mapping<T> {
  [key: string]: T;
}

export enum CountryEnum {
  UnitedStates = 'UNITED_STATES',
  Canada = 'CANADA',
}

export enum StateOrProvinceEnum {
  Alabama = 'ALABAMA',
  Alaska = 'ALASKA',
  Alberta = 'ALBERTA',
  Arizona = 'ARIZONA',
  Arkansas = 'ARKANSAS',
  BritishColumbia = 'BRITISH_COLUMBIA',
  California = 'CALIFORNIA',
  // Canada = 'CANADA',
  Colorado = 'COLORADO',
  Connecticut = 'CONNECTICUT',
  Delaware = 'DELAWARE',
  DistrictOfColumbia = 'DISTRICT_OF_COLUMBIA',
  Florida = 'FLORIDA',
  Georgia = 'GEORGIA',
  Hawaii = 'HAWAII',
  Idaho = 'IDAHO',
  Illinois = 'ILLINOIS',
  Indiana = 'INDIANA',
  Iowa = 'IOWA',
  Kansas = 'KANSAS',
  Kentucky = 'KENTUCKY',
  Louisiana = 'LOUISIANA',
  Maine = 'MAINE',
  // Manitoba = 'MANITOBA', //of Canada
  Maryland = 'MARYLAND',
  Massachusetts = 'MASSACHUSETTS',
  Michigan = 'MICHIGAN',
  Minnesota = 'MINNESOTA',
  Mississippi = 'MISSISSIPPI',
  Missouri = 'MISSOURI',
  Montana = 'MONTANA',
  Nebraska = 'NEBRASKA',
  Nevada = 'NEVADA',
  // NewfoundlandAndLabrador = 'NEWFOUNDLAND_AND_LABRADOR', //of Canada
  NewBrunswick = 'NEW_BRUNSWICK',
  NewHampshire = 'NEW_HAMPSHIRE',
  NewJersey = 'NEW_JERSEY',
  NewMexico = 'NEW_MEXICO',
  NewYork = 'NEW_YORK',
  NorthCarolina = 'NORTH_CAROLINA',
  NorthDakota = 'NORTH_DAKOTA',
  NovaScotia = 'NOVA_SCOTIA',
  Ohio = 'OHIO',
  Oklahoma = 'OKLAHOMA',
  Ontario = 'ONTARIO',
  Oregon = 'OREGON',
  Pennsylvania = 'PENNSYLVANIA',
  PrinceEdwardIsland = 'PRINCE_EDWARD_ISLAND',
  PuertoRico = 'PUERTO_RICO',
  Quebec = 'QUEBEC',
  RhodeIsland = 'RHODE_ISLAND',
  Saskatchewan = 'SASKATCHEWAN',
  SouthCarolina = 'SOUTH_CAROLINA',
  SouthDakota = 'SOUTH_DAKOTA',
  Tennessee = 'TENNESSEE',
  Texas = 'TEXAS',
  Utah = 'UTAH',
  Vermont = 'VERMONT',
  Virginia = 'VIRGINIA',
  Washington = 'WASHINGTON',
  WestVirginia = 'WEST_VIRGINIA',
  Wisconsin = 'WISCONSIN',
  Wyoming = 'WYOMING',
}

export enum StateAbbreviation {
  AB = 'AB',
  AL = 'AL',
  AK = 'AK',
  AZ = 'AZ',
  AR = 'AR',
  BC = 'BC',
  CA = 'CA',
  CO = 'CO',
  CT = 'CT',
  DE = 'DE',
  DC = 'DC',
  FL = 'FL',
  GA = 'GA',
  HI = 'HI',
  ID = 'ID',
  IL = 'IL',
  IN = 'IN',
  IA = 'IA',
  KS = 'KS',
  KY = 'KY',
  LA = 'LA',
  MB = 'MB',
  ME = 'ME',
  MD = 'MD',
  MA = 'MA',
  MI = 'MI',
  MO = 'MO',
  MN = 'MN',
  MS = 'MS',
  MT = 'MT',
  NB = 'NB',
  NE = 'NE',
  NL = 'NL',
  NV = 'NV',
  NS = 'NS',
  NH = 'NH',
  NM = 'NM',
  NC = 'NC',
  NJ = 'NJ',
  NY = 'NY',
  ND = 'ND',
  OH = 'OH',
  ON = 'ON',
  OK = 'OK',
  OR = 'OR',
  PA = 'PA',
  PE = 'PE',
  QC = 'QC',
  PR = 'PR',
  RI = 'RI',
  SC = 'SC',
  SD = 'SD',
  SK = 'SK',
  TN = 'TN',
  TX = 'TX',
  UT = 'UT',
  VT = 'VT',
  VA = 'VA',
  WA = 'WA',
  WV = 'WV',
  WI = 'WI',
  WY = 'WY',
}

export type StateType = StateOrProvinceEnum;

export interface States {
  symbol: string;
  name: string;
  brokerage_license: string;
  brokerage_name: string;
  broker_license: string;
  office_adress1: string;
  office_adress2: string;
  broker_name: string;
}

export type ThenArg<T> = T extends PromiseLike<infer U> ? U : T;

export interface Board {
  name: string;
  code: string;
}

export interface MortgageCalculatorPageQuery {
  mlsnum?: string;
  mls_id?: string;
}

export type AddressComponentType = {
  short_name: string;
  long_name: string;
  types: string[];
};

export type ComponentPosition = 'left' | 'right';

export type TabsVariant =
  | 'default'
  | 'spread'
  | 'material'
  | 'material_lg'
  | 'search';

export interface CommonPageDataProps {
  countriesWithStates: TypeMarketsCountrySection[];
  footerContent: TypeFooter;
  allOffices: OfficesResponse;
}

export enum SearchAgentByEnum {
  AGENT = 'AGENT',
  LOCATION = 'LOCATION',
}

export interface BrokerageDetails {
  name: string;
  phone: string;
  email: string;
  license: string;
}

export interface BrokerDetails {
  name: string;
  phone: string;
  email: string;
  license: string;
}

export interface WandererBrokerResponse {
  office: OfficeLinkResponse;
  broker: BrokerResponse;
}

export enum AnalyticsEventEnum {
  SEARCH_FOR_DIVISION = 'search_for_division',
  VIEW_DIVISION_ENABLED_PROFILE = 'view_division_enabled_profile',
  PROFILE_VIEWED_AFTER_DIVISION_SELECTION = 'profile_viewed_after_division_selection',
  CLICKED_ON_CONTACT_LINKS_ON_DIVISION_ENABLED_PROFILE = 'click_contact_links_on_division_profile',
}
