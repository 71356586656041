import { useLoadScript } from '@react-google-maps/api';
import { Libraries } from '@react-google-maps/api/src/utils/make-load-script-url';
import env from '../utils/env';

const GOOGLE_MAPS_LIBRARIES: Libraries = ['places', 'geometry'];

export const useGoogleMapsAPILoader = (): boolean => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
    libraries: GOOGLE_MAPS_LIBRARIES,
  });

  return isLoaded;
};
