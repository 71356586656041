import Env, { IEnv } from '../utils/env';

export type ENVVariableName = keyof IEnv;

/**
 * Determines whether a feature flag specified by `envName` is enabled.
 * @param envName - The name of the environment variable to check.
 * @returns `true` if the feature flag is enabled, `false` otherwise.
 */
const useENVFeatureFlag = (envName: ENVVariableName): boolean => {
  const featureFlagValue = Env[envName] ?? 'false';

  return featureFlagValue.toString().toLowerCase() === 'true';
};

export default useENVFeatureFlag;
