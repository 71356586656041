import Link from 'next/link';
import React from 'react';
import { DateTime } from 'luxon';
import { OfficesResponse } from '../openapi/bff';
import { LegaleseElement } from '../openapi/wanderer';
import { TypeMarketsCountrySection } from '../types/generated/page-contents';
import {
  BrokerLicense,
  RealApiAgentWebsiteResponse,
} from '../types/real-api-types';
import { isCASiteOnly } from '../utils/SiteConfiguration';
import Container from './Container';
import CAFooter from './CAFooter';
import USFooter from './USFooter';

interface FooterDisclaimerRedesignedProps {
  disclaimers?: LegaleseElement[];
  brokerLicenses: BrokerLicense[];
  countriesWithStates: TypeMarketsCountrySection[];
  agent?: RealApiAgentWebsiteResponse;
  allOffices: OfficesResponse;
}

const FooterDisclaimerRedesigned: React.FC<FooterDisclaimerRedesignedProps> = ({
  countriesWithStates,
  brokerLicenses,
  disclaimers,
  agent,
  allOffices,
}) => {
  const isCASite = isCASiteOnly();

  return (
    <Container>
      <div className='xl:px-10 px-5 lg:mt-28 mt-4'>
        <div className='lg:flex lg:flex-row flex flex-col-reverse space-y-2 lg:justify-between text-light-gray-7 font-inter font-light text-[13px] text-opacity-60 '>
          <div className='flex items-center mt-2'>Licenses</div>
          <div className='flex'>
            <Link href='/pages/legal#privacy'>Privacy Policy</Link>
            <div className='w-[1px] h-4 bg-[#90919e] ml-[6px] mr-[6px] mt-0.5' />
            <Link href='/pages/legal#terms-of-use'>Terms of Use</Link>
          </div>
          <div>
            <p>{`© ${DateTime.local().year} The Real Brokerage ${
              DateTime.local().year
            }. All Rights Reserved.${
              !isCASite ? ' Powered by Home Junction.' : ''
            }`}</p>
          </div>
        </div>
        {isCASite ? (
          <CAFooter />
        ) : (
          <USFooter
            countriesWithStates={countriesWithStates}
            brokerLicenses={brokerLicenses}
            disclaimers={disclaimers}
            allOffices={allOffices}
            agent={agent}
          />
        )}
      </div>
    </Container>
  );
};

export default FooterDisclaimerRedesigned;
