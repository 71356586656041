import React from 'react';
import { ColorThemeType } from '../types/real-api-types';

export const ThemeContext = React.createContext<ColorThemeType>('blue');

interface ThemeProviderProps {
  theme: ColorThemeType;
  children: React.ReactNode;
}

const ThemeProvider: React.FC<ThemeProviderProps> = ({ children, theme }) => {
  return (
    <ThemeContext.Provider value={theme}>{children}</ThemeContext.Provider>
  );
};

export default ThemeProvider;
