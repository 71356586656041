import classNames from 'classnames';
import { FC } from 'react';
import GeminiNavLinkButton, {
  IGeminiNavLinkButtonProps,
} from '../Header/GeminiNavLinkButton';

interface GeminiSectionProps {
  title?: string;
  subTitle?: string;
  description?: string;
  links?: IGeminiNavLinkButtonProps[];
  className?: string;
}

const GeminiSection: FC<GeminiSectionProps> = ({
  title,
  description,
  links,
  className,
  subTitle,
}) => {
  return (
    <div className={classNames('w-full xl:w-1/3 xl:py-[50px]', className)}>
      {!!title && (
        <h2 className='text-xs xl:text-base font-bold tracking-2.6 xl:tracking-[3.52px] text-cobalt uppercase px-5 xl:px-0 py-3 xl:py-0 bg-light-secondary-gray-2 xl:bg-off-white xl:border-transparent border-t border-t-new-gray border-b border-b-new-gray'>
          {title}
        </h2>
      )}

      {!!subTitle && (
        <h4 className='font-bold tracking-2.6 text-cobalt text-xs xl:mb-[30px] uppercase xl:border-transparent  border-b border-b-new-gray py-3 xl:py-0 px-5 xl:px-0'>
          {subTitle}
        </h4>
      )}

      {!!description && (
        <p className='text-[15px] xl:mt-5 text-cobalt opacity-70 xl:p-0 p-5 border-b border-b-new-gray xl:border-transparent'>
          {description}
        </p>
      )}

      {links?.length && (
        <div className='flex flex-col xl:gap-[30px]'>
          {links.map((link, index) => (
            <GeminiNavLinkButton key={[link, index].join('-')} {...link} />
          ))}
        </div>
      )}
    </div>
  );
};

export default GeminiSection;
