import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useGoogleMapsAPILoader } from '../hooks/useGoogleMapsAPILoader';
import { usePageLoading } from '../hooks/usePageLoading';
import env from '../utils/env';
import { isCASiteOnly, isUSSiteOnly } from '../utils/SiteConfiguration';
import PageTransitionLoader from './PageTransitionLoader';
import Button from './Button';
import CollapsibleMenuItem from './CollapsibleMenuItem';
import NavButton from './NavButton';
import NavDropdownOptions, {
  NavDropdownOptionsType,
  aboutOptions,
  brokerageOptions,
  mortgageOptions,
  titleOptions,
} from './NavDropdownOptions';
import NavMenuItem from './NavMenuItem';

export interface NavProps {
  loading?: boolean;
}

const Nav: React.FC<NavProps> = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const loading = usePageLoading();
  const router = useRouter();
  const [dropdownActive, setDropdownActive] =
    useState<NavDropdownOptionsType>();
  const isCASite = isCASiteOnly();
  const isUSSite = isUSSiteOnly();
  const isNewSite = isCASite || isUSSite;

  // hide nav on scroll on mobile when menu is open
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';

      return () => {
        document.body.style.overflow = 'auto';
      };
    }
  }, [isOpen]);

  // set isOpen to false when route changes
  useEffect(() => {
    setIsOpen(false);
  }, [router]);

  useGoogleMapsAPILoader();

  return (
    <nav
      className='flex flex-col items-center justify-between flex-wrap w-full bg-white z-50 sticky top-0'
      onMouseLeave={() => setDropdownActive(undefined)}
    >
      <div className='absolute w-full left-0 top-0 z-[9999]'>
        <PageTransitionLoader loading={loading} />
      </div>
      {isNewSite && (
        <div className='bg-black py-2.5 w-full text-sm flex items-center justify-end pr-[10vw] font-light z-50'>
          <Link passHref href={`${env.NEXT_PUBLIC_ONEREAL_DOMAIN}`}>
            <a
              className={classNames(
                'cursor-pointer text-sm leading-4 font-light font-telegraf border-r border-white pr-2 py-0.5',
                isUSSite ? 'text-new-primary' : 'text-white'
              )}
            >
              US
            </a>
          </Link>
          <Link passHref href={`${env.NEXT_PUBLIC_ONEREAL_CANADA_DOMAIN}`}>
            <a
              className={classNames(
                'cursor-pointer text-sm leading-4 font-light font-telegraf pl-2 py-0.5',
                isCASite ? 'text-new-primary' : 'text-white'
              )}
            >
              Canada
            </a>
          </Link>
        </div>
      )}
      <div className='max-w-[1440px] mx-auto w-full relative z-40 bg-white'>
        <div className='flex items-center justify-between px-6 xl:px-36 relative transition-all ease-in-out duration-300 py-6'>
          <div className='hidden xl:block'>
            <Link href='/'>
              <Image
                src='/img/real-logo-black.svg'
                alt='logo'
                width={101.9}
                height={47.62}
                className='cursor-pointer'
                objectFit='cover'
                layout='fixed'
              />
            </Link>
          </div>
          <div className='xl:hidden block'>
            <Link href='/'>
              <Image
                src='/img/real-logo-black.svg'
                alt='logo'
                width={75.95}
                height={35.37}
                className='cursor-pointer'
                objectFit='cover'
                layout='fixed'
              />
            </Link>
          </div>

          <div className='xl:flex xl:flex-row xl:items-center gap-6 hidden'>
            <NavButton
              title='Home'
              showArrow={false}
              onClick={() => router.push('/')}
            />
            <NavMenuItem
              title='Brokerage'
              onShow={() => setDropdownActive('Brokerage')}
              onHide={() => setDropdownActive(undefined)}
              isOpen={dropdownActive === 'Brokerage'}
            />
            {!isCASite && (
              <NavMenuItem
                title='Mortgage'
                onShow={() => setDropdownActive('Mortgage')}
                onHide={() => setDropdownActive(undefined)}
                isOpen={dropdownActive === 'Mortgage'}
              />
            )}
            {!isCASite && (
              <NavMenuItem
                title='Title/Escrow'
                onShow={() => setDropdownActive('Title')}
                onHide={() => setDropdownActive(undefined)}
                isOpen={dropdownActive === 'Title'}
              />
            )}
            <NavMenuItem
              title='About Real'
              onShow={() => setDropdownActive('About')}
              onHide={() => setDropdownActive(undefined)}
              isOpen={dropdownActive === 'About'}
            />
            <a
              href={`${env.NEXT_PUBLIC_INVESTORS_DOMAIN}`}
              target='_blank'
              rel='noopener noreferrer'
            >
              <div
                className={classNames(
                  'flex items-center space-x-1 cursor-pointer text-gray-600'
                )}
              >
                <p className='whitespace-nowrap'>Investor Relations</p>
              </div>
            </a>
          </div>
          <Button
            title='Agent Login'
            variant='outline-black'
            size='md'
            isRounded={false}
            href={`${env.NEXT_PUBLIC_BOLT_DOMAIN}/login`}
            textStyles='!text-xl'
            buttonStyles='!px-2 !py-1 hidden xl:block'
          />
          <div onClick={() => setIsOpen(!isOpen)} className='xl:hidden'>
            {isOpen ? (
              <Image
                src='/img/iconclose.svg'
                alt='leader'
                width={16}
                height={16}
                objectFit='cover'
              />
            ) : (
              <Image
                src='/img/iconhamburger.svg'
                alt='leader'
                width={16}
                height={16}
                objectFit='cover'
              />
            )}
          </div>
        </div>
      </div>
      {!!dropdownActive && <NavDropdownOptions type={dropdownActive} />}

      {/* ---MOBILE DEVICES--- */}
      <div
        className={classNames(
          'bg-white absolute h-screen w-screen overflow-y-scroll xl:hidden px-6 py-3 z-10 pt-[180px]',
          isOpen ? 'block ' : 'hidden'
        )}
      >
        <div className='mb-8'>
          <Link href='/'>
            <a className='text-base text-dark font-normal'>Home</a>
          </Link>
        </div>
        <CollapsibleMenuItem title='Brokerage' options={brokerageOptions} />
        {!isCASite && (
          <CollapsibleMenuItem title='Mortgage' options={mortgageOptions} />
        )}
        {!isCASite && (
          <CollapsibleMenuItem title='Title/Escrow' options={titleOptions} />
        )}
        <CollapsibleMenuItem title='About Real' options={aboutOptions} />
        <div className='mb-16'>
          <a
            href={`${env.NEXT_PUBLIC_INVESTORS_DOMAIN}`}
            target='_blank'
            className='text-base text-dark font-normal'
            rel='noopener noreferrer'
          >
            Investor Relations
          </a>
        </div>
        <Button
          title='Agent Login'
          variant='outline-black'
          size='md'
          isRounded={false}
          href={`${env.NEXT_PUBLIC_BOLT_DOMAIN}/login`}
          textStyles='!text-xl text-center w-full'
          buttonStyles='w-full !px-2 !py-0 text-center'
        />
      </div>
    </nav>
  );
};

export default Nav;
