import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faTiktok,
  faXTwitter,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';

export enum SocialMedia {
  FACEBOOK = 'facebook',
  INSTAGRAM = 'instagram',
  TWITTER = 'twitter',
  YOUTUBE = 'youtube',
  LINKEDIN = 'linkedin',
  TIKTOK = 'tiktok',
}

export interface SocialMediaLink {
  name: SocialMedia;
  icon: IconProp;
  url: string;
}

export const realSocialLinks: SocialMediaLink[] = [
  {
    name: SocialMedia.FACEBOOK,
    icon: faFacebookF,
    url: 'https://www.facebook.com/realbrokerage',
  },
  {
    name: SocialMedia.INSTAGRAM,
    icon: faInstagram,
    url: 'https://www.instagram.com/realbrokerage/',
  },
  {
    name: SocialMedia.TWITTER,
    icon: faXTwitter,
    url: 'https://twitter.com/joinreal',
  },
  {
    name: SocialMedia.LINKEDIN,
    icon: faLinkedinIn,
    url: 'https://www.linkedin.com/company/joinreal',
  },
  {
    name: SocialMedia.YOUTUBE,
    icon: faYoutube,
    url: 'https://www.youtube.com/c/RealBrokerage',
  },
  {
    name: SocialMedia.TIKTOK,
    icon: faTiktok,
    url: 'http://www.tiktok.com/@therealbrokerage',
  },
];
