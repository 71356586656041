import { FC } from 'react';
import env from '../../../utils/env';
import Separator from '../Header/GeminiNavSeparator';
import GeminiSection from './GeminiSection';

const BrokerageNav: FC = () => {
  return (
    <div className='w-full xl:max-w-[1440px] mx-auto block xl:flex justify-between font-inter xl:px-[5%] bg-off-white'>
      <GeminiSection
        title='REAL ESTATE'
        description="Real is a real estate experience company working to make life's most complex transaction simple for agents, home buyers, and sellers."
      />

      <Separator />

      <GeminiSection
        subTitle='GET STARTED'
        links={[
          {
            title: 'Find An Agent',
            description:
              "Connect with the industry's best and find the right real estate partner for you.",
            href: '/search-agent',
          },
          {
            title: 'Buy A Home',
            description:
              'Explore exclusive listings and discover the right home for you.',
            href: '/listings',
          },
          {
            title: 'Sell A Home',
            description: 'Go from selling to sold quickly. Hassle-free.',
            href: '/pages/sell',
          },
        ]}
      />
      <Separator />

      <GeminiSection
        subTitle='DIVISIONS'
        links={[
          {
            title: 'Luxury',
            description:
              'Let Real Luxury bring your premiere property dreams to life.',
            href: `${env.NEXT_PUBLIC_ONEREAL_DOMAIN}/pages/luxury`,
          },
          {
            title: 'Military',
            description:
              'Expert-level guidance from agents committed to Improving the lives of military families through home ownership.',
            href: `${env.NEXT_PUBLIC_ONEREAL_DOMAIN}/pages/military`,
          },
        ]}
      />
    </div>
  );
};

export default BrokerageNav;
