import Image from 'next/image';
import React from 'react';
import { DateTime } from 'luxon';
import canadalogo from '../assets/img/canadalogo.svg';

interface CAFooterProps {}

const CAFooter: React.FC<CAFooterProps> = () => {
  return (
    <div className='mt-10'>
      <div className='py-2 md:py-6 leading-6'>
        <p className='font-inter text-[13px] text-light-gray-4'>
          This site is operated by The Real Brokerage Inc., and its affiliated
          licensed real estate brokerages (“Broker”).
        </p>
        <p className='font-inter text-[13px] text-light-gray-4 mt-3'>
          Broker is licensed in the following Canadian Provinces as indicated
          below:
        </p>
        <p className='font-inter text-[13px] text-light-gray-4'>
          British Columbia- Real Broker BC Ltd
        </p>
        <p className='font-inter text-[13px] text-light-gray-4'>
          Alberta- Real Broker AB Ltd
        </p>
        <p className='font-inter text-[13px] text-light-gray-4'>
          Manitoba- Real Broker Manitoba Ltd
        </p>
        <p className='font-inter text-[13px] text-light-gray-4'>
          Ontario- Real Broker Ontario Ltd
        </p>
        <p className='font-inter text-[13px] text-light-gray-4 mt-3'>
          Real Broker BC Ltd, Real Broker AB Ltd, Real Broker Manitoba Ltd, and
          Real Broker Ontario Ltd are members of the Canadian Real Estate
          Association.
        </p>
        <div className='my-4 text-light-gray-4'>
          <strong className='font-inter text-light-gray-6 text-[13px]'>
            Contact information:{' '}
          </strong>
          <div className='font-inter text-[13px] mt-2 grid grid-cols-5'>
            <div>Manitoba:</div>
            <div>
              Real Broker
              <br />
              330 St. Mary Avenue Suite 300
              <br />
              Winnipeg, MB R3C 3Z5
              <br />
              1-888-204-8554
              <br />
              MBbroker@therealbrokerage.com
            </div>
          </div>
          <div className='font-inter text-[13px] mt-2 grid grid-cols-5'>
            <div>British Columbia:</div>
            <div>
              Real Broker
              <br />
              666 Burrard St. Suite 500
              <br />
              Vancouver, BC V6C 3P6
              <br />
              1-888-828-8447
              <br />
              BCbroker@therealbrokerage.com
            </div>
          </div>
          <div className='font-inter text-[13px] mt-2 grid grid-cols-5'>
            <div>Alberta:</div>
            <div>
              Real Broker
              <br />
              1816 Crowchild Trail NW Suite 700
              <br />
              Calgary, AB T2M 3Y7
              <br />
              1-855-623-6900
              <br />
              ABbroker@therealbrokerage.com
            </div>
          </div>
          <div className='font-inter text-[13px] mt-2 grid grid-cols-5'>
            <div>Ontario:</div>
            <div>
              Real Broker Ontario Ltd., Brokerage
              <br />
              130 King Street West Suite 1900
              <br />
              Toronto, ON M5X 1E3
              <br />
              1-888-311-1172
              <br />
              ONbroker@therealbrokerage.com
            </div>
          </div>
        </div>
        <div className='font-inter text-[13px] my-4 text-light-gray-4'>
          REALTOR®, REALTORS®, and the REALTOR® logo are certification marks
          that are owned by REALTOR® Canada Inc. and licensed exclusively to The
          Canadian Real Estate Association (CREA). These certification marks
          identify real estate professionals who are members of CREA and who
          must abide by CREA’s By-Laws, Rules, and the REALTOR® Code. The MLS®
          trademark and the MLS® logo are owned by CREA and identify the quality
          of services provided by real estate professionals who are members of
          CREA.
        </div>
        <div className='font-inter text-[13px] my-4 text-light-gray-4'>
          The information contained on this site is based in whole or in part on
          information that is provided by members of The Canadian Real Estate
          Association (CREA), who are responsible for its accuracy. CREA
          reproduces and distributes this information as a service for its
          members and assumes no responsibility for its accuracy.
        </div>
        <div className='font-inter text-[11px] mt-4 text-light-gray-4'>
          Powered by:
        </div>
        <div className='mb-4 text-light-gray-4'>
          <Image src={canadalogo} width={135} height={45} alt='down' />
        </div>
        <div className='font-inter text-[13px] my-4 text-light-gray-4'>
          The listing content on this website is protected by copyright and
          other laws, and is intended solely for the private, non-commercial use
          by individuals. Any other reproduction, distribution or use of the
          content, in whole or in part, is specifically forbidden. The
          prohibited uses include commercial use, “screen scraping”, “database
          scraping”, and any other activity intended to collect, store,
          reorganize or manipulate data on the pages produced by or displayed on
          this website. See the website’s Terms of Use for details.
        </div>
        <div className='font-inter text-[13px] my-4 text-light-gray-4'>
          Information contained on this site (including without limitation
          estimates, projections, testimonials and endorsements) must not be
          relied upon as a substitute for professional advice from a qualified
          individual. No results or other outcomes are guaranteed, and any
          estimates, projections, testimonials or endorsements contained on this
          site are not indicative of actual and/or similar results.
        </div>
        <div className='font-inter text-[13px] my-4 text-light-gray-4'>
          © The Real Brokerage, LLC {DateTime.local().year}
        </div>
      </div>
    </div>
  );
};

export default CAFooter;
