import React from 'react';
import { CountryEnum } from '../types';
import env from './env';

interface PropsWithChildren {
  children: React.ReactNode;
}

export const getSupportedCountries = (): CountryEnum[] => {
  return (env.NEXT_PUBLIC_SUPPORTED_COUNTRIES?.split(',') ||
    []) as CountryEnum[];
};

export const isUSSiteOnly = (): boolean => {
  const supportedCountries = getSupportedCountries();
  return (
    supportedCountries?.length === 1 &&
    supportedCountries?.includes(CountryEnum.UnitedStates)
  );
};

export const isCASiteOnly = (): boolean => {
  const supportedCountries = getSupportedCountries();
  return (
    supportedCountries?.length === 1 &&
    supportedCountries?.includes(CountryEnum.Canada)
  );
};

export const isMultiRegionSite = (): boolean => {
  const supportedCountries = getSupportedCountries();
  return (
    supportedCountries?.length === 2 &&
    supportedCountries?.includes(CountryEnum.UnitedStates) &&
    supportedCountries?.includes(CountryEnum.Canada)
  );
};

export const USSiteOnly: React.FC<PropsWithChildren> = ({ children }) => {
  const isUSOnly = isUSSiteOnly();
  if (!isUSOnly) return null;
  return <div className='w-full'>{children}</div>;
};

export const CASiteOnly: React.FC<PropsWithChildren> = ({ children }) => {
  const isCAOnly = isCASiteOnly();
  if (!isCAOnly) return null;
  return <div className='w-full'>{children}</div>;
};

export const MultiRegionalSiteOnly: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const isSupportedCountry = isMultiRegionSite();
  if (!isSupportedCountry) return null;
  return <div className='w-full'>{children}</div>;
};

export const getRestrictedCountry = (): string => {
  if (isCASiteOnly()) {
    return 'ca';
  }
  return 'us';
};
