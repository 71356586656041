import classNames from 'classnames';
import React, { useContext } from 'react';
import { getThemeBgColorClassName } from '../utils/DisplayHelpers';
import { ThemeContext } from './ThemeProvider';

interface PageTransitionLoaderProps {
  loading: boolean;
}

const PageTransitionLoader: React.FC<PageTransitionLoaderProps> = ({
  loading,
}) => {
  const colorTheme = useContext(ThemeContext);

  if (!loading) return null;

  return (
    <div
      className='w-full h-1 bg-gray-200 overflow-hidden'
      data-testid='page-loader'
    >
      <div
        className={classNames(
          'w-1/6 h-full animate-leftToRight',
          getThemeBgColorClassName(colorTheme)
        )}
        style={{ marginLeft: '100%' }}
      />
    </div>
  );
};

export default PageTransitionLoader;
