/* eslint-disable @next/next/no-img-element */
import React from 'react';
import GeminiButton from './GeminiButton';

interface GeminiFooterRedesignProps {}

const GeminiFooterTop: React.FC<GeminiFooterRedesignProps> = () => {
  return (
    <div className='relative w-full'>
      <div className='relative max-w-[1440px] mx-auto 2xl:h-[257px] xl:h-[353px] md:h-[185px] h-[291px] w-full bg-cobalt text-off-white'>
        <div className='absolute top-0 left-0 z-10 lg:pl-[70px] px-5 xl:pt-[180px] md:pt-[80px] pt-[70px]'>
          <p className='font-inter xl:text-base text-xs font-bold tracking-[3.52px]'>
            JOIN REAL
          </p>
          <div className='2xl:w-[59%] xl:w-[64.5%] md:w-[73%] w-[95%]'>
            <p className='font-telegraf font-normal xl:text-[56px] text-[42px] xl:leading-[61px] leading-[44px] md:mt-[30px] mt-[10px] mb-[30px]'>
              Be a part of the team transforming real estate
            </p>
          </div>
          <GeminiButton
            label='Become A Real Agent'
            variant='primary-light'
            href='/pages/join-real'
          />
        </div>
      </div>
      <div className="relative w-screen 2xl:h-[570px] md:h-[472px] h-[220px] bg-[url('/img/real-group.jpg')] object-cover bg-cover bg-center">
        <div className='bg-cobalt 2xl:w-[59%] 2xl:h-[260px] xl:w-[64.5%] xl:h-[132px] md:w-[73%] md:h-[156px] w-[71%] h-[78px]' />
      </div>
    </div>
  );
};

export default GeminiFooterTop;
