import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { useState } from 'react';
import { EnumMap } from '../../types';
import LinkContainer, { ILinkContainerProps } from '../LinkContainer';

type GeminiButtonVariant =
  | 'primary-dark'
  | 'secondary-dark'
  | 'secondary'
  | 'rounded-icon-primary'
  | 'primary-light';

interface GeminiButtonProps extends ILinkContainerProps {
  label?: string;
  variant?: GeminiButtonVariant;
  onClick?(): void;
  type?: 'button' | 'submit' | 'reset';
  fullWidth?: boolean;
}

const GeminiButton: React.FC<GeminiButtonProps> = ({
  label,
  variant = 'secondary-dark',
  onClick,
  type = 'button',
  fullWidth,
  ...props
}) => {
  const [hover, setHover] = useState(false);

  const buttonVariantsClassMap: EnumMap<GeminiButtonVariant, string> = {
    'primary-light':
      'border border-off-white text-off-white px-4 py-2 hover:border-transparent hover:!bg-new-primary hover:!text-cobalt focus:ring-1 focus:ring-off-white focus:outline-none focus:ring-offset-2',
    secondary: 'text-new-primary focus:ring-1 focus:ring-off-white p-1',
    'primary-dark':
      'border border-cobalt text-cobalt px-4 py-2 hover:border-transparent hover:!bg-new-primary focus:ring-1 focus:ring-cobalt focus:outline-none focus:ring-offset-2',
    'secondary-dark': 'text-cobalt focus:ring-1 focus:ring-cobalt p-1',
    'rounded-icon-primary':
      'bg-new-primary h-10 w-10 rounded-full text-cobalt focus:ring-1 focus:ring-off-white focus:ring-offset-4',
  };

  return (
    <LinkContainer {...props}>
      <button
        className={classNames(
          'flex items-center justify-center',
          buttonVariantsClassMap[variant],
          { 'w-full': fullWidth }
        )}
        type={type}
        onClick={onClick}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        {!!label && (
          <span className='font-telegraf tracking-wide mr-2.5 text-base text-left'>
            {label}
          </span>
        )}

        <FontAwesomeIcon
          icon={faArrowRight}
          className={hover ? 'rotate-0' : '-rotate-45'}
        />
      </button>
    </LinkContainer>
  );
};

export default GeminiButton;
