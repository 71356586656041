import React from 'react';
import {
  BOLT_CUSTOM_ASSET_FOR_TEXAS_IABS_PDF_URL,
  BOLT_CUSTOM_ASSET_FOR_TEXAS_CPN_PDF_URL,
} from '../../constants/BoltConstants';

interface GeneralTexasDisclaimersProps {}

const GeneralTexasDisclaimers: React.FC<GeneralTexasDisclaimersProps> = () => {
  return (
    <div className='font-primary-light text-base text-gray-500 pb-4'>
      <p className='font-primary-bold font-bold text-gray-500 opacity-90'>
        The Texas Real Estate Commission (TREC) requires that we provide the
        following information:
      </p>
      <a
        target='_blank'
        className='underline'
        rel='noreferrer'
        href={BOLT_CUSTOM_ASSET_FOR_TEXAS_IABS_PDF_URL}
      >
        Texas Real Estate Commission Information About Brokerage Service
      </a>
      <a
        target='_blank'
        className='underline'
        rel='noreferrer'
        href={BOLT_CUSTOM_ASSET_FOR_TEXAS_CPN_PDF_URL}
      >
        TREC Consumer Protection Notice
      </a>
    </div>
  );
};

export default GeneralTexasDisclaimers;
