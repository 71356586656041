import React from 'react';

const GeminiMlsComplianceItems: React.FC = () => {
  return (
    <section className='mt-5 font-inter font-normal text-[14px] leading-[21px] text-off-white text-opacity-70'>
      <article>
        <p>
          The Digital Millennium Copyright Act of 1998, 17 U.S.C. § 512 (the
          “DMCA”) provides recourse for copyright owners who believe that
          material appearing on the Internet infringes their rights under U.S.
          copyright law. If you believe in good faith that any content or
          material made available in connection with our website or services
          infringes your copyright, you (or your agent) may send us a notice
          requesting that the content or material be removed, or access to it
          blocked. Notices must be sent in writing by email to{' '}
          <a href='mailto:DMCAnotice@MLSGrid.com' className='underline'>
            DMCAnotice@MLSGrid.com
          </a>
          .
        </p>
      </article>

      <article>
        <p>
          “The DMCA requires that your notice of alleged copyright infringement
          include the following information:
        </p>
      </article>
      <article>
        <p>
          (1) description of the copyrighted work that is the subject of claimed
          infringement;
        </p>
        <p>
          (2) description of the alleged infringing content and information
          sufficient to permit us to locate the content;
        </p>
        <p>
          (3) contact information for you, including your address, telephone
          number and email address;
        </p>
        <p>
          (4) a statement by you that you have a good faith belief that the
          content in the manner complained of is not authorized by the copyright
          owner, or its agent, or by the operation of any law;
        </p>
        <p>
          (5) a statement by you, signed under penalty of perjury, that the
          information in the notification is accurate and that you have the
          authority to enforce the copyrights that are claimed to be infringed;
          and
        </p>
        <p>
          (6) a physical or electronic signature of the copyright owner or a
          person authorized to act on the copyright owner’s behalf. Failure to
          include all of the above information may result in the delay of the
          processing of your complaint.
        </p>
      </article>
    </section>
  );
};

export default GeminiMlsComplianceItems;
