import { useRouter } from 'next/router';
import { useState, useEffect } from 'react';

export const usePageLoading = (): boolean => {
  const router = useRouter();
  const [loading, setLoading] = useState<boolean>(false);

  const handleRouterChangeStart = (): void => setLoading(true);

  const handleRouterChangeComplete = (): void => setLoading(false);

  useEffect(() => {
    router.events.on('routeChangeStart', handleRouterChangeStart);
    router.events.on('routeChangeError', handleRouterChangeComplete);
    router.events.on('routeChangeComplete', handleRouterChangeComplete);

    return () => {
      router.events.off('routeChangeStart', handleRouterChangeStart);
      router.events.off('routeChangeError', handleRouterChangeComplete);
      router.events.off('routeChangeComplete', handleRouterChangeComplete);
    };
  }, [router.events]);

  return loading;
};
