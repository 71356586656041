import Image from 'next/image';
import React from 'react';
import Link from 'next/link';
import Container from './Container';

interface FooterRedesignProps {}

const FooterTop: React.FC<FooterRedesignProps> = () => {
  return (
    <Container>
      <div className='relative lg:py-6 sm:py-1'>
        <div className='lg:w-1/2 lg:ml-9 ml-4 sm:w-full'>
          <p className='text-red-1 font-inter font-medium text-[15px] tracking-[0.3125em]'>
            JOIN REAL
          </p>
          <div className='pt-6 pb-1 md:pb-14'>
            <p className=' text-white text-3xl tracking-[-.292px] md:text-[52px] font-telegraf font-light pb-14 md:leading-[58px]'>
              Be a part of the team transforming real estate.
            </p>
          </div>

          <Link href='/pages/join-real'>
            <a className='flex items-center'>
              <div>
                <p className='text-red-1 font-inter text-xl font-[400]'>
                  Learn more
                </p>
              </div>

              <div className='ml-5 mt-1.5'>
                <Image
                  src={require('../assets/img/right_arrow.svg')}
                  alt='right'
                />
              </div>
            </a>
          </Link>
        </div>
        <div className='lg:absolute lg:-top-36 lg:-right-32 hidden lg:block lg:w-7/12 xl:w-auto'>
          <Image
            src={require('../assets/img/real_group.jpg')}
            alt='footer top'
          />
        </div>
      </div>
    </Container>
  );
};

export default FooterTop;
