import classNames from 'classnames';
import React from 'react';

interface GeminiNavMenuItemProps {
  title: string;
  onShow(): void;
  onHide(): void;
  isOpen: boolean;
  textClassName?: string;
}

const GeminiNavMenuItem: React.FC<GeminiNavMenuItemProps> = ({
  title,
  onShow,
  onHide,
  isOpen,
  textClassName,
}) => {
  return (
    <button
      onMouseEnter={onShow}
      onClick={isOpen ? onHide : onShow}
      className={classNames(
        'font-inter text-base font-semibold text-cobalt w-full whitespace-nowrap py-5 border-b-2',
        isOpen ? 'border-red-1' : 'border-transparent',
        textClassName
      )}
    >
      <span>{title}</span>
    </button>
  );
};

export default GeminiNavMenuItem;
