import React from 'react';
import classNames from 'classnames';
import Image from 'next/image';

interface DropdownButtonContentProps {
  title: string;
  onClick?(): void;
  showArrow?: boolean;
}

const NavButton: React.FC<DropdownButtonContentProps> = ({
  title,
  onClick = () => null,
  showArrow = true,
}) => (
  <div
    className={classNames(
      'flex items-center space-x-1 cursor-pointer text-gray-600'
    )}
    onClick={onClick}
  >
    <p className='whitespace-nowrap'>{title}</p>
    {showArrow && (
      <div className={classNames('h-6 w-6')}>
        <Image
          src='/img/arrow-down.svg'
          alt='arrow-down'
          width={10}
          height={8}
        />
      </div>
    )}
  </div>
);

export default NavButton;
