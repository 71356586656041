import React, { FC, HTMLAttributeAnchorTarget, ReactNode } from 'react';
import LinkWithAnchor from './LinkWithAnchor';

export interface ILinkContainerProps {
  href?: string;
  target?: HTMLAttributeAnchorTarget;
  as?: string;
  children?: ReactNode;
}

const LinkContainer: FC<ILinkContainerProps> = ({
  href,
  target,
  as,
  children,
}) => {
  const Container = href
    ? href.startsWith('http')
      ? 'a'
      : LinkWithAnchor
    : 'span';

  return (
    <Container href={href} target={target} as={as}>
      {children}
    </Container>
  );
};

export default LinkContainer;
