import React from 'react';
import env from '../../utils/env';
import LinkWithAnchor, { LinkWithAnchorProps } from '../LinkWithAnchor';

export enum FooterSectionTypeEnum {
  REAL_ESTATE = 'Real Estate',
  MORTGAGE = 'Mortgage',
  ABOUT_US = 'About Us',
  TITLE_ESCROW = 'Title / Escrow',
  DIVISIONS = 'Divisions',
}

interface FooterLink extends LinkWithAnchorProps {
  label: string;
}

const footerLinks: Record<FooterSectionTypeEnum, FooterLink[]> = {
  [FooterSectionTypeEnum.REAL_ESTATE]: [
    { label: 'Buy a Home', href: '/listings' },
    { label: 'Sell a Home', href: '/pages/sell' },
    { label: 'Find an Agent', href: '/search-agent' },
    {
      label: 'Military',
      href: `${env.NEXT_PUBLIC_ONEREAL_DOMAIN}/pages/military`,
    },
    { label: 'Luxury', href: `${env.NEXT_PUBLIC_ONEREAL_DOMAIN}/pages/luxury` },
    {
      label: 'Resources',
      href: `${env.NEXT_PUBLIC_REAL_BUYER_PLAYBOOK_DOMAIN}`,
      target: '_blank',
    },
  ],
  [FooterSectionTypeEnum.MORTGAGE]: [
    {
      label: 'Purchase or Refinance',
      href: `${env.NEXT_PUBLIC_MORTGAGE_SUBDOMAIN}/purchase`,
      target: '_blank',
    },
    {
      label: 'Current Programs',
      href: `${env.NEXT_PUBLIC_MORTGAGE_SUBDOMAIN}/programs`,
      target: '_blank',
    },
    {
      label: 'Apply Now',
      href: `${env.NEXT_PUBLIC_MYHOME_MORTGAGE_SUBDOMAIN}/homehub/signup/samir@lemonbrew.com?from_mobile_share=true`,
      target: '_blank',
    },
  ],
  [FooterSectionTypeEnum.ABOUT_US]: [
    { label: 'Our Story', href: '/pages/leadership' },
    {
      label: 'Real Newsroom',
      href: `${env.NEXT_PUBLIC_ONEREAL_BLOG_SUBDOMAIN}`,
      target: '_blank',
    },
    {
      label: 'Careers',
      href: `${env.NEXT_PUBLIC_ONEREAL_DOMAIN}/pages/careers`,
    },
    {
      label: 'Partners',
      href: `${env.NEXT_PUBLIC_ONEREAL_WIDENCOLLECTIVE_SUBDOMAIN}/portals/taqiemso/Partners`,
      target: '_blank',
    },
    { label: 'Contact Us', href: '/pages/contact-us' },
    {
      label: 'Agent login',
      href: `${env.NEXT_PUBLIC_BOLT_DOMAIN}/login`,
      target: '_blank',
    },
  ],
  [FooterSectionTypeEnum.TITLE_ESCROW]: [
    { label: 'Current Programs', href: '/' },
    {
      label: 'Apply Now',
      href: `${env.NEXT_PUBLIC_ONEREAL_TITLE_APPLY_FORM_DOMAIN}`,
      target: '_blank',
    },
    {
      label: 'Escrow (CA Residents)',
      href: `${env.NEXT_PUBLIC_TITLE_SUBDOMAIN}/escrow`,
      target: '_blank',
    },
  ],
  [FooterSectionTypeEnum.DIVISIONS]: [
    {
      label: 'Luxury',
      href: `${env.NEXT_PUBLIC_ONEREAL_DOMAIN}/pages/join-luxury`,
    },
    {
      label: 'Military',
      href: `${env.NEXT_PUBLIC_ONEREAL_DOMAIN}/pages/military-division-application`,
    },
  ],
};

interface GeminiFooterContentProps {
  type: FooterSectionTypeEnum;
}

const GeminiFooterContent: React.FC<GeminiFooterContentProps> = ({ type }) => {
  const links = footerLinks[type];

  return (
    <>
      <h5 className='font-telegraf font-normal xl:text-2xl text-xl text-new-primary p-5 xl:pb-5 pb-0 xl:border-b border-new-gray border-opacity-40'>
        {type}
      </h5>
      <nav className='flex flex-col items-start xl:gap-[18px] gap-6 font-inter font-normal text-[15px] leading-[22.5px] text-off-white p-5'>
        {links.map(({ label, href, target }) => (
          <LinkWithAnchor
            key={label}
            href={href ?? ''}
            target={target ?? '_self'}
          >
            {label}
          </LinkWithAnchor>
        ))}
      </nav>
    </>
  );
};

export default GeminiFooterContent;
