import { faArrowLeft, faXmark } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';

interface IMobileSubMenuComponentProps {
  closeMenu: () => void;
  closeSubMenu: () => void;
  children: React.ReactNode;
}

const MobileSubMenuComponent: FC<IMobileSubMenuComponentProps> = ({
  children,
  closeSubMenu,
  closeMenu,
}) => {
  return (
    <div className='bg-new-gray h-screen w-full fixed top-0 z-[99999]'>
      <div className='flex justify-between items-center py-[22px] bg-off-white px-5'>
        <button onClick={closeSubMenu}>
          <FontAwesomeIcon icon={faArrowLeft} className='mr-2.5 text-cobalt' />
          <span className='font-inter text-cobalt font-semibold'>Back</span>
        </button>
        <button onClick={closeMenu}>
          <FontAwesomeIcon icon={faXmark} className='cursor-pointer text-xl' />
        </button>
      </div>
      <div className='bg-off-white h-full pb-20 overflow-y-auto'>
        {children}
      </div>
    </div>
  );
};

export default MobileSubMenuComponent;
