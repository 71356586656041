import { FC } from 'react';
import env from '../../../utils/env';
import Separator from '../Header/GeminiNavSeparator';
import GeminiSection from './GeminiSection';

const AboutUsNav: FC = () => {
  return (
    <div className='w-full xl:max-w-[1440px] mx-auto block xl:flex justify-between font-inter xl:px-[5%] bg-off-white'>
      <GeminiSection
        title='About Us'
        description="We're revolutionizing the real estate experience with proprietary
          technology, end-to-end services, and trusted, agent-led guidance - all
          under one roof."
      />

      <Separator />

      <GeminiSection
        links={[
          {
            title: 'Our Story',
            description:
              'Agent-focused innovators committed to building the brokerage of the future.',
            href: '/pages/leadership',
          },
          {
            title: 'Real Newsroom',
            description:
              'A deeper dive into the fastest-growing publicly traded real estate brokerage.',
            href: `${env.NEXT_PUBLIC_ONEREAL_BLOG_SUBDOMAIN}`,
            target: '_blank',
          },
        ]}
      />

      <Separator />

      <GeminiSection
        links={[
          {
            title: 'Careers',
            description:
              'Stay up to date and see where you can catch Real next.',
            href: `${env.NEXT_PUBLIC_ONEREAL_DOMAIN}/pages/careers`,
            target: '_blank',
          },
          {
            title: 'Contact Us',
            description: "We're here to help 24/7.",
            href: '/pages/contact-us',
          },
        ]}
      />
    </div>
  );
};

export default AboutUsNav;
