/* eslint-disable @next/next/no-img-element */
import { faBars, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import env from '../../../utils/env';
import { useGoogleMapsAPILoader } from '../../../hooks/useGoogleMapsAPILoader';
import { usePageLoading } from '../../../hooks/usePageLoading';
import { isCASiteOnly, isUSSiteOnly } from '../../../utils/SiteConfiguration';
import MobileToggleMenu from '../../MobileToggleMenu';
import PageTransitionLoader from '../../PageTransitionLoader';
import AboutUsNav from '../GeminiDesktopNav/AboutUsNav';
import AgentsNav from '../GeminiDesktopNav/AgentsNav';
import BrokerageNav from '../GeminiDesktopNav/BrokerageNav';
import MortgageNav from '../GeminiDesktopNav/MortgageNav';
import TitleEscrowNav from '../GeminiDesktopNav/TitleEscrowNav';
import GeminiNavDropdownOptions, {
  GeminiNavDropdownOptionsType,
} from './GeminiNavDropdownOptions';
import GeminiNavLinkButton from './GeminiNavLinkButton';
import GeminiNavMenuItem from './GeminiNavMenuItem';
import NewSiteAlertBar from './GeminiNewSiteAlertBar';

interface GeminiNavProps {
  loading?: boolean;
}

const GeminiNav: React.FC<GeminiNavProps> = () => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const loading = usePageLoading();
  const router = useRouter();
  const [activeDropdown, setActiveDropdown] =
    useState<GeminiNavDropdownOptionsType>(undefined);

  const isCASite = isCASiteOnly();
  const isUSSite = isUSSiteOnly();
  const isNewSite = isCASite || isUSSite;

  // Hook to handle body overflow when menu is open
  useEffect(() => {
    document.body.style.overflow = isMenuOpen ? 'hidden' : 'auto';
  }, [isMenuOpen]);

  // Close the menu when the route changes
  useEffect(() => {
    closeMenu();
  }, [router]);

  useGoogleMapsAPILoader();

  const closeMenu = (): void => setIsMenuOpen(false);

  // Render the desktop navigation bar
  const renderDesktopNav = (): JSX.Element => (
    <div className='w-full relative z-40 bg-off-white'>
      <div className='max-w-[1440px] mx-auto flex items-center justify-between relative transition-all ease-in-out duration-300 xl:px-[5%] px-5'>
        <Link href='/'>
          <img
            src='/img/RealLogo.svg'
            alt='real-logo'
            className='md:h-[38px] md:w-[84px] h-[27px] w-[60px]'
          />
        </Link>
        <div className='xl:flex xl:flex-row xl:items-center gap-[40px] hidden'>
          <NavItems />
        </div>
        <button
          onClick={() => setIsMenuOpen((prev) => !prev)}
          className='xl:hidden py-4 flex items-center'
        >
          <span className='font-inter text-sm mr-2.5'>Menu</span>
          <FontAwesomeIcon icon={isMenuOpen ? faXmark : faBars} size='lg' />
        </button>
      </div>
      {activeDropdown && <GeminiNavDropdownOptions type={activeDropdown} />}
    </div>
  );

  // Render the mobile navigation bar
  const renderMobileNav = (): JSX.Element => (
    <div
      className={classNames(
        'bg-off-white absolute h-screen w-screen overflow-y-scroll z-[99999] xl:hidden',
        isMenuOpen ? 'block' : 'hidden'
      )}
    >
      <div className='flex items-center justify-between px-5 py-4 bg-off-white sticky top-0'>
        <Link href='/'>
          <img
            src='/img/RealLogo.svg'
            alt='real-logo'
            className='h-[27px] w-[60px]'
          />
        </Link>
        <button onClick={() => setIsMenuOpen((prev) => !prev)}>
          <FontAwesomeIcon icon={isMenuOpen ? faXmark : faBars} size='lg' />
        </button>
      </div>
      <MobileMenuItems />
      <GeminiNavLinkButton
        title='Investors'
        titleClassName='text-[22px] font-semibold font-inter text-cobalt flex items-center justify-between w-full py-3 px-1'
        href={`${env.NEXT_PUBLIC_INVESTORS_DOMAIN}`}
        target='_blank'
      />
      <GeminiNavLinkButton
        title={isCASite ? 'Take me to USA' : 'Take me to Canada'}
        titleClassName='text-[22px] font-semibold font-inter text-cobalt flex items-center justify-between w-full py-3 px-1'
        href={
          isCASite
            ? `${env.NEXT_PUBLIC_ONEREAL_DOMAIN}`
            : `${env.NEXT_PUBLIC_ONEREAL_CANADA_DOMAIN}`
        }
        target='_blank'
      />
    </div>
  );

  // Render the navigation items for the desktop view
  const NavItems = (): JSX.Element => (
    <>
      <GeminiNavMenuItem
        title='Real Estate'
        onShow={() => setActiveDropdown('Brokerage')}
        onHide={() => setActiveDropdown(undefined)}
        isOpen={activeDropdown === 'Brokerage'}
      />
      {!isCASite && (
        <>
          <GeminiNavMenuItem
            title='Mortgage'
            onShow={() => setActiveDropdown('Mortgage')}
            onHide={() => setActiveDropdown(undefined)}
            isOpen={activeDropdown === 'Mortgage'}
          />
          <GeminiNavMenuItem
            title='Title/Escrow'
            onShow={() => setActiveDropdown('Title')}
            onHide={() => setActiveDropdown(undefined)}
            isOpen={activeDropdown === 'Title'}
          />
        </>
      )}
      <GeminiNavMenuItem
        title='About Us'
        onShow={() => setActiveDropdown('About')}
        onHide={() => setActiveDropdown(undefined)}
        isOpen={activeDropdown === 'About'}
      />
    </>
  );

  // Render the navigation items for the mobile view
  const MobileMenuItems = (): JSX.Element => (
    <>
      <MobileToggleMenu menuName='Real Estate' closeMenu={closeMenu}>
        <BrokerageNav />
      </MobileToggleMenu>
      {!isCASite && (
        <>
          <MobileToggleMenu menuName='Mortgage' closeMenu={closeMenu}>
            <MortgageNav />
          </MobileToggleMenu>
          <MobileToggleMenu menuName='Title/Escrow' closeMenu={closeMenu}>
            <TitleEscrowNav />
          </MobileToggleMenu>
        </>
      )}
      <MobileToggleMenu menuName='About Us' closeMenu={closeMenu}>
        <AboutUsNav />
      </MobileToggleMenu>
      <MobileToggleMenu menuName='Agents' closeMenu={closeMenu}>
        <AgentsNav />
      </MobileToggleMenu>
    </>
  );

  return (
    <nav
      className='flex flex-col items-center justify-between flex-wrap w-full bg-off-white z-50 sticky top-0'
      onMouseLeave={() => setActiveDropdown(undefined)}
    >
      <div className='absolute w-full left-0 top-0 z-[9999]'>
        <PageTransitionLoader loading={loading} />
      </div>
      {isNewSite && (
        <NewSiteAlertBar
          dropdownActive={activeDropdown}
          setDropdownActive={setActiveDropdown}
        />
      )}

      {/* Render both desktop and mobile navigation based on screen size */}
      {renderDesktopNav()}
      {renderMobileNav()}
    </nav>
  );
};

export default GeminiNav;
