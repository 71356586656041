import classNames from 'classnames';
import React, { HTMLAttributeAnchorTarget } from 'react';
import { FiLoader } from 'react-icons/fi';
import { EnumMap } from '../types';
import LinkWithAnchor from './LinkWithAnchor';

type ButtonVariantType =
  | 'primary'
  | 'secondary'
  | 'outline'
  | 'white'
  | 'outline-primary'
  | 'outline-white'
  | 'black'
  | 'more'
  | 'outline-black';

type ButtonSizeType = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

interface ButtonProps {
  title: string;
  type?: 'submit' | 'button' | 'reset';
  variant?: ButtonVariantType;
  href?: string;
  as?: string;
  target?: HTMLAttributeAnchorTarget;
  onClick?(): void;
  size?: ButtonSizeType;
  isLoading?: boolean;
  icon?: React.ReactNode | React.ReactNode[];
  buttonStyles?: string;
  textStyles?: string;
  fullWidth?: boolean;
  disabled?: boolean;
  isRounded?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  title,
  type = 'submit',
  variant = 'primary',
  href,
  as,
  size = 'md',
  target,
  onClick,
  icon,
  buttonStyles,
  textStyles,
  fullWidth,
  disabled,
  isLoading = false,
  isRounded = true,
}) => {
  const ButtonVariantsClassMap: EnumMap<ButtonVariantType, string> = {
    primary: 'bg-primary text-white',
    secondary: 'bg-gray-100 border border-gray-300 text-black',
    outline:
      'border border-gray-500 text-black hover:text-white hover:bg-gray-500',
    'outline-primary': 'bg-transparent border border-primary text-primary',
    'outline-white': 'bg-transparent border border-primary text-white',
    white: 'border bg-white text-dark',
    black: 'bg-theme-black text-white',
    more: ' bg-white text-dark',
    'outline-black': 'bg-transparent border-[3px] border-black text-black',
  };

  const ButtonSizeClassMap: Record<ButtonSizeType, string> = {
    xs: 'px-2.5 py-1 text-xs',
    sm: 'px-6 py-1 text-base ',
    md: 'px-3.5 py-1.5 text-base',
    lg: 'px-3.5 py-1.5 text-lg',
    xl: 'px-4 py-4 text-xl ',
  };

  const Container = href
    ? href.startsWith('http')
      ? 'a'
      : LinkWithAnchor
    : 'span';

  const LoaderIconComponent = <FiLoader size={18} className='animate-spin' />;

  const rightIcon = icon && isLoading ? LoaderIconComponent : icon;

  return (
    <Container as={as} href={href!} target={target}>
      <button
        type={type}
        className={classNames(
          'font-primary-medium flex items-center whitespace-pre',
          ButtonSizeClassMap[size],
          ButtonVariantsClassMap[variant],
          buttonStyles,
          {
            'w-full justify-center': fullWidth,
            'cursor-wait': isLoading,
            'cursor-not-allowed': !isLoading && disabled,
            rounded: isRounded,
          }
        )}
        onClick={onClick}
        disabled={disabled}
      >
        <span
          className={classNames(
            'font-primary-regular',
            ButtonSizeClassMap[size],
            {
              'pr-2': icon,
            },
            textStyles
          )}
        >
          {title}
        </span>
        {icon && <div className='w-max mt-1'>{rightIcon}</div>}
      </button>
    </Container>
  );
};

export default Button;
