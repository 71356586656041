import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useState } from 'react';
import MobileSubMenuComponent from './MobileToggleMenuItem';

interface IMobileToggleMenuProps {
  menuName: string;
  children: React.ReactNode;
  closeMenu?: () => void;
}

const MobileToggleMenu: FC<IMobileToggleMenuProps> = ({
  menuName,
  children,
  closeMenu,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  if (isOpen) {
    return (
      <MobileSubMenuComponent
        closeMenu={closeMenu}
        closeSubMenu={() => setIsOpen(false)}
      >
        {children}
      </MobileSubMenuComponent>
    );
  }

  return (
    <div className='w-full'>
      <button
        className='font-inter font-semibold text-[22px] text-cobalt flex items-center justify-between w-full py-8 border-y px-5 border-new-gray'
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <span>{menuName}</span>
        <FontAwesomeIcon className='text-xl' icon={faPlus} />
      </button>
    </div>
  );
};

export default MobileToggleMenu;
