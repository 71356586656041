import { faGlobe } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import Link from 'next/link';
import { FC, useState } from 'react';
import env from '../../../utils/env';
import { isCASiteOnly, isUSSiteOnly } from '../../../utils/SiteConfiguration';
import { GeminiNavDropdownOptionsType } from './GeminiNavDropdownOptions';
import GeminiNavMenuItem from './GeminiNavMenuItem';

interface INewSiteAlertBarProps {
  dropdownActive: GeminiNavDropdownOptionsType;
  setDropdownActive: (dropdown: GeminiNavDropdownOptionsType) => void;
}

const NewSiteAlertBar: FC<INewSiteAlertBarProps> = ({
  dropdownActive,
  setDropdownActive,
}) => {
  const [showCountryDropdown, setShowCountryDropdown] = useState(false);
  const isUsSite = isUSSiteOnly();
  const isCASite = isCASiteOnly();

  return (
    <div className='hidden xl:block bg-light-blue-1 w-full text-sm font-light z-50'>
      <div className='max-w-[1440px] mx-auto font-inter px-[5%] items-center hidden xl:flex justify-end'>
        <GeminiNavMenuItem
          title='Agents'
          onHide={() => setDropdownActive(undefined)}
          onShow={() => setDropdownActive('Agents')}
          isOpen={dropdownActive === 'Agents'}
          textClassName='text-off-white !font-light w-fit mr-10 !py-2'
        />

        <a
          href={`${env.NEXT_PUBLIC_INVESTORS_DOMAIN}`}
          target='_blank'
          rel='noopener noreferrer'
          className='block text-off-white !font-light w-fit mr-10 !py-2 text-base -mt-[2px]'
        >
          Investors
        </a>

        <div className='relative'>
          <button onClick={() => setShowCountryDropdown(!showCountryDropdown)}>
            <FontAwesomeIcon
              icon={faGlobe}
              className='text-off-white text-base'
            />
          </button>

          {showCountryDropdown && (
            <div className='absolute right-0 top-7 mt-0.5 bg-white border-2 border-red-1 flex flex-col items-start text-left w-[172px]'>
              <Link href={`${env.NEXT_PUBLIC_ONEREAL_CANADA_DOMAIN}`}>
                <button
                  className={classNames(
                    'py-2.5 w-full px-6 text-left text-cobalt hover:bg-gray-100',
                    isCASite ? 'opacity-100' : 'opacity-60'
                  )}
                >
                  Canada
                </button>
              </Link>

              <Link href={`${env.NEXT_PUBLIC_ONEREAL_DOMAIN}`}>
                <button
                  className={classNames(
                    'py-2.5 border-t px-6 text-left  border-t-new-gray w-full  hover:bg-gray-100',
                    isUsSite ? 'opacity-100' : 'opacity-60'
                  )}
                >
                  USA
                </button>
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NewSiteAlertBar;
